import EncryptService from '../service/encrypt.service';
import { RequestService } from '../utilities/request.component';

export default class MenuService {

    static getAllMenues(token: any) {
        return RequestService.sendGetRequest("menu_consola/obtenerMenu", token);
    } // getAllMenues

    static agregarMenu(datos: any, token: any) {
        let dataEncrypt = EncryptService.encryptData(datos);
        return RequestService.sendPostRequest(dataEncrypt, 'menu_consola/agregarMenu', token);
    } // agregarMenu

    static getMenuPerfil(token: any, perfilid: any) {
        let dataEncrypt = EncryptService.encryptData(perfilid);
        return RequestService.sendGetRequest(`menu_consola/obtenerMenuPerfilConsola/${dataEncrypt}`, token);
    } // getMenuPerfil

    static getMenuPerfilConsola(token: any, perfilid: any) {
        //let dataEncrypt = EncryptService.encryptData(perfilid);
        return RequestService.sendGetRequest(`menu_consola/obtenerMenuCreado/${perfilid}`, token);
    } // getMenuPerfil

    /**API 2.0 */
    static getMenus(data: any, token: any) {
        return RequestService.sendGetRequest('/apiget/getMenus', token);
    }
    static getMenusPerfil(data: any, token: any) {
        return RequestService.sendPostRequest(data, 'processrequest/apipost/getMenuPerfil', token);
    }

    static setMenuEnPerfil(data: any, token: any) {
        return RequestService.sendPostRequest(data, 'processrequest/apipost/setMenuPerfil', token);
    }
} 