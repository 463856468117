import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Panel } from 'primereact/panel';
import { useNavigate } from 'react-router-dom';
import ApiServices from '../../../service/apiServices';
import { Checkbox } from 'primereact/checkbox';
import UserMarker from '../../../Components/Markers/UserMarker/UserMarker';
import CompanyMarker from '../../../Components/Markers/CompanyMarker/CompanyMarker';
import authGuardService from '../../../service/authGuardService';
import EncryptService from '../../../service/encrypt.service';
import { Toast } from 'primereact/toast';
import MapEx from '../../../Components/Map/Map';

const MovimientoEnvio = () => {

    const user = authGuardService.getUser()

    const [cubicMeters, setCubicMeters] = useState<any>()
    const [street, setStreet] = useState<any>()
    const [placeNumber, setPlaceNumber] = useState<any>()
    const [colony, setColony] = useState<any>()
    const [avenue, setAvenue] = useState<any>()
    const [postalCode, setPostalCode] = useState<any>()
    const [materialList, setMaterialList] = useState<any>([])
    const [selectedMaterial, setSelectedMaterial] = useState<any>()
    const [colonyList, setColonyList] = useState<any>()
    const [selectedColony, setSelectedColony] = useState<any>()
    const [selectedCompanyLocation, setSelectedCompanyLocation] = useState<any>()
    const [apiResponse, setApiResponse] = useState<any>()
    const toast = useRef<any>(null);

    const [currentLocation, setCurrentLocation] = useState({
        center: {
            lat: 19.431570552318778,
            lng: -99.14056662025806
        },
        zoom: 16
    })

    useEffect(() => {
        getStoreLocation()
        getMaterialList()
    }, [])

    const getMaterialList = () => {
        let datos
        ApiServices.apiPost('catMaterial', datos).then(async res => {
            setMaterialList(res.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }

    const defaultProps = {
        center: {
            lat: 19.431570552318778,
            lng: -99.14056662025806
        },
        zoom: 12
    }

    const [companyLocations, setCompanyLocations] = useState([])

    useEffect(() => {
        if ("geolocation" in navigator) {
            askLocation()
            console.log("geolocation")
        } else {
            getStoreLocation(defaultProps.center.lat, defaultProps.center.lng)
        }
    }, [])

    const askLocation = () => {
        navigator.geolocation.getCurrentPosition(
            function (position) {
                setCurrentLocation({
                    center: {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    },
                    zoom: 16
                })
                getStoreLocation(position.coords.latitude, position.coords.longitude)
            },
            function (error) {
                getStoreLocation(defaultProps.center.lat, defaultProps.center.lng)
            }
        );
    }

    const getStoreLocation = async(lat = defaultProps.center.lat, lng = defaultProps.center.lng) => {
        let datos: any = {
            suc_lat: lat,
            suc_lng: lng
        }
        datos = await EncryptService.encryptData(JSON.stringify(datos));

        ApiServices.apiPost('getSucursales', {dataEnc: datos}).then(async res => {
            formatStoresLocations(res.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }

    const formatStoresLocations = (locations: any) => {
        if (locations && locations.length > 0) {
            const newLocations = locations.map((singleLocation: any, index: any) => {
                const newSingleLocation = structuredClone(singleLocation)
                if (index === 0)
                    newSingleLocation.selected = true
                else
                    newSingleLocation.selected = false
                return newSingleLocation
            })
            setCompanyLocations(newLocations)
        }
    }

    const handleCheckLocation = (e: any, selectedIndex: any) => {
        if (companyLocations && companyLocations.length > 0) {
            const newArray = structuredClone(companyLocations)

            const newArrayModified: any = newArray.map((singleLocation: any, index) => {
                if (index === selectedIndex) {
                    singleLocation.selected = true
                    setSelectedCompanyLocation(singleLocation)
                }
                else
                    singleLocation.selected = false

                return singleLocation
            })

            setCompanyLocations(newArrayModified)
        }
    }

    const handleCPChange = async(cp: any) => {
        setPostalCode(cp)
        if (cp.length === 5) {
            let datos: any = { cp_cp: cp }
            datos = await EncryptService.encryptData(JSON.stringify(datos));

            ApiServices.apiPost('catCP', {dataEnc: datos}).then(async res => {
                setColonyList(res.data.addenda)
            }).catch(error => {
                console.log(error)
            })
        }
    }

    const handleAdd = () => {
        if(street.trim() && selectedColony && currentLocation && currentLocation && selectedCompanyLocation && selectedMaterial && cubicMeters){

            let datos: any = {
                "code": 301,
                "usu_id": user.usu_id,
                "tipo_id": 2,
                "sol_calle": street,
                "sol_num": placeNumber,
                "cp_id": selectedColony.cp_id,
                "sol_lat": currentLocation.center.lat,
                "sol_lng": currentLocation.center.lng,
                "suc_id": selectedCompanyLocation.suc_id,
                "materiales": [{
                    "mat_id": selectedMaterial.mat_id,
                    "mat_volumen": cubicMeters
                }],
                "mov_ini": "",
                "mov_fin": ""
            }
            datos = EncryptService.encryptData(JSON.stringify(datos));
            ApiServices.processRequestAdd(datos)
                .then(async (res) => {
                    if (res.status === 200 && res.data.mensaje === 'Solicitud agregada correctamente') {
                        setApiResponse(res.data)
                        setVisible(true)
                        // console.log('Toasty Agregado Exitoso')
                    } else {
                        console.log('Hubo Algun error al agregar tu pedido', res)
                    }
                })
                .catch((error) => {
                     toast.current.show({
                        severity: "error",
                        summary: "Error",
                        detail: "Intentelo de Nuevo mas tarde.",
                        life: 4000,
                    }); 
                });
        } else {
            toast.current.show({
                severity: "error",
                summary: "Campos incompletos",
                detail: "Todos los campos son obligatorios.",
                life: 4000,
            }); 
        }

        /* setVisible(true) */
    }

    /* OLD */

    const [globalFilter, setGlobalFilter] = useState<any>(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const cities = [
        { name: 'New York', code: 'NY' },
        { name: 'Rome', code: 'RM' },
        { name: 'London', code: 'LDN' },
        { name: 'Istanbul', code: 'IST' },
        { name: 'Paris', code: 'PRS' }
    ];
    const navigate = useNavigate();
    const [visible, setVisible] = useState(false);
    const footerContent = (
        <div>
            <Button label="Terminar" icon="pi pi-check" onClick={() => navigate('/inicio')} autoFocus />
        </div>
    );

    return (
        <section>
            <Toast ref={toast} />
            <div className="grid">
                <div className='col-12 md:col-12'>
                    <h5 className="m-0">Nuevo movimiento - Venta</h5>
                    <p>Selecciona los siguientes datos para iniciar un nuevo movimiento</p>
                </div>

                <div className='col-12 md:col-4'>
                    <span className="p-float-label p-input-icon-left w-full">
                        <Dropdown
                            value={selectedMaterial}
                            onChange={(e) => setSelectedMaterial(e.value)}
                            options={materialList}
                            optionLabel="mat_desc"
                            placeholder="Tipo de material"
                            className="w-full"
                        />
                    </span>
                </div>
                <div className='col-12 md:col-4'>
                    <span className="p-float-label w-100">
                        <InputText
                            keyfilter="num"
                            value={cubicMeters}
                            onChange={(e) => setCubicMeters(e.target.value)}
                        />
                        <label htmlFor="username">Volumen m3</label>
                    </span>
                </div>

                <div className='col-12 md:col-12'>
                    <p>Ubicación</p>
                </div>

                <div className='col-12 md:col-4'>
                    <span className="p-float-label w-100">
                        <InputText
                            value={street}
                            onChange={(e) => setStreet(e.target.value)}
                        />
                        <label htmlFor="username">Calle</label>
                    </span>
                </div>
                <div className='col-12 md:col-4'>
                    <span className="p-float-label w-100">
                        <InputText
                            keyfilter="num"
                            value={placeNumber}
                            onChange={(e) => setPlaceNumber(e.target.value)}
                        />
                        <label htmlFor="username">Número</label>
                    </span>
                </div>
                {/* <div className='col-12 md:col-4'>
                    <span className="p-float-label w-100">
                        <InputText
                            value={avenue}
                            onChange={(e) => setAvenue(e.target.value)}
                        />
                        <label htmlFor="username">Avenida</label>
                    </span>
                </div> */}

                <div className='col-12 md:col-4'>
                    <span className="p-float-label w-100">
                        <InputText
                            keyfilter="num"
                            value={postalCode}
                            maxLength={5}
                            onChange={(e) => handleCPChange(e.target.value)}
                        />
                        <label htmlFor="username">C.P.</label>
                    </span>
                </div>
                <div className='col-12 md:col-4'>
                    <span className="p-float-label w-100">
                        <Dropdown
                            value={selectedColony}
                            onChange={(e) => setSelectedColony(e.value)}
                            options={colonyList}
                            optionLabel='cp_col'
                            placeholder='Selecciona tu colonia'
                            className='w-full'
                        />
                    </span>
                </div>
                {/* <div className='col-12 md:col-4'>
                    <div className='flex justify-content-end'>
                        <div style={{ width: '50%' }}>
                            <Button label="Buscar " />
                        </div>
                    </div>
                </div> */}
            </div>

            <Panel header="SELECCIONA LA SUCURSAL MÁS CERCANA A TU UBICACIÓN" style={{ marginTop: 40 }} toggleable>
                <div className="grid">
                    <div className='col-12 md:col-2'>
                        <p>La Mas recomendada</p>
                    </div>
                    <div className='col-12 md:col-10'>
                        <p>Otras Sugerencias</p>
                    </div>
                    {companyLocations && companyLocations.map((singleLocation: any, index) => {
                        if (index === 0) {
                            return (
                                <div className='col-12 md:col-2'>
                                    <div className="flex align-items-center">
                                        <Checkbox inputId="location" checked={singleLocation.selected} onChange={(e) => { handleCheckLocation(e, index) }} />
                                        <label htmlFor="location" className="ml-2">{singleLocation.suc_nom}</label>
                                    </div>
                                </div>
                            )
                        } else {
                            return (
                                <div className='col-12 md:col-1'>
                                    <div className="flex align-items-center">
                                        <Checkbox inputId="location" checked={singleLocation.selected} onChange={(e) => { handleCheckLocation(e, index) }} />
                                        <label htmlFor="location" className="ml-2">{singleLocation.suc_nom}</label>
                                    </div>
                                </div>
                            )
                        }
                    })}
                </div>
                <div style={{ height: '50vh', width: '100%', marginTop: 20 }}>
                    <MapEx 
                        currentLocation={currentLocation}
                        companyLocations={companyLocations}
                    />
                </div>
            </Panel>

            <div className='col-12 md:col-12' style={{ marginTop: 40 }}>
                <div className='flex justify-content-end'>
                    <div style={{ width: '16%' }}>
                        <Button label="Enviar" onClick={handleAdd} />
                    </div>
                </div>
            </div>

            <Dialog header="Nueva solicitud enviada" visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)} footer={footerContent} draggable={false} resizable={false}>
                <div className='text-center'>
                    <p className="m-0">
                        Se ha enviado la solicitud, en 12 horas tendrás una respuesta.
                    </p>
                    <h3>{apiResponse && apiResponse.folio}</h3>
                    <small>Folio generado</small>
                </div>
            </Dialog>
        </section>
    )

}

export default React.memo(MovimientoEnvio);
