import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Dropdown } from 'primereact/dropdown';
import authGuardService from '../../../../service/authGuardService';
import { PerfilService } from '../../../../service/perfil.service';
import UserService from '../../../../service/user.service';
import ApiServices
    from '../../../../service/apiServices';
import EncryptService from '../../../../service/encrypt.service';
import { FilterMatchMode } from 'primereact/api';
const UsersConfiguration = () => {

    const [userList, setUserList] = useState([])

    const getUsers = () => {
        let datos
        ApiServices.apiPost('listUsuarios', datos).then(async res => {
            formatUsersList(res.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }

    useEffect(() => {
        getUsers()
    }, [])

    const formatUsersList = (list: any) => {
        const listClone = structuredClone(list)

        if (listClone && listClone.length > 0) {
            const newList = listClone.map((singleUser: any) => {
                const newUser = singleUser
                newUser.usu_full_name = `${singleUser.usu_nom} ${singleUser.usu_pat} ${singleUser.usu_mat} `
                newUser.prf_desc = singleUser.prf_id === 0 ? 'Administrador' : 'Cliente'
                return newUser
            })
            console.log(newList)
            setUserList(newList)
        }
    }







    /* OLD */
    let emptyCatalog = {
        // name: '',
        // password: '',
        // confirmPassword: '',
        // username: '',
        usu_id:'',
        prf_id:'',
        usu_nom:'',
        usu_pat:'',
        usu_mat:'',
        usu_empresa:'',
        usu_tel:'',
        usu_email:'',
        usu_psw:'',
        confirm_usu_psw: '',
    };

    const token = authGuardService.getToken()
    const [dropdownValue, setDropdownValue] = useState<any>(null);
    const [dropdownValues, setDropdownValues] = useState<any>(null);
    const [catalogs, setCatalogs] = useState<any>(null);
    const [catalog, setCatalog] = useState<any>(emptyCatalog);
    const [addDialog, setAddDialog] = useState<boolean>(false);
    const [deleteDialog, setDeleteDialog] = useState<boolean>(false);
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [globalFilter, setGlobalFilter] = useState("");
    const [validEmail, setValidEmail] = useState<any>(false);

    const onGlobalFilterChange = (e: any) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters["global"].value = value;
        setFilters(_filters);
        setGlobalFilter(value);
    };

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const toast = useRef<any>(null);
    const settingsRef = useRef<any>(null);

    useEffect(() => {
        // handleGetSettings()
        handleGetTypes()
    }, []); 

    //Obtiene los Setting de la DB
    const handleGetSettings = async () => {
        const allUSers = await UserService.getAllUsers(token).then((response) => {
            if (response.data.valido == 0) toast.current.show({ severity: 'error', summary: 'Error', detail: 'Hubo un error al descargar los datos', life: 4000 })
            else setCatalogs(response.data.addenda)
        }).catch((error) => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Hubo un error al descargar los datos', life: 4000 });
        })
    }

    //Obtiene los valores de los tipos de perfiles de la DB
    const handleGetTypes = async () => {
        const allUSers = await PerfilService.getPerfiles({}, token).then((response) => {
            if (response.data.valido == 0) toast.current.show({ severity: 'error', summary: 'Error', detail: 'Hubo un error al descargar los datos', life: 4000 })
            else {
                const values = response.data.addenda.map((element: any) => {
                    return { name: element.descripcion, code: element.id_perfil }
                });
                setDropdownValues(values)
            }
        }).catch((error) => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Hubo un error al descargar los datos', life: 4000 });
        })
    }

    //Guarda o Edita el Catalogo seleccionado
    const saveCatalog = async () => {
        setSubmitted(true);
        //Si el Catalogo tiene ID se mandara a Actulizar ese registro de lo contrario se mandara a agregar.
        if (catalog.usu_id) {
        // if (catalog.id_usuario_sistema) {
            

            let data: any = {
                "code":"205",
                "usu_id": catalog.usu_id,
                "prf_id": catalog.prf_id,
                "usu_nom": catalog.usu_nom,
                "usu_pat": catalog.usu_pat,
                "usu_mat": catalog.usu_mat,
                "usu_empresa": catalog.usu_empresa,
                "usu_tel": catalog.usu_tel,
                "usu_email": catalog.usu_email,
                "usu_psw": catalog.usu_psw,
            }
            data = await EncryptService.encryptData(JSON.stringify(data));
            ApiServices.processRequestAdd(data)
            .then(async (res) => {
                handleGetTypes()
                hideDialog();
                toast.current.show({ severity: 'success', summary: 'Exito', detail: 'Registro Editado con Exito', life: 3000 });
            })
            .catch((error) => {
                /* Toasty Error */
                console.log(error)
            });
            // const newCatalog = {
            //     ...catalog,
            //     id_perfil: dropdownValue.code,
            //     nip: catalog.password ? catalog.password : catalog.nip,
            //     nipconfifm: catalog.confirmPassword ? catalog.confirmPassword : null
            // }
            // UserService.modificaUsuario(newCatalog, token).then((response) => {
            //     if (response.data.valido == 0) toast.current.show({ severity: 'error', summary: 'Error', detail: 'Hubo un error al agregar', life: 4000 })
            //     else {
            //         hideDialog();
            //         toast.current.show({ severity: 'success', summary: 'Exito', detail: 'Registro Editado con Exito', life: 3000 });
            //         handleGetSettings()
            //     }
            // }).catch((error) => {
            //     toast.current.show({ severity: 'error', summary: 'Error', detail: 'Hubo un error al descargar los datos', life: 4000 });
            // })
        } else {
            if (dropdownValue && catalog.usu_psw && catalog.confirm_usu_psw && catalog.usu_nom.length > 0 && catalog.usu_email.length > 0 && catalog.usu_psw === catalog.confirm_usu_psw) {
                let data: any = {
                    "code":"105",
                    // "usu_id": catalog.usu_id,
                    "prf_id": 1,
                    "usu_nom": catalog.usu_nom,
                    "usu_pat": catalog.usu_pat,
                    "usu_mat": catalog.usu_mat,
                    "usu_empresa": catalog.usu_empresa,
                    "usu_tel": catalog.usu_tel,
                    "usu_email": catalog.usu_email,
                    "usu_psw": catalog.usu_psw,
                }
                data = await EncryptService.encryptData(JSON.stringify(data));
                ApiServices.processRequestAdd(data)
                .then(async (res) => {
                    handleGetTypes()
                    hideDialog();
                    toast.current.show({ severity: 'success', summary: 'Exito', detail: 'Registro Agregado con Exito', life: 3000 });
                })
                .catch((error) => {
                    /* Toasty Error */
                    console.log(error)
                });
                
                // const newCatalog = {
                //     ...catalog,
                //     id_perfil: dropdownValue.code,
                //     nip: catalog.password,
                //     nipconfifm: catalog.confirmPassword,
                //     usu_status: 1,
                //     usu_edit: null,
                //     id_usuario_sistema: findIndexById()
                // }
                // UserService.agregaUsuario(newCatalog, token).then((response) => {
                //     if (response.data.valido == 0) toast.current.show({ severity: 'error', summary: 'Error', detail: 'Hubo un error al agregar', life: 4000 })
                //     else {
                //         hideDialog();
                //         toast.current.show({ severity: 'success', summary: 'Exito', detail: 'Registro Agregado con Exito', life: 3000 });
                //         // handleGetSettings()
                //         setUserList(response.data.addenda)
                //     }
                // }).catch((error) => {
                //     toast.current.show({ severity: 'error', summary: 'Error', detail: 'Hubo un error al descargar los datos', life: 4000 });
                // })
            }
        }
    }

    //Borra el Catalogo seleccionado
    const deleteCatalog = async () => {
        if (catalog.usu_id) {
            // if (catalog.id_usuario_sistema) {
            const newCatalog = {
                usu_id: catalog.usu_id
            }
            ApiServices.apiPost('deleteUsuario', newCatalog).then((response) => {
                if (response.data.valido == 0) toast.current.show({ severity: 'error', summary: 'Error', detail: 'Hubo un error al eliminar el registro', life: 4000 })
                else {
                    hideDeleteDialog();
                    toast.current.show({ severity: 'success', summary: 'Exito', detail: 'Registro eliminado con exito', life: 3000 });
                    // handleGetSettings()
                    setUserList(response.data.addenda)

                }
            }).catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Hubo un error al elimar los datos', life: 4000 });
            })
        }


    }

    //Abre el Modal para agregar el catalogo seleccionado
    const openNew = () => {
        setCatalog(emptyCatalog);
        setSubmitted(false);
        setAddDialog(true);
    }

    //Oculta el modal para agregar o editar el catalogo seleccionado
    const hideDialog = () => {
        setSubmitted(false);
        setAddDialog(false);
    }

    //Oculta el modal de eliminarel catalogo seleccionado
    const hideDeleteDialog = () => {
        setDeleteDialog(false);
    }

    //Abre el Modal para editar un catalogo seleccionado
    const editProduct = (catalog: any) => {
        console.log("USUARIO SELECCIONADO -->",catalog)
        setDropdownValue({ name: catalog.descripcion, code: catalog.id_perfil })
        setCatalog(catalog);
        setAddDialog(true);
    }

    //Abre el Modal para eliminar el catalogo seleccionado
    const confirmDeleteProduct = (catalog: any) => {
        console.log(catalog)
        setCatalog(catalog);
        setDeleteDialog(true);
    }

    const findIndexById = (): number => {
        let index = 0
        catalogs.forEach((element: { id_usuario_sistema: number; }) => { if (element.id_usuario_sistema >= index) index = element.id_usuario_sistema });
        return index + 1
    }

    //Funcion para editar los campos
    const onInputChange = (e: any, name: string) => {
        const val = (e.target && e.target.value) || '';
        let _product = { ...catalog };
        _product[`${name}`] = val;
        setCatalog(_product);
    }

    const validateEmail = (email: any, name: string) => {
        onInputChange(email, name)
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        setValidEmail(re.test(email.target.value))
    }

    //Botones de Editar y Eliminar
    const actionBodyTemplate = (rowData: any) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-warning mr-2" onClick={() => editProduct(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mt-2" onClick={() => confirmDeleteProduct(rowData)} />
            </div>
        );
    }

    const AddFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Guardar" icon="pi pi-save" className="p-button-text" onClick={saveCatalog} />
        </>
    );
    const deleteFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDialog} />
            <Button label="Confirmar" icon="pi pi-check" className="p-button-text" onClick={deleteCatalog} />
        </>
    );

    return (
        <div className="grid table-demo">
            <Toast ref={toast} />

            <div className="col-12">
                <div className="flex flex-column align-items-center md:flex-row md:justify-content-between md:align-items-center" style={{ marginTop: 20, marginBottom: 20 }}>
                    <div className="col-12 md:col-8">
                        <h3 className="m-0">Usuarios</h3>
                        <h6>Crea un nuevo usuario y asignale un nuevo perfil</h6>
                    </div>
                    <div className="col-12 md:col-2">
                        <InputText type="search" placeholder='Buscador general' value={globalFilter} onChange={onGlobalFilterChange} style={{ marginRight: 10 }} />
                    </div>
                    <span className="col-12 md:col-2">
                        <Button label="Nuevo Registro" icon="pi pi-plus" iconPos="left" onClick={openNew} />
                    </span>
                </div>

                <DataTable
                    ref={settingsRef}
                    value={userList}
                    dataKey="st_id"
                    paginator
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25]}
                    className="datatable-responsive"
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
                    emptyMessage="No hay registros en la tabla."
                    size='small'
                    filters={filters}
                >
                    <Column field="usu_id" header="Usuario" sortable></Column>
                    <Column field="usu_full_name" header="Nombre" sortable></Column>
                    <Column field="prf_desc" header="Tipo de Perfil" sortable></Column>
                    <Column field="usu_lasttime" header="Último Ingreso" sortable></Column>

                    <Column body={actionBodyTemplate}></Column>
                </DataTable>
            </div>

            <Dialog visible={addDialog} style={{ width: '850px' }} modal className="p-fluid" footer={AddFooter} onHide={hideDialog}>
                <h4>Crear usuario</h4>
                <div className="p-2 grid">
                    <div className='col-12 md:col-4'>
                        <div className="field">
                            <span className="p-float-label">
                                <InputText
                                    id="name"
                                    value={catalog.usu_nom}
                                    onChange={(e) => onInputChange(e, 'usu_nom')}
                                    className={classNames({ 'p-invalid': submitted && !catalog.usu_nom })}
                                    tooltip="Ingresa el nombre"
                                />
                                {submitted && !catalog.usu_nom && <small className="p-invalid">Descripción es requerido.</small>}
                                <label htmlFor="name">Nombre</label>
                            </span>
                        </div>
                    </div>
                    <div className='col-12 md:col-4'>
                        <div className="field">
                            <span className="p-float-label">
                                <InputText
                                    id="lastname"
                                    value={catalog.usu_pat}
                                    onChange={(e) => onInputChange(e, 'usu_pat')}
                                    className={classNames({ 'p-invalid': submitted && !catalog.usu_pat })}
                                    tooltip="Ingresa el primer apellido"
                                />
                                <label htmlFor="name">Primer apellido</label>
                                {submitted && !catalog.usu_pat && <small className="p-invalid">Descripción es requerido.</small>}
                            </span>
                        </div>
                    </div>
                    <div className='col-12 md:col-4'>
                        <div className="field">
                            <span className="p-float-label">
                                <InputText
                                    id="lastname"
                                    value={catalog.usu_mat}
                                    onChange={(e) => onInputChange(e, 'usu_mat')}
                                    className={classNames({ 'p-invalid': submitted && !catalog.usu_mat })}
                                    tooltip="Ingresa el segundo apellido"
                                />
                                <label htmlFor="name">Segundo apellido</label>
                                {submitted && !catalog.usu_mat && <small className="p-invalid">Descripción es requerido.</small>}
                            </span>
                        </div>
                    </div>
                    <div className='col-12 md:col-4'>
                        <div className="field">
                            <span className="p-float-label">
                                <InputText
                                    id="lastname"
                                    value={catalog.usu_empresa}
                                    onChange={(e) => onInputChange(e, 'usu_empresa')}
                                    className={classNames({ 'p-invalid': submitted && !catalog.usu_empresa })}
                                    tooltip="Ingresa el segundo apellido"
                                />
                                <label htmlFor="name">Nombre de la empresa</label>
                                {submitted && !catalog.usu_empresa && <small className="p-invalid">Descripción es requerido.</small>}
                            </span>
                        </div>
                    </div>
                    <div className='col-12 md:col-4'>
                        <div className="field">
                            <span className="p-float-label">
                                <InputText
                                    id="lastname"
                                    value={catalog.usu_tel}
                                    onChange={(e) => onInputChange(e, 'usu_tel')}
                                    className={classNames({ 'p-invalid': submitted && !catalog.usu_tel })}
                                    tooltip="Ingresa el segundo apellido"
                                />
                                <label htmlFor="name">Teléfono</label>
                                {submitted && !catalog.usu_tel && <small className="p-invalid">Teléfono es requerido.</small>}
                            </span>
                        </div>
                    </div>
                    <div className='col-12 md:col-4'>
                        <div className="field">
                            <span className="p-float-label">
                                <InputText
                                    id="username"
                                    value={catalog.usu_email}
                                    onChange={(e) => validateEmail(e, 'usu_email')}
                                    className={classNames({ 'p-invalid': submitted && !catalog.usu_email && !validEmail })}
                                    tooltip="Ingresa el Correo"
                                />
                                <label htmlFor="username">Correo</label>
                                {submitted && !catalog.usu_email && <small className="p-invalid">Correo es requerido.</small>}
                                {!validEmail && <small className="p-invalid">El correo no es valido.</small>}
                            </span>
                        </div>
                    </div>
                    <div className='col-12 md:col-4'>
                        <div className="field">
                            {/* <label htmlFor="dropdownValue">Tipo de Perfil</label> */}
                            <Dropdown
                                value={dropdownValue}
                                onChange={(e) => setDropdownValue(e.value)}
                                options={dropdownValues}
                                optionLabel="name"
                                placeholder="Tipo de perfil"
                                className={classNames({ 'p-invalid': submitted && !dropdownValue })}
                                tooltip="Selecciona el tipo de perfil"
                            />
                            {submitted && !dropdownValue && <small className="p-invalid">Tipo de usuario es requerido.</small>}
                        </div>
                    </div>
                </div>
                <h4>Contraseña Previa</h4>
                <div className="grid">
                    <div className='col-12 md:col-4'>
                        <div className="field" style={{ height: 120 }}>
                            <span className="p-float-label">
                                <Password
                                    value={catalog.usu_psw}
                                    onChange={(e) => onInputChange(e, 'usu_psw')}
                                    toggleMask
                                    className={classNames({ 'p-invalid': submitted && !catalog.usu_psw && !catalog.id_usuario_sistema })}
                                    tooltip="Ingresa la contraseña"
                                />
                                <label htmlFor="password">Contraseña</label>
                            </span>

                            {submitted && !catalog.usu_psw && !catalog.usu_id && <small className="p-invalid">Contraseña es requerida.</small>}
                            {submitted && catalog.usu_psw != catalog.usu_psw && <small className="p-invalid">Las contraseñas no coinciden.</small>}
                        </div>
                    </div>
                    <div className='col-12 md:col-4'>
                        <div className="field" style={{ height: 120 }}>
                            <span className="p-float-label">
                                <Password
                                    value={catalog.confirm_usu_psw}
                                    onChange={(e) => onInputChange(e, 'confirm_usu_psw')}
                                    className={classNames({ 'p-invalid': submitted && !catalog.confirm_usu_psw && !catalog.usu_id })}
                                    toggleMask
                                    tooltip="Confirma la contraseña"
                                />
                                <label htmlFor="confirmPassword">Confirmar Contraseña</label>
                            </span>
                            {submitted && !catalog.confirm_usu_psw && !catalog.usu_id && <small className="p-invalid">Confirmar contraseña es requerido.</small>}
                            {submitted && catalog.usu_psw != catalog.confirm_usu_psw && <small className="p-invalid">Las contraseñas no coinciden.</small>}
                        </div>
                    </div>
                </div>

            </Dialog>

            <Dialog visible={deleteDialog} style={{ width: '450px' }} header="Eliminar" modal footer={deleteFooter} onHide={hideDeleteDialog}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {catalog && <span>Estas seguro de que quieres eliminar este registro <b>{catalog.name}</b>?</span>}
                </div>
            </Dialog>
        </div >
    );
}

export default React.memo(UsersConfiguration);
