import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import PrimeReact, { addLocale } from "primereact/api";
import "primereact/resources/primereact.min.css";
import { Tooltip } from "primereact/tooltip";
import { classNames } from "primereact/utils";
import { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import "./App.scss";
import configFile from "./configurationFile";
import Dashboard from "./pages/administrador/Dashboard";
import AdminCotizacion from "./pages/administrador/cotizacion/AdminCotizacion";
import ViewCotizacion from "./pages/administrador/cotizacion/ViewCotizacion";
import AdminHistorial from "./pages/administrador/historial/AdminHistorial";
import ViewHistorial from "./pages/administrador/historial/ViewHistorial";
import AdminPagos from "./pages/administrador/pagos/AdminPagos";
import ViewPagos from "./pages/administrador/pagos/ViewPagos";
import AdminReciclaje from "./pages/administrador/reciclaje/AdminReciclaje";
import Accesos from "./pages/administrador/seguridad/accesos/Accesos";
import AppBreadcrumb from "./pages/administrador/shared/BreadCrumb";
import AppFooter from "./pages/administrador/shared/Footer";
import AppTopbar from "./pages/administrador/shared/TopBar";
import AdminVentanilla from "./pages/administrador/ventanilla/AdminVentanilla";
import ViewVentanilla from "./pages/administrador/ventanilla/ViewVentanilla";
import PerfilCliente from "./pages/cliente/Perfil";
import Historial from "./pages/cliente/historial/Historial";
import HistorialDetalle from "./pages/cliente/historial/HistorialDetalle";
import InicioCliente from "./pages/cliente/inicio";
import MovimientoCompra from "./pages/cliente/movimientos/MovimientoCompra";
import MovimientoDetalle from "./pages/cliente/movimientos/MovimientoDetalle";
import MovimientoEnvio from "./pages/cliente/movimientos/MovimientoEnvio";
import MovimientoPago from "./pages/cliente/movimientos/MovimientoPago";
import MovimientoPagoVentanilla from "./pages/cliente/movimientos/MovimientoVentanilla"; 
import MovimientoReciclaje from "./pages/cliente/movimientos/MovimientoReciclaje";
import MovimientoResumen from "./pages/cliente/movimientos/MovimientoResumen";
import Profile from "./pages/otros/Profile";
import authGuardService from "./service/authGuardService";
import MovimientoPagoAcreditando from "./pages/cliente/movimientos/MovimientoPagoAcreditando";
import ApiServices from "./service/apiServices";
import CatMateriales from "./pages/administrador/catalogos/materiales/CatMateriales";
import CatMovimientos from "./pages/administrador/catalogos/movimientos/CatMovimientos";
import AdminReports from "./pages/administrador/reportes/AdminReports";

const App = (props: any) => {

  const user = authGuardService.getUser()

  addLocale('es', {
    firstDayOfWeek: 1,
    dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
    dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'],
    dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
    monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
    monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
    today: 'Hoy',
    clear: 'Limpiar'
  });

  const [menuMode, setMenuMode] = useState(configFile.menu);

  const [rightMenuActive, setRightMenuActive] = useState(false);
  const [configActive, setConfigActive] = useState(false);
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [sidebarStatic, setSidebarStatic] = useState(false);
  const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
  const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
  const [menuActive, setMenuActive] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const [topbarMenuActive, setTopbarMenuActive] = useState(false);
  const [notificationMenuActive, setNotificationMenuActive] = useState(false);
  const [ripple, setRipple] = useState(true);
  const [sidebarActive, setSidebarActive] = useState(false);
  const [pinActive, setPinActive] = useState(false);
  const [activeInlineProfile, setActiveInlineProfile] = useState(false);
  const [resetActiveIndex, setResetActiveIndex] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<any>([]);

  const isAuthenticated = () => {
    return user && user.prf_id === 0
  };

  const menu = [
    {
      label: "Inicio",
      to: "/home",
    },
    {
      label: "Cotización",
      to: "/cotizacion",
    },
    {
      label: "Pagos",
      to: "/pagos",
    },
    {
      label: "Ventanilla",
      to: "/ventanilla",
    },
    {
      label: "Reciclaje",
      to: "/reciclaje",
    },
    {
      label: "Historial",
      to: "/historial",
    },
    {
      label: "Accesos",
      to: "/accesos/usuarios",
    },
    /* LIGA A PANTALLAS DE CLIENTE*/
    {
      label: "Inicio - Cliente",
      to: "/inicio",
    },
    {
      label: "Historial de compras - Cliente",
      to: "/c-historial",
    },
  ];

  const clientMenu = [
    {
      label: "Inicio",
      to: "/inicio",
    },
    // {
    //   label: "Historial de Compras",
    //   to: "/historial",
    // },
  ]

  const adminMenu = [
    {
      label: "Inicio",
      to: "/administrador/inicio",
    },
    {
      label: "Cotizaciones",
      to: "/administrador/cotizaciones",
    },
    {
      label: "Pagos",
      to: "/administrador/pagos",
    },
    {
      label: "Ventanilla",
      to: "/administrador/ventanilla",
    },
    {
      label: "Reciclaje",
      to: "/administrador/reciclaje",
    },
    {
      label: "Historial",
      to: "/administrador/historial",
    },
    {
      label: "Accesos",
      to: "/administrador/accesos",
    },
    {
      label: "Materiales",
      to: "/administrador/cat-materiales",
    },
    {
      label: "Movimientos",
      to: "/administrador/cat-movimientos",
    },
    {
      label: "Reportes",
      to: "/administrador/reportes",
    }
  ]

  const routes = [
    { parent: "Inicio", label: "Inicio" },
    { parent: "Inicio", label: "Cotizacion" },
    { parent: "Inicio", label: "Cotizacion / Solicitud" },
    { parent: "Inicio", label: "Pagos" },
    { parent: "Inicio", label: "Pagos / Solicitud" },
    { parent: "Inicio", label: "Ventanilla" },
    { parent: "Inicio", label: "Ventanilla / Solicitud" },
    { parent: "Inicio", label: "Reciclaje" },
    { parent: "Inicio", label: "Historial" },
    { parent: "Inicio", label: "Accesos / Usuarios" },
    { parent: "Inicio", label: "Accesos / Perfiles" },
    { parent: "Inicio", label: "Perfil" },
    
  ];

  let rightMenuClick: any;
  let configClick: any;
  let menuClick: any;
  let searchClick: boolean = false;
  let topbarItemClick: any;
  let topbarNotificationItemClick: any;

  useEffect(() => {
    setResetActiveIndex(true);
    setMenuActive(false);
    getNotifications();
  }, [menuMode]);

  const onDocumentClick = () => {
    if (!searchClick && searchActive) {
      onSearchHide();
    }

    if (!topbarItemClick) {
      setTopbarMenuActive(false);
    }

    if (!topbarNotificationItemClick) {
      setNotificationMenuActive(false);
    }

    if (!menuClick) {
      if (isHorizontal() || isSlim()) {
        setMenuActive(false);
        setResetActiveIndex(true);
      }

      if (overlayMenuActive || staticMenuMobileActive) {
        setOverlayMenuActive(false);
        setStaticMenuMobileActive(false);
      }

      hideOverlayMenu();
      unblockBodyScroll();
    }

    if (!rightMenuClick) {
      setRightMenuActive(false);
    }

    if (configActive && !configClick) {
      setConfigActive(false);
    }

    topbarItemClick = false;
    topbarNotificationItemClick = false;
    menuClick = false;
    configClick = false;
    rightMenuClick = false;
    searchClick = false;
  };

  const onSearchHide = () => {
    setSearchActive(false);
    searchClick = false;
  };

  //Cambiar la posicion del menu
  const onMenuModeChange = (menuMode: any) => {
    setMenuMode(menuMode);
    setOverlayMenuActive(false);
  };

  const onRightMenuButtonClick = () => {
    rightMenuClick = true;
    setRightMenuActive(true);
  };

  const onRightMenuClick = () => {
    rightMenuClick = true;
  };

  const onRightMenuActiveChange = (active: any) => {
    setRightMenuActive(active);
  };

  const onConfigClick = () => {
    configClick = true;
  };

  const onConfigButtonClick = (event: any) => {
    setConfigActive((prevState) => !prevState);
    configClick = true;
    event.preventDefault();
  };

  const onRippleChange = (e: any) => {
    PrimeReact.ripple = e.value;
    setRipple(e.value);
  };

  const onMenuButtonClick = (event: any) => {
    menuClick = true;

    if (isOverlay()) {
      setOverlayMenuActive((prevState) => !prevState);
    }

    if (isDesktop()) {
      setStaticMenuDesktopInactive((prevState) => !prevState);
    } else {
      setStaticMenuMobileActive((prevState) => !prevState);
    }

    event.preventDefault();
  };

  const hideOverlayMenu = () => {
    setOverlayMenuActive(false);
    setStaticMenuMobileActive(false);
  };

  const toggleNotificationMenu = (event: any) => {
      topbarNotificationItemClick = true;
      getNotifications()
      setNotificationMenuActive(!notificationMenuActive);
      hideOverlayMenu();
      event.preventDefault();
  }

  const onTopbarItemClick = (event: any) => {
    topbarItemClick = true;
    setTopbarMenuActive((prevState) => !prevState);
    hideOverlayMenu();
    event.preventDefault();
  };

  const onToggleMenu = (event: any) => {
    menuClick = true;

    if (overlayMenuActive) {
      setOverlayMenuActive(false);
    }

    if (sidebarActive) {
      setSidebarStatic((prevState) => !prevState);
    }

    event.preventDefault();
  };

  const onSidebarMouseOver = () => {
    if (menuMode === "sidebar" && !sidebarStatic) {
      setSidebarActive(isDesktop());
      setTimeout(() => {
        setPinActive(isDesktop());
      }, 200);
    }
  };

  const onSidebarMouseLeave = () => {
    if (menuMode === "sidebar" && !sidebarStatic) {
      setTimeout(() => {
        setSidebarActive(false);
        setPinActive(false);
      }, 250);
    }
  };

  const onMenuClick = () => {
    menuClick = true;
  };

  const onChangeActiveInlineMenu = (event: any) => {
    setActiveInlineProfile((prevState) => !prevState);
    event.preventDefault();
  };

  const onRootMenuItemClick = () => {
    setMenuActive((prevState) => !prevState);
  };

  const onMenuItemClick = (event: any) => {
    if (!event.item.items) {
      hideOverlayMenu();
      setResetActiveIndex(true);
    }

    if (!event.item.items && (isHorizontal() || isSlim())) {
      setMenuActive(false);
    }
  };

  const isHorizontal = () => {
    return menuMode === "horizontal";
  };

  const isSlim = () => {
    return menuMode === "slim";
  };

  const isOverlay = () => {
    return menuMode === "overlay";
  };

  const isDesktop = () => {
    return window.innerWidth > 991;
  };

  const onInputClick = () => {
    searchClick = true;
  };

  const breadcrumbClick = () => {
    searchClick = true;
    setSearchActive(true);
  };

  const unblockBodyScroll = () => {
    if (document.body.classList) {
      document.body.classList.remove("blocked-scroll");
    } else {
      document.body.className = document.body.className.replace(
        new RegExp(
          "(^|\\b)" + "blocked-scroll".split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      );
    }
  };

  const layoutClassName = classNames("layout-wrapper", {
    "layout-static": menuMode === "static",
    "layout-overlay": menuMode === "overlay",
    "layout-overlay-active": overlayMenuActive,
    "layout-slim": menuMode === "slim",
    "layout-horizontal": menuMode === "horizontal",
    "layout-active": menuActive,
    "layout-mobile-active": staticMenuMobileActive,
    "layout-sidebar": menuMode === "sidebar",
    "layout-sidebar-static": menuMode === "sidebar" && sidebarStatic,
    "layout-static-inactive":
      staticMenuDesktopInactive && menuMode === "static",
    "p-ripple-disabled": !ripple,
  });

  const selectHome = () => {
    if (user && user.prf_id === 0) {
      return <Dashboard />
    } else if (user && user.prf_id === 1) {
      return <InicioCliente />
    } else {
      return <InicioCliente />
    }
  }

  const selectMenu = () => {
    if (user && user.prf_id === 0) {
      return adminMenu
    } else {
      return clientMenu
    }
  }

  const getNotifications = async () => {
      if(!user) {
          return
      }
      let datos: any = {
          usu_id: user.usu_id ?? 0,
      }
      //datos = await EncryptService.encryptData(JSON.stringify(datos));
      ApiServices.apiPost('getNotificaciones', datos).then(async res => {
          const notificationsEx = res.data.addenda
          setNotifications(notificationsEx)
      }).catch(error => {
          console.log(error)
      })
  }

  return (
    <div className={layoutClassName} onClick={onDocumentClick}>
      <Tooltip
        /* ref={copyTooltipRef} */ target=".block-action-copy"
        position="bottom"
        content="Copied to clipboard"
        event="focus"
      />

      <div className="layout-main">
        {/* Menu cuando esta en modo horizontal */}
        <AppTopbar
          items={selectMenu()}
          notifications={notifications}
          menuMode={menuMode}
          colorScheme={props.colorScheme}
          menuActive={menuActive}
          topbarMenuActive={topbarMenuActive}
          activeInlineProfile={activeInlineProfile}
          onTopbarItemClick={onTopbarItemClick}
          notificationMenuActive={notificationMenuActive}
          toggleNotificationMenu={toggleNotificationMenu}
          onMenuButtonClick={onMenuButtonClick}
          onSidebarMouseOver={onSidebarMouseOver}
          onSidebarMouseLeave={onSidebarMouseLeave}
          onToggleMenu={onToggleMenu}
          onChangeActiveInlineMenu={onChangeActiveInlineMenu}
          onMenuClick={onMenuClick}
          onMenuItemClick={onMenuItemClick}
          onRootMenuItemClick={onRootMenuItemClick}
          resetActiveIndex={resetActiveIndex}
        />

        <AppBreadcrumb
          routes={routes}
          onMenuButtonClick={onMenuButtonClick}
          menuMode={menuMode}
          onRightMenuButtonClick={onRightMenuButtonClick}
          onInputClick={onInputClick}
          searchActive={searchActive}
          breadcrumbClick={breadcrumbClick}
        />

        {/* Menu al dar click te dirige a la vista*/}
        <div className="layout-main-content">
          <Routes>
            <Route path="/inicio" element={selectHome()} /> {/* DONE */}

            {/* ADMIN */}
            {isAuthenticated() &&
              <>
                <Route path="/administrador/inicio" element={<Dashboard />} />{/* INICIO */}
                <Route path="/administrador/cotizaciones" element={<AdminCotizacion />} />{/* COTIZACIONES */}
                <Route path="/administrador/cotizaciones/detalle" element={<ViewCotizacion />} />{/* DETALLE SOLICITUD */}
                <Route path="/administrador/pagos" element={<AdminPagos />} />{/* PAGOS */}
                <Route path="/administrador/pagos/detalle" element={<ViewPagos />} />{/* DETALLE PAGO */}
                <Route path="/administrador/ventanilla" element={<AdminVentanilla />} />{/* VENTANILLA */}
                <Route path="/administrador/ventanilla/detalle" element={<ViewVentanilla />} />{/* DETALLE VENTANILLA */}
                <Route path="/administrador/reciclaje" element={<AdminReciclaje />} />{/* RECICLAJE */}
                <Route path="/administrador/historial" element={<AdminHistorial />} />{/* HISTORIAL */}
                <Route path="/administrador/historial/detalle" element={<ViewHistorial />} />{/* DETALLE HISTORIAL */}
                <Route path="/administrador/accesos" element={<Accesos />} />{/* LISTADO DE USUARIOS */}
                <Route path="/administrador/reportes" element={<AdminReports />} />
                {/* CATALOGOS ? */}
                <Route path="/administrador/perfil" element={<Profile />} />{/* MISSING PROFILE IN FIGMA */}
                <Route path="/administrador/cat-materiales" element={<CatMateriales />} />
                <Route path="/administrador/cat-movimientos" element={<CatMovimientos/>} />
              </>
            }
  {/* MovimientoPagoVentanilla */}
            {/* CLIENTE  */}
            <Route path="/inicio" element={<InicioCliente />} />
            <Route path="/perfil" element={<PerfilCliente />} />
            <Route path="/movimiento/envio" element={<MovimientoEnvio />} />
            <Route path="/movimiento/compra" element={<MovimientoCompra />} />
            <Route path="/movimiento/reciclaje" element={<MovimientoReciclaje />} />
            <Route path="/movimiento/detalle" element={<MovimientoDetalle />} />{/* ESTATUS INGRESADA */}
            <Route path="/movimiento/pago" element={<MovimientoPago />} />{/* PAGO EN LINEA */}
            <Route path="/movimiento/pago/ventanilla" element={<MovimientoPagoVentanilla />} />{/* PAGO VENTANILLA */}
            <Route path="/movimiento/pago/acreditando" element={<MovimientoPagoAcreditando />} />{/* PAGO EN LINEA */}
            <Route path="/movimiento/resumen" element={<MovimientoResumen />} />{/* SOLICITUD ACTIVO DETALLE || PENDIENTE DETALLE */}
            {/* <Route path="/historial" element={<Historial />} />HISTORIAL */}
            {/* <Route path="/historial/detalle" element={<HistorialDetalle />} />DETALLE HISTORIAL */}

            {/* GENERAL */}
            <Route path="/" element={() => <Navigate to="/inicio" />}></Route>
          </Routes>
        </div>

        <AppFooter colorScheme={props.colorScheme} />
      </div>
    </div>
  );
};

export default App;
