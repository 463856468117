import React from 'react';

const Profile = () => {

    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <h5>Pagina del Perfil</h5>
                </div>
            </div>
        </div>
    );

}

export default React.memo(Profile);