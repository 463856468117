import React, { useEffect, useRef, useState } from 'react';
import ApiServices from '../../../../service/apiServices';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { Tag } from 'primereact/tag';
import { Dialog } from 'primereact/dialog';
import { classNames } from 'primereact/utils';
import { Toast } from 'primereact/toast';

const CatMovimientos = () => {
    let emptyCatalog = {
        tipo_id: '',
        tipo_desc: '',
        tipo_status: '',
    };

    const [globalFilter, setGlobalFilter] = useState<any>(null);
    const dt = useRef<any>(null);
    const [selectedMovements, setSelectedMovements] = useState<any>(null);
    const [movimientos, setMovimientos] = useState([]);
    const [movement, setMovement] = useState<any>(emptyCatalog);
    const toast = useRef<any>(null);
    const [deleteDialog, setDeleteDialog] = useState<boolean>(false);
    const [addDialog, setAddDialog] = useState<boolean>(false);
    const [submitted, setSubmitted] = useState<boolean>(false);

    const getMovimientos = async () => {
        ApiServices.apiPost('catTipoMov', '').then(async res => {
            setMovimientos(res.data.addenda)
            console.log(res.data.addenda)
        }).catch( error => {
            console.log(error)
        })
    }

    useEffect(() => {
        getMovimientos();
    }, [])

    //Funcion para editar los campos
    const onInputChange = (e: any, name: string) => {
        const val = (e.target && e.target.value) || '';
        let _product = { ...movement };
        _product[`${name}`] = val;
        setMovement(_product);
    }

    //Abre el Modal para agregar el catalogo seleccionado
    const openNew = () => {
        setMovement(emptyCatalog);
        setSubmitted(false);
        setAddDialog(true);
    }

    //Oculta el modal para agregar o editar el catalogo seleccionado
    const hideDialog = () => {
        setSubmitted(false);
        setAddDialog(false);
    }

    //Abre el Modal para editar un catalogo seleccionado
    const editMovimiento = (material: any) => {
        setMovement({ ...material });
        setAddDialog(true);
    }

    //Abre el Modal para eliminar el catalogo seleccionado
    const confirmDeleteMovimiento = (material: any) => {
        setMovement(material);
        setDeleteDialog(true);
    }

    //Oculta el modal de eliminarel catalogo seleccionado
    const hideDeleteDialog = () => {
        setDeleteDialog(false);
    }

    //Guardar/editar movimientos
    const saveMovement = async () => {
        setSubmitted(true)

        if(movement.tipo_desc)
        {
            if(movement.tipo_id)
            {
                let data = {
                    tipo_id: movement.tipo_id,
                    tipo_desc: movement.tipo_desc,
                    tipo_status: 1
                }

                ApiServices.apiPost('setMovimiento', data).then((response) => {
                    if(response.data.valido == 1)
                    {
                        hideDialog();
                        toast.current.show({ severity: 'success', summary: 'Exito', detail: 'Movimiento editado exitosamente', life: 3000 });
                        getMovimientos();
                    }
                })
            }
            else
            {
                let data = {
                    tipo_id: 0,
                    tipo_desc: movement.tipo_desc,
                    tipo_status: 1
                }

                ApiServices.apiPost('setMovimiento', data).then((response) => {
                    if(response.data.valido == 1)
                    {
                        hideDialog();
                        toast.current.show({ severity: 'success', summary: 'Exito', detail: 'Movimiento agregado exitosamente', life: 3000 });
                        getMovimientos();
                    }
                })
            }
        }
    }

    //Eliminar material
    const deleteMovement= async () =>{
        if(movement.tipo_id)
        {
            let data = {
                tipo_id: movement.tipo_id,
                tipo_desc: movement.tipo_desc,
                tipo_status: 0
            }

            ApiServices.apiPost('setMovimiento', data).then((response) => {
                if(response.data.valido == 1)
                {
                    hideDeleteDialog();
                    toast.current.show({ severity: 'success', summary: 'Exito', detail: 'Movimiento borrado exitosamente', life: 3000 });
                    getMovimientos();
                }
            })
        }
    }

    /*DISEÑO */

    const codeBodyTemplate = (rowData: any) => {
        return (
            <>
                <span className="p-column-title">Code</span>
                {rowData.tipo_id}
            </>
        );
    }

    const nameBodyTemplate = (rowData: any) => {
        return (
            <>
                <span className="p-column-title">Name</span>
                {rowData.tipo_desc}
            </>
        );
    }

    const actionBodyTemplate = (rowData: any) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-text" onClick={() => editMovimiento(rowData)} />
                <Button icon="pi pi-trash" className='p-button-text p-button-danger' onClick={() => confirmDeleteMovimiento(rowData)}/>
            </div>
        );
    }

    const AddFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Guardar" icon="pi pi-save" className="p-button-text" onClick={saveMovement} />
        </>
    );

    const deleteFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDialog} />
            <Button label="Confirmar" icon="pi pi-check" className="p-button-text" onClick={deleteMovement} />
        </>
    );

    const header = (
        <div className="grid">
            <div className="col-12 flex align-items-center justify-content-between">
                <div className='col-12 md:col-8'>
                    <h5 className="m-0">Catálogo de movimientos</h5>
                    <p>Aquí podrás encontrar los movimientos registrados, dar de alta nuevos movimientos o eliminar.</p>
                </div>
                <div className="col-12 md:col-4 flex mt-1 md:mt-0 p-input-icon-left">
                    <span className="p-float-label p-input-icon-left w-100" style={{height:"auto", marginRight:"10px"}}>
                        <i className="pi pi-search" />
                        <InputText id="search" type="search" onInput={(e) => setGlobalFilter(e.currentTarget.value)} />
                        <label htmlFor="search">Buscador general</label>
                    </span>
                    <Button label="Nuevo movimiento" icon="pi pi-plus" iconPos="left" onClick={openNew}/>
                </div>
            </div>
        </div>
    );

    return (
        <section>
            <Toast ref={toast} />
            <DataTable ref={dt} value={movimientos} selection={selectedMovements} onSelectionChange={(e) => setSelectedMovements(e.value)}
                dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Mostrando {first} de {last} de un total de {totalRecords} entradas."
                globalFilter={globalFilter} emptyMessage="No se encuentran entradas." header={header} responsiveLayout="scroll">
                    <Column field="tipo_id" header="ID" sortable body={codeBodyTemplate}></Column>
                    <Column field="tipo_desc" header="Movimiento" sortable body={nameBodyTemplate}></Column>
                    <Column body={actionBodyTemplate} headerStyle={{ minWidth: '1rem' }}></Column>
            </DataTable>

            <Dialog visible={addDialog} style={{ width: '30vw' }} header="Agregar movimientos" modal className="p-fluid" footer={AddFooter} onHide={hideDialog}>
                <div className="field">
                    <label htmlFor="tipo_desc">Tipo de movimiento</label>
                    <InputText
                        id="tipo_desc"
                        value={movement.tipo_desc}
                        onChange={(e) => onInputChange(e, 'tipo_desc')}
                        tooltip="Ingresa tipo movimiento"
                        className={classNames({ 'p-invalid': submitted && !movement.tipo_desc })}
                    />
                    {submitted && !movement.tipo_desc && <small className="p-invalid" style={{color:'red'}}>Tipo de movimiento obligatorio.</small>}
                </div>
            </Dialog>

            <Dialog visible={deleteDialog} style={{ width: '30vw' }} header="Eliminar" modal footer={deleteFooter} onHide={hideDeleteDialog}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {movement && <span>¿Esta seguro que desea eliminar <b>{movement.tipo_desc}</b>?</span>}
                </div>
            </Dialog>
        </section>
    )
}

export default React.memo(CatMovimientos)