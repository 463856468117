import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import App from "../src/App";
import authGuardService from "../src/service/authGuardService";
import configFile from "./configurationFile";
import { Access } from "./pages/otros/AccessDenied";
import { Error } from "./pages/otros/Error";
import { NotFound } from "./pages/otros/NotFound";
import { Olvide } from "./pages/public/ForgotPassword";
import { Landing } from "./pages/public/Landing";
import { Login } from "./pages/public/Login";
import { Registro } from "./pages/public/Register";
import { Olvide2 } from "./pages/public/olvidept2";
import { Olvide3 } from "./pages/public/olvidept3";
import moment from "moment";

const AppWrapper = (props: any) => {
  const [colorScheme, setColorScheme] = useState(configFile.theme);
  const [theme, setTheme] = useState("green");
  const [componentTheme, setComponentTheme] = useState("green");
  const permisos: { label: any }[] = [];

  let location = useLocation();

  moment.locale('es');

  useEffect(() => {
    onColorSchemeChange(colorScheme);
    onComponentThemeChange("green");
  }, []);

  useEffect(() => {
    /* user && MenuService.getMenuPerfilConsola(token, user.id_perfil).then(res => {
      let respuestaDto = EncryptService.dencryptData(res.data.response);
      if (respuestaDto.valido == 0) {
        toast.current.show({ severity: 'error', summary: 'Datos Incorrectos', detail: 'Email y/o Contraseña Incorrectos', life: 4000 });
      } else {
        permisosMenu(respuestaDto.addenda).then(async permiso => {
          authGuardService.sendPermits(permiso);
          setPermissions(permiso)
        })
      }
    }).catch(error => {
      console.log(error)
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'Intentelo de Nuevo mas tarde.', life: 4000 });
    }) */
  }, []);

  const permisosMenu = async (menu: any[]) => {
    menu.forEach((menuItem) => {
      let permiso = { label: menuItem.label };
      if (menuItem.items && menuItem.items.length > 0) {
        permisosMenu(menuItem.items);
      } else {
        permisos.push(permiso);
      }
    });
    return permisos;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const onColorSchemeChange = (scheme: string) => {
    changeStyleSheetUrl("layout-css", "layout-" + scheme + ".css", 1);
    changeStyleSheetUrl("theme-css", "theme-" + scheme + ".css", 1);
    setColorScheme(scheme);
  };

  const changeStyleSheetUrl = (id: any, value: any, from: any) => {
    const element = document.getElementById(id) as HTMLInputElement;
    const urlTokens = (element.getAttribute("href") as String).split("/");

    if (from === 1) {
      urlTokens[urlTokens.length - 1] = value;
    } else if (from === 2) {
      urlTokens[urlTokens.length - 2] = value;
    }

    const newURL = urlTokens.join("/");

    replaceLink(element, newURL);
  };

  const onMenuThemeChange = (theme: string) => {
    const layoutLink = document.getElementById("layout-css");
    const href =
      "assets/layout/css/" + theme + "/layout-" + colorScheme + ".css";

    replaceLink(layoutLink, href);
    setTheme(theme);
  };

  const onComponentThemeChange = (theme: string) => {
    const themeLink = document.getElementById("theme-css");
    const href = "assets/theme/" + theme + "/theme-" + colorScheme + ".css";
    replaceLink(themeLink, href);
    setComponentTheme(theme);
  };

  const replaceLink = (linkElement: any, href: string, callback?: any) => {
    const id = linkElement.getAttribute("id");
    const cloneLinkElement = linkElement.cloneNode(true);
    cloneLinkElement.setAttribute("href", href);
    cloneLinkElement.setAttribute("id", id + "-clone");
    linkElement.parentNode.insertBefore(
      cloneLinkElement,
      linkElement.nextSibling
    );
    cloneLinkElement.addEventListener("load", () => {
      linkElement.remove();
      cloneLinkElement.setAttribute("id", id);

      if (callback) {
        callback();
      }
    });
  };

  const home = () => {
    return (
      <App
        colorScheme={colorScheme}
        onColorSchemeChange={onColorSchemeChange}
        componentTheme={componentTheme}
        onComponentThemeChange={onComponentThemeChange}
        theme={theme}
        onMenuThemeChange={onMenuThemeChange}
      />
    );
  };

  const ValidateHomeLogin = () => {
    if (authGuardService.isLoggednIn()) return home();
    return <Access colorScheme={colorScheme} />;
  };

  const ValidateLanding = () => {
    if (configFile.landing) {
      if (authGuardService.isLoggednIn()) return home();
      return <Landing />;
    } else {
      return ValidateLoggin();
    }
  };

  const ValidateLoggin = () => {
    if (authGuardService.isLoggednIn()) return home();
    return <Login colorScheme={colorScheme} />;
  };

  switch (location.pathname) {
    case "/home":
      return ValidateHomeLogin();
    case "/error":
      return <Error colorScheme={colorScheme} />;
    case "/notfound":
      return <NotFound colorScheme={colorScheme} />;
    case "/access":
      return <Access colorScheme={colorScheme} />;
    case "/login":
      return ValidateLoggin();
    case "/landing":
      return <Landing colorScheme={colorScheme} />;
    case "/registro":
      return <Registro colorScheme={colorScheme} />;
    case "/olvide":
      return <Olvide colorScheme={colorScheme} />;
    case "/olvide-pt2":
      return <Olvide2 colorScheme={colorScheme} />;
    case "/olvide-pt3":
      return <Olvide3 colorScheme={colorScheme} />;
    default:
      return ValidateLanding();
  }
};

export default AppWrapper;
