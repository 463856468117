import React, { useEffect, useState } from 'react';
import { Chart } from 'primereact/chart';
import ApiServices from '../../service/apiServices';
import EncryptService from '../../service/encrypt.service';

const EmptyPage = () => {

    // const [materiales, setMateriales] = useState<any>([]);
    const [materialesLabels, setMaterialesLabels] = useState<any>();
    const [materialesCount, setMaterialesCount] = useState<any>();

    // const [pagos, setPagos] = useState<any>([]);
    const [pagosLabels, setPagosLabels] = useState<any>();
    const [pagosCount, setPagosCount] = useState<any>();

    // const [solicitudes, setSolicitudes] = useState<any>([]);
    const [solicitudesLabels, setSolicitudesLabels] = useState<any>();
    const [solicitudesCount, setSolicitudesCount] = useState<any>();



    const getGraficaData = async () => {
        let datos: any = {
            "code": 201,
        }

        datos = await EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.processRequestAdd(datos)
        .then(async (res) => {
            // setMateriales(res.data.materiales)
            // setPagos(res.data.pagos)
            // setSolicitudes(res.data.solicitudes)
            await orderPagoData(res.data.materiales, res.data.pagos, res.data.solicitudes);
        })
        .catch((error) => {
            console.log(error)
        });
    }


    const orderPagoData = (materiales: any[], pagos: any[], solicitudes:any[]) => {
        let matlabels = [];
        let paglabels = [];
        let sollabels = [];

        let matdata = [];
        let pagdata = [];
        let soldata = [];
        for(let mats of materiales){
            matlabels.push(mats.mat_desc[0].toString().toUpperCase()+mats.mat_desc.toString().substring(1))
            matdata.push(mats.volumen)
            setMaterialesLabels(matlabels)
            setMaterialesCount(matdata)
        }
        for(let pag of pagos){
            paglabels.push(pag.pago_desc)
            pagdata.push(pag.suma)
            setPagosLabels(paglabels)
            setPagosCount(pagdata)
        }
        for(let sol of solicitudes){
            sollabels.push(sol.sol_desc)
            soldata.push(sol.suma)
            setSolicitudesLabels(sollabels)
            setSolicitudesCount(soldata)
        }
    }


    /** GRAFICA DE MATERIALES */
    //66BB6A
    const chartRec = {
        labels: materialesLabels,
        datasets: [{
            data: materialesCount,
            label: 'Materiales',
            backgroundColor: [
                '#0C6F3C',
                '#0C6F3C',
                'rgba(54, 162, 235, 0.2)',
                'rgba(153, 102, 255, 0.2)'
              ],
              hoverBackgroundColor: [
                '#16A664',
                '#16A664'
              ],
              borderWidth: 1
        }]
    };

    const lightOptionsRec = {
        maintainAspectRatio: false,
        aspectRatio: .6,
        plugins: {
            legend: {
                display: false,
                labels: {
                    color: '#495057'
                }
            },
        },
        scales: {
            x: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            },
            y: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            }
        }
    };


    /** GRAFICA COTIZACIONES */
    const chartData = {
        labels: solicitudesLabels,
        datasets: [
            {
                data: solicitudesCount,
                backgroundColor: [
                    "#42A5F5",
                    "#66BB6A",
                    "#FFA726",
                    "#FB153A",
                    "#FBF015"
                ],
                hoverBackgroundColor: [
                    "#64B5F6",
                    "#81C784",
                    "#FFB74D",
                    "#FF5733",
                    "#F3E922"
                ]
            }
        ]
    };

    /** GRAFICA PAGOS */
    const chartDataPagos = {
        labels: pagosLabels,
        datasets: [
            {
                data: pagosCount,
                backgroundColor: [
                    "#42A5F5",
                    "#66BB6A",
                    "#FFA726",
                    "#D55B9B"
                ],
                hoverBackgroundColor: [
                    "#64B5F6",
                    "#81C784",
                    "#FFB74D",
                    "#E078AF"
                ]
            }
        ]
    };

    const lightOptions = {
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        }
    };

    useEffect(() => {
        getGraficaData();
    }, []);

    return (
        <section>
            <div className='grid'>
                <div className='p-col-12'>
                    <h1>Bienvenido</h1>
                    <p>Aquí podrás encontrar todo lo relacionado con los pagos, materiales y cotizaciones en curso y tu historial</p>
                </div>
            </div>
            <div className='grid mt-2'>
                <div className='col-12 md:col-4'>
                    <h3>Pagos</h3>
                </div>
                <div className='col-12 md:col-4'>
                    <h3>Materiales</h3>
                </div>
                <div className='col-12 md:col-4'>
                    <h3>Cotizaciones</h3>
                </div>

                <div className='col-12 md:col-4'>
                    <Chart type="pie" data={chartDataPagos} options={lightOptions}/>
                </div>
                <div className='col-12 md:col-4'>
                    <Chart type="bar" data={chartRec} options={lightOptionsRec}/>
                </div>
                <div className='col-12 md:col-4'>
                    <Chart type="pie" data={chartData} options={lightOptions}/>
                </div>
            </div>
        </section>
    );

}

export default React.memo(EmptyPage);