import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useRef, useState } from 'react';
// import ProductService from '../../serviceExample/ProductService';
import { useLocation, useNavigate } from 'react-router-dom';
import { Steps } from 'primereact/steps';
import { Toast } from 'primereact/toast';
import ProductService from '../../../serviceExample/ProductService';
import { Dialog } from 'primereact/dialog';
import ApiServices
    from '../../../service/apiServices';
import EncryptService from '../../../service/encrypt.service';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
const MovimientoDetalle = () => {
    const location = useLocation();

    const previousData = location.state.data

    const [detailData, setDetailData] = useState<any>()
    const [materialList, setMaterialList] = useState<any>([])



    const getMovementDetail = () => {
        let datos: any = {
            "code": 302,
            "sol_id": previousData.sol_id
        }

        datos = EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.processRequestAdd(datos)
            .then(async (res) => {
                setDetailData(res.data.solicitud)
                setMaterialList(res.data.materiales)
                /* if (res.data.solicitud.sol_status === 3) {
                    navigate('/movimiento/pago', { state: { previous: previousData, data: detailData, materials: materialList, total: total, iva: iva } })
                } */
            })
            .catch((error) => {
                /* Toasty Error */
            });
    }

    useEffect(() => {
        getMovementDetail()
    }, [previousData])

    /* OLD */

    const [activeIndex, setActiveIndex] = useState(0);
    const items = [
        {
            label: 'Datos generales'
        },
        {
            label: 'Pago'
        },
        {
            label: 'Activado'
        },
    ];

    const navigate = useNavigate();


    const formatCurrency = (value: any) => {
        if (value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        } else {
            return 0
        }
    }

    const priceBodyTemplate = (rowData: any) => {
        return (
            <>
                <span className="p-column-title">Precio</span>
                {formatCurrency(rowData.mat_costo)}
            </>
        );
    }

    const formatVolume = (value: any) => {
        if (value) {
            return value.toLocaleString('en-US'); // Usar 'es-ES' para formato español
        }
        else {
            return 0
        }
    }

    const volumeBodyTemplate = (rowData: any) => {
        return (
            <>
                <span className="p-column-title">Volumen</span>
                {`${formatVolume(rowData.mat_volumen)} m³`}
            </>
        );
    }

    const [total, setTotal] = useState(0)
    const [iva, setIva] = useState(0)

    const TotalMaterial = () => {
        let total = 0;

        materialList && materialList.map((singleMaterial: any) => {
            total = total + singleMaterial.mat_costo
        })

        setTotal(total)
        setIva(total * 0.16)

        return formatCurrency(total);
    };

    const footerGroup = (
        <ColumnGroup>
            <Row>
                <Column footer="Total:" colSpan={3} footerStyle={{ textAlign: 'right' }} />
                <Column footer={TotalMaterial} />
            </Row>
        </ColumnGroup>
    );

    return (
        <section>
            <div className="grid">
                <div className='col-12 md:col-8'>
                    <h5 className="m-0">Detalle de solicitud</h5>
                    {
                        detailData && detailData.sol_status < 2 &&
                        <p>Espera a que Concretos Reciclados envié la cotización para proceder al pago.</p>
                    }
                </div>
                <div className='col-12 md:col-4'>
                    <Steps model={items} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly={true} />
                </div>
                <div className='col-12 md:col-4'>
                    <small className='font-bold'>Tipo de movimiento</small>
                    <p>{previousData ? previousData.tipo_desc : ''}</p>
                </div>
                <div className='col-12 md:col-8'>
                    <small className='font-bold'>Sucursal</small>
                    <p>{detailData ? detailData.suc_nom : ''}</p>
                </div>
                <div className='col-12 md:col-12'>
                    <p>Ubicación</p>
                </div>
                <div className='col-12 md:col-3'>
                    <small>Calle</small>
                    <p>{detailData ? detailData.sol_calle : ''}</p>
                </div>
                <div className='col-12 md:col-9'>
                    <small>Número</small>
                    <p>{detailData ? detailData.sol_num : ''}</p>
                </div>
                {/* <div className='col-12 md:col-3'>
                    <p>Avenida</p>
                    <small>{detailData ? detailData.sol_num : ''}</small>
                </div> */}
                <div className='col-12 md:col-3'>
                    <small>C.P.</small>
                    <p>{detailData ? detailData.cp_cp : ''}</p>
                </div>
                <div className='col-12 md:col-6'>
                    <small>Colonia</small>
                    <p>{detailData ? detailData.cp_col : ''}</p>
                </div>
                <div className='col-12 md:col-12'>
                    {
                        materialList &&
                        <DataTable
                            value={materialList}
                            dataKey="mat_id"
                            rows={5}
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando {first} de {last} de un total de {totalRecords} entradas"
                            emptyMessage="No se encuentran entradas."
                            footerColumnGroup={detailData && detailData.sol_status > 1 && footerGroup}
                        >
                            <Column field="mat_id" header="ID"></Column>
                            <Column field="mat_desc" header="Tipo material"></Column>
                            <Column field="mat_volumen" header="Volumen" body={volumeBodyTemplate}></Column>
                            {detailData && detailData.sol_status > 1 &&
                                <Column field="mat_costo" header="Costo" body={priceBodyTemplate}></Column>
                            }
                        </DataTable>
                    }
                </div>
            </div>
            <div className='flex justify-content-end pb-3'>
                {detailData && detailData.sol_status > 1 && detailData.sol_status < 5 && detailData.sol_status !== 4 &&
                    <div>
                        <Button label={`${detailData.sol_status === 2 ? 'Pagar' : ''} ${detailData.sol_status === 3 ? 'Ver Pago' : ''}`} onClick={() => navigate('/movimiento/pago', { state: { previous: previousData, data: detailData, materials: materialList, total: total, iva: iva } })} 
                         />
                    </div>
                }
                {detailData && detailData.sol_status == 5 &&
                    <div>
                        <Button label={`${detailData.sol_status === 5 ? 'Ver resumen' : ''}`} onClick={() => navigate('/movimiento/resumen', { state: { previous: previousData, data: detailData, materials: materialList, total: total, iva: iva } })} />
                    </div>
                }
            </div>
        </section>
    );
}

export default React.memo(MovimientoDetalle);