import { Button } from "primereact/button"
import { InputText } from "primereact/inputtext"
import { Password } from "primereact/password";
import { useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import EncryptService from "../../service/encrypt.service";
import ApiServices from "../../service/apiServices";
import { Toast } from "primereact/toast";

export const Olvide = (props: any) => {

	const navigate = useNavigate();
	const [email, setEmail] = useState<string>("");
	const [validEmail, setValidEmail] = useState<any>(false);
	const toast = useRef<any>();

	const validateEmail = (email: any) => {
		// onInputChange(email, name)
		const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		setValidEmail(re.test(email.target.value))
	}

	const sendCode = async () => {
		if(email.trim()){
			let datos: any =  {
				"code":102,
				"usu_email":email
			}
			console.log(datos)
			datos = EncryptService.encryptData(JSON.stringify(datos));
			ApiServices.processRequestAdd(datos)
			.then(async (res) => {
				if(res.data.valido === 1){
					navigate('olvide-pt2', { state: { previous: email} })
				} else {
					toast.current.show({
						severity: "error",
						summary: "Campo incompleto",
						detail: res.data.mensaje,
						life: 4000,
					});
				}
			})
		} else {
			toast.current.show({
				severity: "error",
				summary: "Campo incompleto",
				detail: "Ingresa tu correo",
				life: 4000,
			});
		}
	}

	return (
		<section>
      		<Toast ref={toast} />
			<div className='fondo'>
				<div className='grid' style={{ height: '100%', marginTop: 0 }}>
					<div className='col-12 md:col-6 p-0'>
						<img src="assets/img/login-fondo.svg" className='w-100' />
					</div>
					<div className='col-12 md:col-6 flex justify-content-center align-items-center'>
						{/* CASO 1 INGRESAR CONTRASEÑA */}
						<div className='login'>
							<h1>Olvidé mi contraseña</h1>
							<p>Ingresa tu correo electrónico para enviar un código de seguridad.</p>
							<div className='grid p-5'>
								<div className='col-12 md:col-12'>
									<span className="p-float-label">
										<InputText className='p-inputtext-lg' id="username"  onChange={(e) => {setEmail(e.target.value); validateEmail(e)}} />
										<label htmlFor="username">Correo Electrónico</label>
									</span>
									{!validEmail && email && <small className="p-invalid">El correo no es válido.</small>}
								</div>
							</div>
							<div className='flex justify-content-between pb-3'>
                                <div>
									<Button label="Atrás" icon="pi pi-chevron-left" onClick={() => { navigate('login')}}/>
                                </div>
                                <div>
                                    <Button label="Enviar" disabled={!validEmail} onClick={sendCode}/>
                                </div>
                            </div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}