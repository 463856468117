import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Toast } from "primereact/toast";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { InputMask } from "primereact/inputmask";
import EncryptService from "../../service/encrypt.service";
import ApiServices from "../../service/apiServices";

export const Registro = (props: any) => {
  const toast = useRef<any>();
  const navigate = useNavigate();
  const [validEmail, setValidEmail] = useState<any>(false);

  const [userData, setUserData] = useState({
    name: { value: "", valid: true },
    lastName: { value: "", valid: true },
    secondLastName: { value: "", valid: true },
    companyName: { value: "", valid: true },
    phone: { value: "", valid: true },
    email: { value: "", valid: true },
    password: { value: "", valid: true },
    confirmPassword: { value: "", valid: true },
  });

  // const onInputChange = (e: any, name: string) => {
  //   const val = (e.target && e.target.value) || '';
  //   let _product = { ...userData };
  //   _product[`${name}`] = val;
  //   setValidEmail(_product);
  // }

  const validateEmail = (email: any) => {
    // onInputChange(email, name)
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    setValidEmail(re.test(email.target.value))
  }

  const handleGeneralData = (value: any, jsonId: any) => {
    const temporalData = JSON.parse(JSON.stringify(userData));
    temporalData[jsonId].value = value;
    setUserData(temporalData);
  };

  const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

  const handleRegister = async () => {
    if(userData.name.value.trim() && userData.email.value.trim() && userData.companyName.value.trim() && userData.phone.value.trim()){
      if(userData.password.value.trim().length > 6 && userData.confirmPassword.value.trim().length > 6){
        if(validEmail == true){
          let datos;
          datos = {
            code: 105,
            prf_id: 1,
            usu_nom: userData.name.value,
            usu_pat: userData.lastName.value,
            usu_mat: userData.secondLastName.value,
            usu_empresa: userData.companyName.value,
            usu_tel: userData.phone.value,
            usu_email: userData.email.value,
            usu_psw: userData.password.value,
          };
          datos = EncryptService.encryptData(JSON.stringify(datos));
          ApiServices.processRequestAdd(datos)
            .then(async (res) => {
              if (res.data.valido === 0) {
                toast.current.show({
                  severity: "error",
                  summary: "Datos Incorrectos",
                  detail: "Email y/o Contraseña Incorrectos",
                  life: 4000,
                });
              } else {
                setUserData({
                  name: { value: "", valid: true },
                  lastName: { value: "", valid: true },
                  secondLastName: { value: "", valid: true },
                  companyName: { value: "", valid: true },
                  phone: { value: "", valid: true },
                  email: { value: "", valid: true },
                  password: { value: "", valid: true },
                  confirmPassword: { value: "", valid: true },
                });
                toast.current.show({
                  severity: "success",
                  summary: "Registro Exitoso",
                  life: 4000,
                });
                await delay(4000);
                navigate("/login");
              }
            })
            .catch((error) => {
              toast.current.show({
                severity: "error",
                summary: "Error",
                detail: "Intentelo de Nuevo mas tarde.",
                life: 4000,
              });
            });
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "El correo no es valido.",
            life: 4000,
          });
        }
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "La contraseña no es correcta.",
          life: 4000,
        });
      }
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Todos los campos son obligatorios",
        life: 4000,
      });
    }
  };

  function validatePasswords() {
    if (userData.password.value && userData.confirmPassword.value) {
      return !(userData.password.value === userData.confirmPassword.value);
    }
  }

  return (
    <section>
      <Toast ref={toast} />
      <div className="fondo">
        <div className="grid" style={{ height: "100%", marginTop: 0 }}>
          <div className="col-12 md:col-6 p-0">
            <img src="assets/img/login-fondo.svg" className="w-100" />
          </div>
          <div className="col-12 md:col-6 flex justify-content-center align-items-center">
            <div className="login">
              <h1>Registro</h1>
              <p>Ingresa los siguientes datos para realizar tu registro.</p>
              <div className="grid p-5">
                <div className="col-12 md:col-12">
                  <span className="p-float-label">
                    <InputText
                      value={userData.name.value}
                      className="p-inputtext-lg"
                      id="name"
                      onChange={(e) => {
                        handleGeneralData(e.target.value, e.target.id);
                      }}
                    />
                    <label htmlFor="name">Nombre</label>
                  </span>
                </div>
                <div className="col-12 md:col-6">
                  <span className="p-float-label">
                    <InputText
                      value={userData.lastName.value}
                      className="p-inputtext-lg"
                      id="lastName"
                      onChange={(e) => {
                        handleGeneralData(e.target.value, e.target.id);
                      }}
                    />
                    <label htmlFor="lastName">Apellido paterno</label>
                  </span>
                </div>
                <div className="col-12 md:col-6">
                  <span className="p-float-label">
                    <InputText
                      value={userData.secondLastName.value}
                      className="p-inputtext-lg"
                      id="secondLastName"
                      onChange={(e) => {
                        handleGeneralData(e.target.value, e.target.id);
                      }}
                    />
                    <label htmlFor="secondLastName">Apellido materno</label>
                  </span>
                </div>

                <div className="col-12 md:col-12">
                  <span className="p-float-label">
                    <InputText
                      value={userData.companyName.value}
                      className="p-inputtext-lg"
                      id="companyName"
                      onChange={(e) => {
                        handleGeneralData(e.target.value, e.target.id);
                      }}
                    />
                    <label htmlFor="companyName">Nombre de la empresa</label>
                  </span>
                </div>
                <div className="col-12 md:col-6">
                  <span className="p-float-label">
                    <InputMask
                      id="phone"
                      value={userData.phone.value}
                      onChange={(e) => {
                        handleGeneralData(e.target.value, e.target.id);
                      }}
                      className={`p-inputtext-lg ${!userData.phone.valid && "p-invalid"
                        }`}
                      mask="(999) 999-9999"
                      placeholder="(999) 999-9999"
                    ></InputMask>
                    <label htmlFor="phone">Telefono</label>
                  </span>
                </div>
                <div className="col-12 md:col-6">
                  <span className="p-float-label">
                    <InputText
                      value={userData.email.value}
                      className="p-inputtext-lg"
                      id="email"
                      onChange={(e) => {
                        handleGeneralData(e.target.value, e.target.id); validateEmail(e)
                      }}
                    />
                    <label htmlFor="email">Correo electrónico</label>
                  </span>
                  {!validEmail && userData.email.value && <small className="p-invalid">El correo no es valido.</small>}
                </div>

                <div className="col-12 md:col-12">
                  <p>
                    Ingresa una contraseña que tenga por lo menos 8 caracteres.
                  </p>
                </div>
                <div className="col-12 md:col-6">
                  <span className="p-float-label">
                    <Password
                      id="password"
                      value={userData.password.value}
                      onChange={(e) => {
                        handleGeneralData(e.target.value, "password");
                      }}
                      className={`p-inputtext-lg ${validatePasswords() && "p-invalid"
                        }`}
                      feedback={false}
                      toggleMask
                      aria-describedby="password-help"
                      style={{ width: "100%" }}
                    />
                    {validatePasswords() && (
                      <small id="password-help" style={{ color: "red" }}>
                        Las contraseñas no coinciden.
                      </small>
                    )}
                    <label htmlFor="password">Contraseña</label>
                  </span>
                </div>
                <div className="col-12 md:col-6">
                  <span className="p-float-label">
                    <Password
                      id="confirmPassword"
                      value={userData.confirmPassword.value}
                      onChange={(e) => {
                        handleGeneralData(e.target.value, "confirmPassword");
                      }}
                      className={`p-inputtext-lg ${validatePasswords() && "p-invalid"
                        }`}
                      feedback={false}
                      toggleMask
                      aria-describedby="confirmPassword-help"
                      style={{ width: "100%" }}
                    />
                    {validatePasswords() && (
                      <small id="confirmPassword-help" style={{ color: "red" }}>
                        Las contraseñas no coinciden.
                      </small>
                    )}
                    <label htmlFor="confirmPassword">
                      Confirmar Contraseña
                    </label>
                  </span>
                </div>
              </div>
              <div className="flex justify-content-between pb-3">
                <div></div>
                <div>
                  <Button label="Registrarme" disabled={!validEmail} onClick={handleRegister} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
