export const Norm = (props: { data: any[] }) => {
  return (
    <div id='norm' className='text-center'>
      <div className='container norm'>
        <div className='section-title'>
          <h1>CONOCE LA NUEVA NORMA</h1>
          <h3>
            NACDMX-007-RNAT-2019
          </h3>
        </div>
      </div>
      <div className="row backgroundimage" style={{display: 'flex', alignItems: 'center'}}>
        <div className="col-xs-12 col-md-5">
          {" "}
          <img src="images/landing/CR-12.png" className="img-responsive" loading="lazy" />
          {" "}
        </div>
        <div className='col-xs-12 col-md-7'>
          <div className="container norm">
            <div className='col-md-8 col-md-12 section-title inner margin-text'>
              <h3 className="title2">Clasificación y especificaciones de manejo integral para los residuos de la construcción y demolición</h3>
            </div>
            <div className='col-md-8 col-md-12 section-title margin-text'>
              <p className="subtitle2">La norma establece que los residuos de la construcción y demolición deberán seleccionarse en el origen y entregarse en los sitios autorizados por esta Secretaría o por la autoridad ambiental competente, de forma separada para facilitar su valorización.</p>
            </div>
            <div className='col-md-8 col-md-12'>
              <div className="flex justify-content-end">
                <a href="https://sedema.cdmx.gob.mx/storage/app/uploads/public/64f/a0d/a70/64fa0da703c50093600864.pdf" className="text-right more" target="_blank">Saber más...</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
