import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react';
import ProductService from '../../../serviceExample/ProductService';
import { Message } from 'primereact/message';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Checkbox } from 'primereact/checkbox';
import { useLocation, useNavigate } from 'react-router-dom';
import ApiServices from '../../../service/apiServices';
import EncryptService from '../../../service/encrypt.service';
import configuration from '../../../configurationFile';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';

const ViewPagos = () => {
    const location = useLocation();

    const previousData = location.state.data

    console.log("DETALLE COTIZACIÓN",previousData)

    let emptyData = {
        mov_id: 0,
        mov_placa: '',
        sol_id: 0
    }

    const [products, setProducts] = useState<any>(null);
    const [selectedProducts, setSelectedProducts] = useState<any>(null);
    const dt = useRef<any>(null);
    const toast = useRef<any>(null);
    const [checked, setChecked] = useState<boolean>(false);
    const navigate = useNavigate();

    /* useEffect(() => {
        const productService = new ProductService();
        productService.getProducts().then(data => setProducts(data));
    }, []); */
    const [ventanillaSelected, setVentanillaSelected] = useState<any>('');
    const [pagoSelected, setPagoSelected] = useState<any>('');
    const [materialesList, setMaterialesList] = useState([]);
    const [movimientosList , setMovimientosList] = useState([]);
    const [addDialog, setAddDialog] = useState<boolean>(false);
    const [placa, setPlaca] = useState<any>(emptyData)
    const [submitted, setSubmitted] = useState<boolean>(false);

    const getMovementDetail = async () => {
        let datos: any = {
            "code": 302,
            "tipo": 5,
            "sol_id": previousData.sol_id
        }

        datos = await EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.processRequestAdd(datos)
            .then(async (res) => {
                console.log("DATA DE SOLICITUD SELECCIONADA", res.data)
                setVentanillaSelected(res.data.solicitud)
                setMaterialesList(res.data.materiales)
                setMovimientosList(res.data.movimientos)
                setPagoSelected(res.data.pago[0])
            })
            .catch((error) => {
                /* Toasty Error */
            });
    }

    useEffect(() => {
        getMovementDetail()
    }, [previousData])

    //Abre el modal para editar la placa
    const editPlaca = (placa: any) =>
    {
        setPlaca({...placa})
        console.log("Placa: ", placa)
        setAddDialog(true);
    }

    //Oculta el modal para agregar o editar el catalogo seleccionado
    const hideDialog = () => {
        setSubmitted(false);
        setAddDialog(false);
    }

    //Funcion para editar los campos
    const onInputChange = (e: any, name: string) => {
        const val = (e.target && e.target.value) || '';
        let _product = { ...placa };
        _product[`${name}`] = val;
        setPlaca(_product);
    }

    const codeBodyTemplate = (rowData: any) => {
        return (
            <>
                <span className="p-column-title">Tipo material</span>
                {rowData.mat_desc}
            </>
        );
    }

    const codeBodyTemplateMov = (rowData: any) => {
        return (
            <>
                <span className="p-column-title">Tipo material</span>
                {rowData.mov_id}
            </>
        );
    }

    const nameBodyTemplate = (rowData: any) => {
        return (
            <>
                <span className="p-column-title">Volumen</span>
                {rowData.mat_volumen}m³
            </>
        );
    }

    const nameBodyTemplateMov = (rowData: any) => {
        return (
            <>
            <div className='flex align-items-center'>
                {rowData.mov_placa}
                <Button icon="pi pi-pencil" className="p-button-text" onClick={() => editPlaca(rowData)}/>
            </div>
            </>
        );
    }


    const priceBodyTemplateMov = (rowData: any) => {
        return (
            <>
                <span className="p-column-title">Costo</span>
                {rowData.mov_conductor}
            </>
        );
    }

    const VolumenBodyTemplateMov = (rowData: any) => {
        return (
            <>
                <span className="p-column-title">Volumen</span>
                {rowData.mov_volumen}m³
            </>
        );
    }

    const CheckEntregado = (rowData: any) => {
        return (
            <>
                <span className="p-column-title">Entregado</span>
                <div className="p-field-checkbox text-center">
                    <Checkbox inputId="binary" checked={checked} onChange={(e: any) => setChecked(e.checked)} />
                </div>
            </>
        );
    }

    const TotalVolumen = () => {
        let total = 0;

        materialesList && materialesList.map((singleMaterial: any) => {
            total = total + singleMaterial.mat_volumen
        })

        return total + "m³";
    };

    const TotalVolumen2 = () => {
        let total = 0;

        movimientosList && movimientosList.map((singleMaterial: any) => {
            total = total + singleMaterial.mov_volumen
        })

        return total + "m³";
    };

    const footerTabla1 = (
        <ColumnGroup>
            <Row>
                <Column footer="Precio total:" />
                <Column footer={TotalVolumen} />
            </Row>
        </ColumnGroup>
    );
    const footerTabla2 = (
        <ColumnGroup>
            <Row>
                <Column footer="Precio total:" colSpan={3} />
                <Column footer={TotalVolumen2} />
                {/* <Column footer="Restan 2" /> */}
            </Row>
        </ColumnGroup>
    );

    const [file, setFile] = useState<any>();
    const refFile: any = useRef()
    const handleFileClick = (e: any) => {
        refFile.current.click()
    }

    const readUploadFile = (e: any) => {
        e.preventDefault();
        if (e.target.files) {
            console.log(e.target.files[0])
            setFile(e.target.files[0]);
        }
    }

	const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

    const updatePayment = async () => {
        let data: any = {
            "sol_id": previousData.sol_id,
            "pago_id": pagoSelected.pago_id,
            "pago_status": 2,
            "pago_comprobante": "media/comprobante/",
            "pago_nota": pagoSelected.pago_nota,
            "sol_status":5
        }

        ApiServices.apiPostFile('updatePago', file, data).then(async res => {
            if (res.data.valido === 1) {
                toast.current.show({
                    severity: "success",
                    summary: "Éxito",
                    detail: "Comprobante guardado con éxito.",
                    life: 4000,
                });
                await delay(2500);
                navigate('/administrador/ventanilla')
                // console.log('Toasty pago actualizado con éxito')
                // setVisible(true)
            }
            console.log(res)
        }).catch(error => {
            console.log(error)
        })
    }

    const savePlaca = async () => {
        setSubmitted(true)

        if(placa.mov_placa)
        {
            let data = {
                mov_id: placa.mov_id,
                mov_placa: placa.mov_placa,
                sol_id: placa.sol_id
            }

            ApiServices.apiPost('setPlacaMov', data).then((response) => {
                if(response.data.valido == 1)
                {
                    hideDialog();
                    toast.current.show({ severity: 'success', summary: 'Exito', detail: 'Placa editada exitosamente', life: 3000 });
                    getMovementDetail();
                }
            })
        }
    }

    const DialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Guardar" icon="pi pi-save" className="p-button-text" onClick={savePlaca} />
        </>
    );


    return (
        <section>
            <Toast ref={toast} />

            <div className='grid'>
                <div className='col-12 md:col-12 px-5'>
                    <div className='grid'>
                        <div className='col-12'>
                            <div className='flex justify-content-between'>
                                <div>

                                </div>
                                <div className='flex'>
                                    <div className="col-12">
                                        {pagoSelected && pagoSelected.pago_nota === "pagado" ? <Message className='w-100' severity="success" text="PAGADO" /> : <Message className='w-100' severity="error" text="No se ha recibido el pago" />}
                                        
                                    </div>
                                </div>
                            </div>
                            <div className='flex justify-content-between'>
                                <div>
                                    <h5>Detalle de solicitud</h5>
                                    <p>Registro de movimiento realizado</p>
                                </div>
                                <div className='flex'>
                                    <div className='pr-4'>
                                        <p>Folio <span className='font-bold'>234567</span></p>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>

                    <div className='grid'>
                        <div className='col-12 md:col-2'>
                            <div className='text'>
                                <small className='font-bold'>Tipo de movimiento</small>
                                <p>{ventanillaSelected  ? ventanillaSelected.tipo_desc : ' '}</p>
                                {/* <p>Compra</p> */}
                            </div>
                        </div>
                        <div className='col-12 md:col-1'>
                            <div className='text'>
                                <small className='font-bold'>Tipo de pago</small>
                                <p>{pagoSelected  && pagoSelected.pago_forma == 1 ? 'Tarjeta' : pagoSelected.pago_forma == 2 ? 'Transferencia' : 
                                    pagoSelected.pago_forma == 3 ? 'Deposito' : pagoSelected.pago_forma == 4 ? 'Ventanilla' :' '}</p>
                                {/* <p>En linea</p> */}
                            </div>
                        </div>
                        <div className='col-12 md:col-2'>
                            <div className='text'>
                                <small className='font-bold'>Línea de pago</small>
                                {/* <p>{ventanillaSelected  ? ventanillaSelected : ' '}</p> */}
                                <p>Ventanilla</p>
                            </div>
                        </div>
                        <div className='col-12 md:col-3'>
                            <div className='text'>
                                <small className='font-bold'>Sucursal</small>
                                <p>{ventanillaSelected  ? ventanillaSelected.suc_nom : ' '}</p>
                                {/* <p>Arboles...</p> */}
                            </div>
                        </div>
                        <div className='col-12 md:col-4'>
                            <div className='text'>
                                <small className='font-bold'>Ubicación</small>
                                {/* <p>{ventanillaSelected  ? ventanillaSelected : ' '}</p> */}
                                <p>{ventanillaSelected ? ventanillaSelected.sol_calle + ', ' + ventanillaSelected.sol_num + ', '+ ventanillaSelected.cp_cp + ', '+ ventanillaSelected.cp_id
                                + ', ' + ventanillaSelected.cp_edo : ''}</p>
                                {/* <p>Priv. Prta 3 Manzana 001, Campo Militar 1 A, 53538 Naucalpan de Juárez, Méx.</p> */}
                            </div>
                        </div>
                    </div>


                </div>
                <div className='col-12 md:col-6'>
                    <h5>Material comprado</h5>
                    <DataTable ref={dt} value={materialesList} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)} footerColumnGroup={footerTabla1}
                        dataKey="id" className="datatable-responsive" scrollable scrollHeight="400px"
                        currentPageReportTemplate="Mostrando {first} de {last} de un total de {totalRecords} entradas"
                        emptyMessage="No se encuentran entradas." responsiveLayout="scroll">
                        <Column field="mat_desc" header="Tipo material" sortable body={codeBodyTemplate} ></Column>
                        <Column field="mat_volumen" header="Volumen" sortable body={nameBodyTemplate} ></Column>
                    </DataTable>
                </div>
                <div className='col-12 md:col-6'>
                    <br />
                    <DataTable ref={dt} value={movimientosList} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)} footerColumnGroup={footerTabla2}
                        dataKey="id" paginator rows={5} className="datatable-responsive"
                        currentPageReportTemplate="Mostrando {first} de {last} de un total de {totalRecords} entradas"
                        emptyMessage="No se encuentran entradas." responsiveLayout="scroll">
                        <Column field="code" header="ID" sortable body={codeBodyTemplateMov} ></Column>
                        <Column field="name" header="Placa" sortable body={nameBodyTemplateMov} ></Column>
                        <Column field="price" header="Conductor" body={priceBodyTemplateMov} sortable ></Column>
                        <Column field="price" header="Volumen" body={VolumenBodyTemplateMov} ></Column>
                        {/* <Column field="price" header="Precio" body={priceBodyTemplate} ></Column> */}
                        {/* <Column field="price" header="Entregado" body={CheckEntregado} ></Column> */}
                    </DataTable>

                    <div className='flex justify-content-end pb-3'>
                        {pagoSelected && pagoSelected.pago_nota === "pagado" && !file ? 
                        <div className='p-2'>
                            <Button label="Guardar" className='p-button-lg' onClick={() => navigate('/administrador/ventanilla')} />
                        </div> : file ?
                        <div className='p-2'>
                            <Button label="Registrar pago" className='p-button-lg' onClick={updatePayment} />
                        </div> : ''
                        }
                    </div>

                </div>
                <div className='col-12 md:col-6'>
                    <h5>Comprobante de pago</h5>
                    {pagoSelected.pago_comprobante ? <a href={configuration.urlmedia + pagoSelected.pago_comprobante} target='_blank'>Acceder a comprobante guardado</a> : ''}
                    {!file ? 
                        <div className='pt-2'>
                            <input
                                ref={refFile}
                                type="file"
                                hidden
                                onChange={readUploadFile}
                            />
                            <p className='document' onClick={handleFileClick}>
                                <i className='pi pi-cloud-upload' style={{ 'fontSize': '3em' }}></i>
                                    Selecciona para subir el comprobante de pago</p>
                        </div> :
                        <div className='pt-2 document-ok'>
                            <p onClick={handleFileClick}><i className='pi pi-file' style={{ 'fontSize': '3em' }}></i></p>
                            <a className='' href="">{file.name}</a>
                            {/* Se agrega el 2do con texto del nombre del archivo con el peso, puede seleccionar para hacer cambio de documento */}
                        </div>
                    }
                    

                    {/* <div className='py-5'>
                        <Button label="Registrar pago" className='p-button-lg' />
                    </div> */}
                </div>

            </div>

            <Dialog visible={addDialog} style={{ width: '30vw' }} header="Editar información" modal 
                className="p-fluid" footer={DialogFooter} onHide={hideDialog}>
                    <div className="field">
                    <label htmlFor="mov_placa">Placa</label>
                    <InputText
                        id="mov_placa"
                        value={placa.mov_placa}
                        onChange={(e) => onInputChange(e, 'mov_placa')}
                        tooltip="Ingresa el num de placa"
                        className={classNames({ 'p-invalid': submitted && !placa.mov_placa })}
                        maxLength={10}
                    />
                    {submitted && !placa.mov_placa && <small className="p-invalid" style={{color:'red'}}>
                        Placa obligatoria.
                    </small>}
                </div>
                </Dialog>
            


        </section>
    );
}

export default React.memo(ViewPagos);