import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Panel } from 'primereact/panel';
import { Steps } from 'primereact/steps';
import { VirtualScroller, VirtualScrollerTemplateOptions } from 'primereact/virtualscroller';
import React, { useEffect, useRef, useState } from 'react';
// import ProductService from '../../serviceExample/ProductService';
import { useLocation, useNavigate } from 'react-router-dom';
import { classNames } from 'primereact/utils';
import { RadioButton } from 'primereact/radiobutton';
import { Dialog } from 'primereact/dialog';
import ApiServices from '../../../service/apiServices';
import authGuardService from '../../../service/authGuardService';
import { Calendar } from 'primereact/calendar';
import EncryptService from '../../../service/encrypt.service';
import { Checkbox } from 'primereact/checkbox';


const MovimientoPagoAcreditando = () => {
    const navigate = useNavigate();
    const { usu_id } = authGuardService.getUser()

    const [cardNumber, setCardNumber] = useState<any>()
    const [cardExpirationDate, setCardExpirationDate] = useState<any>()
    const [cardCvv, setCardCvv] = useState<any>()

    const [cardsList, setCardsList] = useState([])
    const [selectedCard, setSelectedCard] = useState<any>()

    const timeLineSteps = [
        {
            label: 'Datos generales'
        },
        {
            label: 'Pago'
        },
        {
            label: 'Activado'
        }
    ]

    const location = useLocation();

    console.log(location)

    const movementData = location.state.data
    const movementMaterials = location.state.materials
    const previousData = location.state.previous
    const total = location.state.total
    const iva = location.state.iva

    const handleClickTimeLine = (e: any) => {
        if (e === 0) {
            navigate(-1)
        }
    }

    useEffect(() => {
        getCards()
    }, [])

    const getCards = async () => {
        let datos: any = {
            usu_id
        }
        datos = await EncryptService.encryptData(JSON.stringify(datos));

        ApiServices.apiPost('listTarjetas', {dataEnc: datos}).then(async res => {
            setCardsList(res.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }

    const setCard = async () => {
        const expirationDate = `${cardExpirationDate.getMonth() + 1}/${cardExpirationDate.getFullYear()}`

        let datos:any = {
            usu_id,
            trj_token: cardCvv,
            trj_term: expirationDate,
            trj_desc: cardNumber
        }
        datos = await EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.apiPost('addTarjeta', {dataEnc: datos}).then(async res => {
            if (res.data.valido === 1) {
                setCardsList(res.data.addenda)
                console.log('Toasty Tarjeta agregada con exito')
                setModalNewCardVisible(false)
            }
            console.log(res.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }

    const clearCard = () => {
        setCardNumber(null)
        setCardExpirationDate(null)
        setCardCvv(null)

        setModalNewCardVisible(false)
    }

    const [modalNewCardVisible, setModalNewCardVisible] = useState(false);


    const footerAddCardTemplate = (
        <div>
            <Button label="Cancelar" onClick={clearCard} autoFocus />
            <Button label="Agregar" onClick={setCard} autoFocus />
        </div>
    );

    const checkFields = () => {
        if (paymentType.tarjeta) {
            return selectedCard
        }
        else if (paymentType.transferencia || paymentType.deposito || paymentType.ventanilla) {
            return true
        }
    }

    const [loading, setLoading] = useState(false)

    const savePago = () => {
        const status = checkFields()
        if (status) {
            let data: any = {
                "code": 303,
                "sol_id": movementData.sol_id,
                "trj_id": 0,
            }

            if (paymentType.tarjeta) {
                data.pago_forma = 1;
                data.trj_id = selectedCard.trj_id
            }
            else if (paymentType.transferencia) data.pago_forma = 2;
            else if (paymentType.deposito) data.pago_forma = 3;
            else if (paymentType.ventanilla) data.pago_forma = 4

            data = EncryptService.encryptData(JSON.stringify(data));
            setLoading(true)
            ApiServices.processRequestAdd(data)
                .then(async (res) => {
                    console.log(res)
                    // navigate('/movimiento/resumen', { state: { data: previousData } })
                })
                .catch((error) => {
                    console.log(error)
                    /* Toasty Error */
                });
        } else {
            console.log('Toasty Missing Fields')
        }

    }

    /* OLD */
    const [activeIndex, setActiveIndex] = useState(1);
    const items2 = [
        {
            label: 'Datos generales'
        },
        {
            label: 'Pago'
        },
        {
            label: 'Activado'
        },
    ];

    const [selectedCity, setSelectedCity] = useState(null);
    const cities = [
        { name: 'New York', code: 'NY' },
        { name: 'Rome', code: 'RM' },
        { name: 'London', code: 'LDN' },
        { name: 'Istanbul', code: 'IST' },
        { name: 'Paris', code: 'PRS' }
    ];

    const [items] = useState<string[]>(Array.from({ length: 200 }).map((_, i) => `Item #${i}`));

    const itemTemplate = (item: any, options: VirtualScrollerTemplateOptions) => {
        const className = classNames('flex align-items-center p-2', {
            'surface-hover': options.odd
        });

        return (
            <div className={className} style={{ height: options.props.itemSize + 'px' }}>
                {item.mat_desc}
            </div>
        );
    };

    const [ingredient, setIngredient] = useState('');
    const [value, setValue] = useState('');

    const [paymentType, setPaymentType] = useState({
        tarjeta: false,
        transferencia: false,
        deposito: false,
        ventanilla: false
    })

    const handleSelectPayment = (type: any, valueType: any) => {
        const clonePaymentType: any = paymentType

        for (const key in clonePaymentType) {
            if (clonePaymentType.hasOwnProperty(key)) {
                if (type === key) {
                    clonePaymentType[key] = valueType;
                } else {
                    clonePaymentType[key] = false
                }
            }
        }
        setPaymentType(clonePaymentType)
        setKey(Math.floor(Math.random() * 9) + 1)
    }

    const [key, setKey] = useState(0)


    const headerPaneltarjeta = (
        <div>
            <div className="flex align-items-center">
                <Checkbox onChange={(e) => handleSelectPayment('tarjeta', e.checked)} checked={paymentType.tarjeta}></Checkbox>
                <label htmlFor="ingredient1" className="ml-2">Pago con tarjeta</label>
            </div>
        </div>
    );

    const headerPaneltransferencia = (
        <div>
            <div className="flex align-items-center">
                <Checkbox onChange={(e) => handleSelectPayment('transferencia', e.checked)} checked={paymentType.transferencia}></Checkbox>
                <label htmlFor="ingredient1" className="ml-2">TRANSFERENCIA BANCARIA (SPEI)</label>
            </div>
        </div>
    );

    const headerPanelDeposito = (
        <div>
            <div className="flex align-items-center">
                <Checkbox onChange={(e) => handleSelectPayment('deposito', e.checked)} checked={paymentType.deposito}></Checkbox>
                <label htmlFor="ingredient1" className="ml-2">DEPÓSITO EN BANCOS Y TIENDAS DE CONVENIENCIA</label>
            </div>
        </div>
    );

    const headerPanelVentanilla = (
        <div>
            <div className="flex align-items-center">
                <Checkbox onChange={(e) => handleSelectPayment('ventanilla', e.checked)} checked={paymentType.ventanilla}></Checkbox>
                <label htmlFor="ingredient1" className="ml-2">PAGO EN VENTANILLA</label>
            </div>
        </div>
    );

    return (
        <section>
            <div className="grid">

                <div className='col-12 md:col-8'>
                    <h5 className="m-0">Formas de pago</h5>
                    <p>Selecciona tu forma de pago.</p>
                </div>
                <div className='col-12 md:col-4'>
                    <Steps
                        model={timeLineSteps}
                        activeIndex={1}
                        onSelect={(e) => handleClickTimeLine(e.index)}
                        readOnly={false}
                    />
                </div>
                <div className='col-12 md:col-9'>
                    <div className="grid">
                        <div className='col-12 md:col-12'>
                            <Panel header={headerPaneltarjeta} toggleable collapsed={key === 0 ? false : !paymentType.tarjeta} key={key}>
                                <div className="grid">
                                    <div className='col-12 md:col-4'>
                                        <Dropdown
                                            value={selectedCard}
                                            onChange={(e) => setSelectedCard(e.value)}
                                            options={cardsList}
                                            optionLabel="trj_desc"
                                            placeholder="Selecciona una tarjeta"
                                            className="w-full"
                                        />
                                    </div>
                                    <div className='col-12 md:col-2'>
                                        <Button onClick={() => setModalNewCardVisible(true)} label="Nueva tarjeta" />
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className='col-12 md:col-4'>
                                        <p>Banco</p>
                                        <small>Bancomer</small>
                                    </div>
                                    <div className='col-12 md:col-2'>
                                        <p>Cuenta bancaria</p>
                                        <small>03398493574563</small>
                                    </div>
                                </div>
                            </Panel>
                        </div>
                        <div className='col-12 md:col-12'>
                            <Panel header={headerPaneltransferencia} toggleable collapsed={key === 0 ? false : !paymentType.transferencia} key={key}>
                                <div className="grid">
                                    <div className='col-12 md:col-4'>
                                        <p>Banco</p>
                                        <small>Bancomer</small>
                                    </div>
                                    <div className='col-12 md:col-4'>
                                        <p>Cuenta bancaria</p>
                                        <small>03398493574563</small>
                                    </div>
                                    <div className='col-12 md:col-4'>
                                        <p>Referencia</p>
                                        <small>V858003</small>
                                    </div>
                                    <div className='col-12 md:col-12'>
                                        <small>*Para poder seguir con el registro de vehículos, tendrá que esperar a que el equipo de administrador de Concreto valide el pago.</small>
                                    </div>
                                </div>
                            </Panel>
                        </div>
                        <div className='col-12 md:col-12'>
                            <Panel header={headerPanelDeposito} toggleable collapsed={key === 0 ? false : !paymentType.deposito} key={key}>
                                <div className="grid">
                                    <div className='col-12 md:col-8 centered-radio'>
                                        <RadioButton inputId="ingredient1" name="pizza" value="Cheese" onChange={(e) => setIngredient(e.value)} checked={ingredient === 'Cheese'} />
                                        <img src="/images/options/option1.png" width={300} />
                                    </div>
                                    <div className='col-12 md:col-4 centered-radio'>
                                        <RadioButton inputId="ingredient1" name="pizza" value="Cheese" onChange={(e) => setIngredient(e.value)} checked={ingredient === 'Cheese'} />
                                        <img src="/images/options/option2.png" width={200} />
                                    </div>
                                    <div className='col-12 md:col-8 centered-radio'>
                                        <RadioButton inputId="ingredient1" name="pizza" value="Cheese" onChange={(e) => setIngredient(e.value)} checked={ingredient === 'Cheese'} />
                                        <img src="/images/options/option3.png" width={300} />
                                    </div>
                                    <div className='col-12 md:col-4'>
                                        <p>Luego de hacer clic en “PAGAR”, te daremos las instrucciones para completar tu compra de forma segura.</p>
                                    </div>
                                </div>
                            </Panel>
                        </div>
                        <div className='col-12 md:col-12'>
                            <Panel header={headerPanelVentanilla} toggleable collapsed={key === 0 ? false : !paymentType.ventanilla} key={key}>
                                <div className="grid">
                                    <div className='col-12 md:col-12'>
                                        <p>Si su pago es por medio de ventanilla, por favor, siga al paso de registro de vehículos</p>
                                    </div>
                                </div>
                            </Panel>
                        </div>
                        <div className='col-12 md:col-12'>
                            <Panel header="¿Desea facturar? Si desea facturar, por favor llene los siguientes datos">
                                <div className="grid">
                                    <div className='col-12 md:col-6'>
                                        <span className="p-float-label">
                                            <InputText value={value} onChange={(e) => setValue(e.target.value)} />
                                            <label htmlFor="username">Nombre del contribuyente</label>
                                        </span>
                                    </div>
                                    <div className='col-12 md:col-6'>
                                        <span className="p-float-label">
                                            <InputText value={value} onChange={(e) => setValue(e.target.value)} />
                                            <label htmlFor="username">RFC</label>
                                        </span>
                                    </div>
                                    <div className='col-12 md:col-12'>
                                        <span className="p-float-label">
                                            <InputText value={value} onChange={(e) => setValue(e.target.value)} />
                                            <label htmlFor="username">Dirección</label>
                                        </span>
                                    </div>
                                </div>
                            </Panel>
                        </div>
                    </div>
                </div>

                <div className='col-12 md:col-3'>
                    <Panel header="Resumen de compra">
                        <div className="grid">
                            <div className='col-12 md:col-12'>
                                <h5>Tipo de movimiento</h5>
                                <small>{movementData ? movementData.tipo_desc : ''}</small>
                            </div>
                            <div className='col-12 md:col-12'>
                                <h5>Sucursal</h5>
                                <small>{movementData ? movementData.suc_nom : ''}</small>
                            </div>
                            <div className='col-12 md:col-12'>
                                <h5>Ubicación</h5>
                                <small>{movementData ? `${movementData.sol_calle || ''} ${movementData.sol_num || ''} ${movementData.cp_col || ''} ${movementData.cp_cp || ''} ${movementData.cp_mun || ''} ${movementData.cp_edo || ''}` : ''}</small>
                            </div>
                            <div className='col-12 md:col-12'>
                                <h5>Material requerido</h5>
                                <VirtualScroller
                                    items={movementMaterials}
                                    itemSize={50}
                                    itemTemplate={itemTemplate}
                                    className="border-1 surface-border border-round w-full"
                                    style={{ height: '15vh', marginBottom: 15 }}
                                />
                                <small className='mt-2'><i className='pi pi-info-circle' style={{ color: '#F2C95F' }}></i>Tiene hasta 72 horas para realizar su pago, si pasan de las horas señaladas se cancelará la cotización.</small>
                            </div>
                            <div className='col-12 md:col-6'>
                                <h5>Subtotal</h5>
                            </div>
                            <div className='col-12 md:col-6'>
                                <div className='flex justify-content-end pb-3'>
                                    <div>
                                        <p>${(total).toFixed(2)}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 md:col-6'>
                                <h5>IVA</h5>
                            </div>
                            <div className='col-12 md:col-6'>
                                <div className='flex justify-content-end pb-3'>
                                    <div>
                                        <p>${(iva).toFixed(2)}</p>
                                    </div>
                                </div>
                            </div>
                            {/* <div className='col-12 md:col-6'>
                                <h5>Impuestos</h5>
                            </div>
                            <div className='col-12 md:col-6'>
                                <div className='flex justify-content-end pb-3'>
                                    <div>
                                        <p>$1,000</p>
                                    </div>
                                </div>
                            </div> */}
                            <div className='col-12 md:col-12'>
                                <hr />
                            </div>
                            <div className='col-12 md:col-6'>
                                <h5>Total</h5>
                            </div>
                            <div className='col-12 md:col-6'>
                                <div className='flex justify-content-end pb-3'>
                                    <div>
                                        <p>${(total + iva).toFixed(2)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Panel>
                    <div className='col-12 md:col-12'>
                        {(paymentType.tarjeta || paymentType.transferencia) &&
                            <>
                                <div className='p-2'>
                                    {/* <Button label='PAGAR' onClick={() => navigate('/movimiento/resumen', { state: { data: previousData } })} /> */}
                                    <Button label='PAGAR' onClick={savePago} />
                                </div>
                                <div className='p-2'>
                                    <Button label='Descargar comprobante' />
                                </div>
                            </>
                        }
                        {paymentType.deposito &&
                            <div className='p-2'>
                                <Button label='Descargar PDF' onClick={savePago} />
                            </div>
                        }
                        {paymentType.ventanilla &&
                            <div className='p-2'>
                                <Button label='Siguiente' onClick={savePago} />
                            </div>
                        }
                    </div>
                </div>
            </div>

            <Dialog
                header="Nueva tarjeta"
                visible={modalNewCardVisible}
                style={{ width: '25vw' }}
                onHide={() => setModalNewCardVisible(false)}
                footer={footerAddCardTemplate}
                draggable={false}
                resizable={false}
            >
                <div className="grid" style={{ margin: 10 }}>
                    {/* <div className='col-12 md:col-12'>
                        <Dropdown value={selectedCity} onChange={(e) => setSelectedCity(e.value)} options={cities} optionLabel="name" placeholder="Selecciona una tarjeta" className="w-full" />
                    </div> */}
                    <div className='col-12 md:col-12'>
                        <span className="p-float-label">
                            <InputText
                                keyfilter='int'
                                value={cardNumber}
                                onChange={(e) => setCardNumber(e.target.value)}
                                max={16}
                                maxLength={16}
                            />
                            <label htmlFor="username">Numero de tarjeta</label>
                        </span>
                    </div>
                    <div className='col-12 md:col-12'>
                        <span className="p-float-label">
                            <Calendar
                                value={cardExpirationDate}
                                onChange={(e) => setCardExpirationDate(e.value)}
                                view="month"
                                dateFormat="mm/yy"
                                style={{ width: '100%' }}
                            />
                            <label htmlFor="username">Fecha de caducidad</label>
                        </span>
                    </div>
                    <div className='col-12 md:col-12'>
                        <span className="p-float-label">
                            <InputText
                                keyfilter='int'
                                value={cardCvv}
                                onChange={(e) => setCardCvv(e.target.value)}
                                max={3}
                                maxLength={3}
                            />
                            <label htmlFor="username">CCV</label>
                        </span>
                    </div>
                </div>
            </Dialog>
        </section >
    );
}

export default React.memo(MovimientoPagoAcreditando);
