import CompanyMarker from "../Markers/CompanyMarker/CompanyMarker";
import UserMarker from "../Markers/UserMarker/UserMarker";
import GoogleMapReact from 'google-map-react';

const MapEx = (props: any) => {
    // console.log(props)
     return (
        <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyAq75Xq6BlZ-1E0Iru7SZV_yfxMzXizKO8" }}
        center={props.currentLocation.center}
        zoom={props.currentLocation.zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ }) => {}}
        >
            <UserMarker lat={props.currentLocation.center.lat} lng={props.currentLocation.center.lng} text={'Testing'} /* Kreyser Avrora */ />
            {props.companyLocations && props.companyLocations.map((singleLocation: any, index: any) => (
                <CompanyMarker
                    key={index}
                    lat={singleLocation.suc_lat}
                    lng={singleLocation.suc_lng}
                    text={singleLocation.suc_nom}
                    selected={singleLocation.selected}
                />
            ))}
        </GoogleMapReact>
     )
 };
 
 export default MapEx;
 