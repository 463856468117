import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useRef, useState } from 'react';
import ProductService from '../../../serviceExample/ProductService';
import { useNavigate } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import ApiServices from '../../../service/apiServices';
import EncryptService from '../../../service/encrypt.service';
import { Tag } from 'primereact/tag';
import { FilterMatchMode } from 'primereact/api';

const AdminHistorial = () => {

    const [products, setProducts] = useState<any>(null);
    const [selectedProducts, setSelectedProducts] = useState<any>(null);
    const [globalFilter, setGlobalFilter] = useState<any>(null);
    const dt = useRef<any>(null);
    const navigate = useNavigate();

    const [selectedCity, setSelectedCity] = useState(null);
    const cities = [
        { name: 'New York', code: 'NY' },
        { name: 'Rome', code: 'RM' },
        { name: 'London', code: 'LDN' },
        { name: 'Istanbul', code: 'IST' },
        { name: 'Paris', code: 'PRS' }
    ];
    const [date, setDate] = useState(null);

    /* useEffect(() => {
        const productService = new ProductService();
        productService.getProducts().then(data => setProducts(data));
    }, []); */
    const [historiales, setHistoriales] = useState([]);
    const [historialesclone, setHistorialesClone] = useState([]);

    const [movementTypes, setMovementTypes] = useState([])
    const [selectedMovementType, setSelectedMovementType] = useState<any>(null)
    const [movementTypesFilter, setMovementTypesFilter] = useState([])
    const [filterDate, setFilterDate] = useState<any>()
    const [selectedTypeFilter, setSelectedTypeFilter] = useState<any>()
    const [globalFilterValue, setGlobalFilterValue] = useState("");

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const getMovementTypes = () => {
        let datos
        ApiServices.apiPost('catTipoMov', datos).then(async res => {
            setMovementTypes(res.data.addenda)
            setMovementTypesFilter(res.data.addenda)
        }).catch(error => {
            // toastyErrorServer()
        })
    }

    const formatDate = (date: any) => {
        const fechaOriginal = new Date(date);
        const año = fechaOriginal.getFullYear();
        const mes = (fechaOriginal.getMonth() + 1).toString().padStart(2, '0'); // Los meses van de 0 a 11
        const dia = fechaOriginal.getDate().toString().padStart(2, '0');
        return `${año}-${mes}-${dia}`
    }

    const onGlobalFilterChange = (e: any) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters["global"].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const handleFilter = (filter: any, date: any) => {
        let newArray: any = []
        const cloneArray = structuredClone(historiales)

        if (filter && date) {
            cloneArray.map((singleElement: any) => {
                const splitDate = singleElement.sol_update.split('T')
                if ((singleElement.tipo_desc === filter.tipo_desc) && (formatDate(date) === splitDate[0])) {
                    newArray.push(singleElement)
                }
            })
        } else if (filter || date) {
            cloneArray.map((singleElement: any) => {
                const splitDate = singleElement.sol_update.split('T')
                if ((filter && (singleElement.tipo_desc === filter.tipo_desc)) || (formatDate(date) === splitDate[0])) {
                    newArray.push(singleElement)
                }
            })
        } else {
            newArray = historiales
        }

        setHistorialesClone(newArray)
    }

    const getHistorialCoded = async () => {
        let datos: any = {
            "tipo_id": 4,
        }

        datos = await EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.apiPost('getSolicitudesAdmin', {dataEnc: datos}).then(async res => {
            setHistoriales(res.data.addenda)
            setHistorialesClone(res.data.addenda)
            // setCotizaciones(res.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }

    useEffect(() => {
        // getCotizaciones();
        getMovementTypes();
        getHistorialCoded();
    },[])

    const formatCurrency = (value: any) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }

    const codeBodyTemplate = (rowData: any) => {
        return (
            <>
                <span className="p-column-title">Code</span>
                {rowData.sol_id}
            </>
        );
    }

    const nameBodyTemplate = (rowData: any) => {
        return (
            <>
                <span className="p-column-title">Name</span>
                {rowData.tipo_desc}
            </>
        );
    }


    const priceBodyTemplate = (rowData: any) => {
        return (
            <>
                <span className="p-column-title">Price</span>
                {rowData.sol_update.split('T')[0]}
            </>
        );
    }

    const categoryBodyTemplate = (rowData: any) => {
        return (
            <>
                <span className="p-column-title">Category</span>
                {rowData.volumen}
            </>
        );
    }

    const getStatusColor = (status: any) => {
        switch (status) {
            case 0:
                return '#C94800';
            case 1:
                return '#000000';
            case 2:
                return '#00779C';
            case 3:
                return '#C94800';
            case 4:
                return '#00779C';
            case 5:
                return '#00581C'
            case 6:
                return '#00581C'
        }
    }

    const statusBodyTemplate = (rowData: any) => {
        return (
            <>
                <span className="p-column-title">Status</span>
                {/* <span className={`product-badge status-${rowData.inventoryStatus.toLowerCase()}`}>{rowData.inventoryStatus}</span> */}
                <Tag value={rowData.sol_status == 0 ? 'Cancelada' :rowData.sol_status == 1 ? 'Nueva' : rowData.sol_status == 2 ? 'Cotizada' : rowData.sol_status == 3 ? 'Pendiente' :
                            rowData.sol_status == 4 ? 'Validación' : rowData.sol_status == 5 ? 'Activa' : rowData.sol_status == 6 ? 'Entregada' :  ''} style={{ background: getStatusColor(rowData.sol_status), color: '#FFF' }} />
                {/* {rowData.sol_status} */}
            </>
        )
    }

    const actionBodyTemplate = (rowData: any) => {
        return (
            <div className="actions">
                <Button icon="pi pi-eye" className="p-button-text" onClick={() => navigate('/administrador/historial/detalle', { state: { data: rowData } })} />
            </div>
        );
    }

    const header = (
        <div className="grid">
            <div className='col-12 md:col-12'>
                <h5 className="m-0">Historial</h5>
                <p>Podrás el detalle de los movimientos</p>
            </div>
            <div className='col-12 md:col-12 inline-flex'>
                <div className='col-3'>
                    
                </div>
                <div className='col-3'>
                    <span className="p-float-label p-input-icon-left w-full">
                        <Dropdown
                            value={selectedMovementType}
                            onChange={(e) => {
                                setSelectedMovementType(e.value)
                                setSelectedTypeFilter(e.value)
                                handleFilter(e.value, filterDate)
                            }}
                            options={movementTypes}
                            optionLabel="tipo_desc"
                            placeholder="Tipo de movimiento"
                            className="w-full"
                            emptyMessage="Error al Obtener Movimientos"
                        />
                    </span>
                </div>
                <div className='col-3'>
                    <span className="p-float-label w-full">
                        <Calendar
                            inputId="date"
                            showIcon
                            className="w-full"
                            locale="es"
                            dateFormat="dd/MM/yy"
                            value={filterDate}
                            onChange={(e) => {
                                setFilterDate(e.target.value)
                                handleFilter(selectedTypeFilter, e.target.value)
                            }}
                            showButtonBar
                        />
                        <label htmlFor="username">Fecha</label>
                    </span>
                </div>
                <div className='col-3'>
                    <span className="p-float-label p-input-icon-left w-100">
                        <i className="pi pi-search" />
                        <InputText
                            type="search"
                            value={globalFilterValue}
                            onChange={onGlobalFilterChange}
                        />
                        <label htmlFor="username">Buscador general</label>
                    </span>
                </div>
                
            </div>
        </div>
    );


    return (
        <section>
            <DataTable ref={dt} value={historialesclone} onSelectionChange={(e) => setSelectedProducts(e.value)}
                dataKey="id" paginator rows={5} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Mostrando {first} de {last} de un total de {totalRecords} entradas"
                filters={filters} emptyMessage="No se encuentran entradas." header={header} responsiveLayout="scroll">
                <Column field="sol_id" header="Folio" sortable body={codeBodyTemplate} ></Column>
                <Column field="tipo_desc" header="Movimiento" sortable body={nameBodyTemplate} ></Column>
                <Column field="sol_update" header="Fecha" body={priceBodyTemplate} sortable ></Column>
                <Column field="volumen" header="Volumen" sortable body={categoryBodyTemplate} ></Column>
                <Column field="category" header="N° de cargas" sortable body={categoryBodyTemplate} ></Column>
                <Column field="sol_status" header="Estatus" body={statusBodyTemplate} sortable ></Column>
                <Column body={actionBodyTemplate} headerStyle={{ minWidth: '1rem' }}></Column>
            </DataTable>
            <div className='flex justify-content-end pb-3'>
                <div className="p-3">
                    <Button label="Descargar reporte filtrado" />
                </div>
            </div>
        </section>
    );
}

export default React.memo(AdminHistorial);