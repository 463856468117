import { Button } from "primereact/button"
import { InputText } from "primereact/inputtext"
import { Password } from "primereact/password";
import { Toast } from "primereact/toast";
import { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import EncryptService from "../../service/encrypt.service";
import ApiServices from "../../service/apiServices";

export const Olvide3 = (props: any) => {

	const navigate = useNavigate();
    const location = useLocation();
    const usu_id = location.state.usu_id;
	console.log("ID DE USUARIO", usu_id)
	const toast = useRef<any>();
	
	const [password, setpassword] = useState<string>("");
	const [confirmpassword, setConfirmPassword] = useState<string>("");

	function validatePasswords() {
		if (password && confirmpassword) {
		  return !(password === confirmpassword);
		}
	}

	const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));
	

	const confirmCode = async () => {
		if(password && confirmpassword){
			if(password === confirmpassword){
				let datos: any =  {
					"code":104,
					"usu_id": usu_id,
					"usu_psw": password
				}
				console.log(datos)
				datos = EncryptService.encryptData(JSON.stringify(datos));
				ApiServices.processRequestAdd(datos)
				.then(async (res) => {
					console.log(res)
					if(res.data.valido === 1){
						// navigate('olvide-pt3')
						toast.current.show({
							severity: "success",
							summary: "Campo incompleto",
							detail: "Se ha actualizado tu contraseña, inicia sesión",
							life: 4000,
						});
						await delay(2500);
						navigate('login')

					} else {
						toast.current.show({
							severity: "error",
							summary: "Error",
							detail: res.data.mensaje,
							life: 4000,
						});
					}
				})
			}
		} else {
			
		}
	}
	return (
		<section>
      		<Toast ref={toast} />
			<div className='fondo'>
				<div className='grid' style={{ height: '100%', marginTop: 0 }}>
					<div className='col-12 md:col-6 p-0' >
						<img src="assets/img/login-fondo.svg" className='w-100' />
					</div>
					<div className='col-12 md:col-6 flex justify-content-center align-items-center'>
						<div className='login'>
							<h1>Olvidé mi contraseña</h1>
							<p>Ingresa tu nueva contraseña, recuerda anotarla en tu cuaderno secreto de contraseñas.</p>
							<div className='grid p-5'>
								<div className='col-12 md:col-12'>
									<span className="p-float-label">
									<Password
										id="password"
										value={password}
										onChange={(e) => {setpassword(e.target.value)}}
										className={`p-inputtext-lg ${validatePasswords() && "p-invalid"}`}
										feedback={false}
										toggleMask
										aria-describedby="password-help"
										style={{ width: "100%" }}
										/>
											<label htmlFor="password">Contraseña</label>
									</span>
									{validatePasswords() && (
										<small id="password-help" style={{ color: "red" }}>
											Las contraseñas no coinciden.
										</small>
									)}
								</div>
								<div className='col-12 md:col-12'>
									<span className="p-float-label">
									<Password
										id="confirmPassword"
										value={confirmpassword}
										onChange={(e) => { setConfirmPassword(e.target.value)}}
										className={`p-inputtext-lg ${validatePasswords() && "p-invalid"}`}
										feedback={false}
										toggleMask
										aria-describedby="confirmPassword-help"
										style={{ width: "100%" }}
										/>
										<label htmlFor="confirmPassword">
										Confirmar Contraseña
										</label>
									</span>
									{validatePasswords() && (
										<small id="confirmPassword-help" style={{ color: "red" }}>
											Las contraseñas no coinciden.
										</small>
									)}
								</div>
							</div>
							<div className='flex justify-content-between pb-3'>
								<div>
								</div>
								<div>
									<Button label="Guardar" onClick={confirmCode} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}