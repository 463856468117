import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useRef, useState } from 'react';
import * as ExcelJs from 'exceljs';
import * as FileSaver from 'file-saver';

const AdminReports = () => {

    const [globalFilter, setGlobalFilter] = useState<any>(null);
    const [selectedProduct, setSelectedProduct] = useState<any>(null);
    const [materiales, setMateriales] = useState([]);
    const dt = useRef<any>(null);

    const exportToExcel = async () => {

        try {
            console.log("EXPORTAR ARCHIVO")

            const workbook = new ExcelJs.Workbook();
            const worksheet = workbook.addWorksheet('Reportes');

            // Definir las columnas y agregar datos
            worksheet.columns = [
                {header: 'Cliente', key: 'cliente', width: 32},
                {header: 'Tipo de material', key: 'tipoMaterial', width: 32},
                {header: 'Fecha de entrega', key: 'feEntrega', width: 32},
                {header: 'Cantidad material', key: 'material', width: 32},
                {header: 'Cantidad total mensual entregada por cliente', key: 'cantCliente', width: 32},
                {header: 'Dirección', key: 'direccion', width: 32},
                {header: 'Núm. Factura', key: 'numFactura', width: 32},
            ]

            // Aplicar estilo de negrita a los títulos (encabezados)
            worksheet.getRow(1).eachCell((cell) => {
                cell.font = { bold: true };
                cell.alignment = {horizontal: 'center', vertical: 'middle', wrapText: true}
            });

            
            //Date to the name
            const current_date = new Date();
            // Convertir la fecha a una cadena (string)
            const dateString = current_date.toISOString();
            

            // Generar el archivo Excel
            const excelBuffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            FileSaver.saveAs(blob, "CONCRETOS_REPORTES_" + dateString + '.xlsx');
        } catch (error) {
            console.log(error);
        }
    }

    const header = (
        <div className="grid">
            <div className="col-12 md:col-8">
                <h5 className="m-0">Reporte</h5>
                <p>Aquí podrá generar su reporte en formato excel</p>
            </div>
            <div className="col-12 md:col-4 inline-flex">
                <div className="col-8">
                    <span className="p-float-label p-input-icon-left w-100" style={{height:"auto"}}>
                        <i className="pi pi-search" />
                        <InputText id="search" type="search" onInput={(e) => setGlobalFilter(e.currentTarget.value)} />
                        <label htmlFor="search">Buscar por No. Factura</label>
                    </span>
                </div>
                <div className="col-4">
                    <Button label="Exportar" icon="pi pi-file-excel" onClick={exportToExcel}></Button>
                </div>
            </div>
        </div>
    )
    return (
        <section>
            <DataTable ref={dt} value={materiales} selection={selectedProduct} onSelectionChange={(e) => setSelectedProduct(e.value)}
                dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Mostrando {first} de {last} de un total de {totalRecords}"
                globalFilter={globalFilter} emptyMessage="No se encuentran datos." header={header} responsiveLayout="scroll">
                    <Column header="Cliente"></Column>
                    <Column header="Tipo de material"></Column>
                    <Column header="Fecha de entrega"></Column>
                    <Column header="Cantidad material"></Column>
                    <Column header="Cantidad total mensual entregada por cliente"></Column>
                    <Column header="Dirección"></Column>
                    <Column header="Núm. Factura"></Column>

            </DataTable>
        </section>
    )
}

export default React.memo(AdminReports);