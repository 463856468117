import { Accordion, AccordionTab } from 'primereact/accordion';
import { Panel } from 'primereact/panel';

export const Faqs = (props: { data: any[] }) => {
    return (
        <div id='faqs' className='opacidad text-center '>
            <div className='intro-fq'>
                <div className='overlay'>
                    <div className='container faq'>
                        <div className="col-xs-12 col-md-4">
                            <div className='section-title'>
                                <h2><b>Preguntas frecuentes</b></h2>
                            </div>
                        </div>
                        <div className="col-xs-8 col-md-8 faqpanels">

                            <Panel header="¿Qué tipo de residuos aceptamos?" toggleable collapsed className='panelFaq' expandIcon={'pi pi-plus-circle'} 
                            collapseIcon={'pi pi-minus-circle'}>
                                <p className="m-0">
                                Los materiales aceptados son:
                                </p>
                                <div style={{display: 'flex'}}>
                                <ul>
                                    <li>Adocretos</li>
                                    <li>Blocks</li>
                                    <li>Arcillas</li>
                                    <li>Concreto simple</li>
                                </ul>
                                <ul>
                                    <li>Concreto armado</li>
                                    <li>Mampostería de piedra</li>
                                    <li>Fresado de capeta asfáltica</li>
                                    <li>Tabiques</li>
                                </ul>
                                <ul>
                                    <li>Cerámicos</li>
                                    <li>Ladrillos</li>
                                    <li>Morteros</li>
                                    <li>Adopastos</li>
                                </ul>
                                </div>
                                
                            </Panel>
                            <Panel header="¿Qué materiales no aceptamos?" toggleable collapsed className='panelFaq' expandIcon={'pi pi-plus-circle'} collapseIcon={'pi pi-minus-circle'}>
                                <div style={{display: 'flex'}}>
                                    <ul>
                                        <li>Aceites</li>
                                        <li>Llantas usadas</li>
                                        <li>Asbestos</li>
                                        <li>Plásticos</li>
                                        <li>Tanque de gas</li>
                                        <li>yeso</li>
                                    </ul>
                                    <ul>
                                        <li>Textiles</li>
                                        <li>Lodos bentoníticos</li>
                                        <li>Enseres domésticos</li>
                                        <li>Grasas</li>
                                        <li>Baterías</li>
                                        <li>Papel</li>
                                    </ul>
                                    <ul>
                                        <li>Sustancias químicas</li>
                                        <li>Tablaroca</li>
                                        <li>Vidrio</li>
                                        <li>Residuos sólidos urbanos (orgánicos e inorgánicos)</li>
                                    </ul>
                                </div>
                            </Panel>
                            <Panel header="¿Cuáles son nuestros horarios?" toggleable collapsed className='panelFaq' expandIcon={'pi pi-plus-circle'} collapseIcon={'pi pi-minus-circle'}>
                                <p className="m-0">
                                De lunes a viernes de 6:30 a 18:00 horas y días sábados de 6:30 a 13:00 horas, en caso de requerir servicio fuera de 
                                nuestros horarios se deberá gestionar con nuestra área de caja al teléfono 55-54-28-25-95
                                </p>
                            </Panel>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}