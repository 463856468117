import { RequestService } from "../utilities/request.component";
import EncryptService from "./encrypt.service";

export class PerfilService {

    static getAllPerfiles(token: any) {
        return RequestService.sendGetRequest("perfil_consola/obtenPerfiles", token);
    } // loginUsuario

    static modificaPerfil(user: any, token: any) {
        let dataEncrypt = EncryptService.encryptData(user);
        return RequestService.sendPostRequest(dataEncrypt, 'perfil_consola/actualizarPerfil', token);
    } // modificaUsuario

    static agregaPerfil(user: any, token: any) {
        let dataEncrypt = EncryptService.encryptData(user);
        return RequestService.sendPostRequest(dataEncrypt, 'perfil_consola/agregaPerfil', token);
    } // modificaUsuario

    /**API 2.0 */
    static getPerfiles(data: any, token: string | undefined) {
        return RequestService.sendPostRequest(data, 'apipost/getPerfiles', token);
    }
    static updatePerfil(data: any, token: string | undefined) {
        return RequestService.sendPostRequest(data, 'apipost/updatePerfil', token);
    }
    static setPerfilenCatalogo(data: any, token: string | undefined) {
        return RequestService.sendPostRequest(data, 'apipost/setPerfilesCatalogo', token);
    }

} 