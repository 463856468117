import { Button } from "primereact/button"
import { InputText } from "primereact/inputtext"
import { Panel } from "primereact/panel"
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react';
import EncryptService from "../../service/encrypt.service";
import ApiServices from "../../service/apiServices";
import authGuardService from "../../service/authGuardService";
import { classNames } from "primereact/utils";
import { Calendar } from "primereact/calendar";
import { Password } from "primereact/password";

const PerfilCliente = (props: any) => {

    const user = authGuardService.getUser();
    const { usu_id } = authGuardService.getUser()

    // console.log("USUARIO PROFILE -->", user)
    /* OLD */
    let emptyCatalog = {
        usu_id:user.usu_id,
        prf_id:user.prf_id,
        usu_nom:user.usu_nom,
        usu_pat:user.usu_pat,
        usu_mat:user.usu_mat,
        usu_empresa:user.usu_empresa,
        usu_tel:user.usu_tel,
        usu_email:user.usu_email,
        usu_psw:'',
        confirm_usu_psw: '',
    };

    const [catalog, setCatalog] = useState<any>(emptyCatalog);
    const [submitted, setSubmitted] = useState<boolean>(false);
    const toast = useRef<any>(null);

    const onInputChange = (e: any, name: string) => {
        const val = (e.target && e.target.value) || '';
        let _product = { ...catalog };
        _product[`${name}`] = val;
        setCatalog(_product);
    }

    const UpdateUserProfile = async () => {
        if(catalog.usu_nom.trim() && catalog.usu_pat.trim() && catalog.usu_mat.trim() && catalog.usu_mat.trim() && catalog.usu_mat.trim() && catalog.usu_mat.trim() && catalog.confirm_usu_psw.trim() === catalog.confirm_usu_psw.trim()){
            let data: any = {
                "code":"205",
                "usu_id": catalog.usu_id,
                "prf_id": catalog.prf_id,
                "usu_nom": catalog.usu_nom,
                "usu_pat": catalog.usu_pat,
                "usu_mat": catalog.usu_mat,
                "usu_empresa": catalog.usu_empresa,
                "usu_tel": catalog.usu_tel,
                "usu_email": catalog.usu_email,
                "usu_psw": catalog.usu_psw,
            }
            data = await EncryptService.encryptData(JSON.stringify(data));
            ApiServices.processRequestAdd(data)
            .then(async (res) => {
                let dataUser: any = {
                    "usu_id": catalog.usu_id,
                    "prf_id": catalog.prf_id,
                    "usu_nom": catalog.usu_nom,
                    "usu_pat": catalog.usu_pat,
                    "usu_mat": catalog.usu_mat,
                    "usu_empresa": catalog.usu_empresa,
                    "usu_tel": catalog.usu_tel,
                    "usu_email": catalog.usu_email,
                    "usu_psw": catalog.usu_psw,
                }

                authGuardService.sendUser(dataUser)
                toast.current.show({ severity: 'success', summary: 'Exito', detail: 'Registro Editado con Exito', life: 3000 });
            })
            .catch((error) => {
                /* Toasty Error */
                console.log(error)
            });
        } else {
            toast.current.show({ severity: 'error', summary: 'Campos incompletos', detail: 'Debes de completar todos los campos de usuario para actualizar tu información.', life: 3000 });
        }
    }

    /** TARJETAS DEL USUARIO */

    const [cardNumber, setCardNumber] = useState<any>()
    const [cardExpirationDate, setCardExpirationDate] = useState<any>()
    const [cardCvv, setCardCvv] = useState<any>()

    const [cardsList, setCardsList] = useState([])
    const [selectedCard, setSelectedCard] = useState<any>()

    useEffect(() => {
        getCards()
    }, [])

    const getCards = async () => {
        let datos: any = {
            usu_id
        }
        datos = await EncryptService.encryptData(JSON.stringify(datos));

        ApiServices.apiPost('listTarjetas', {dataEnc: datos}).then(async res => {
            setCardsList(res.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }

    const setCard = async () => {
        if(cardCvv && cardExpirationDate && cardNumber){
            const expirationDate = `${cardExpirationDate.getMonth() + 1}/${cardExpirationDate.getFullYear()}`
    
            let datos:any = {
                usu_id,
                trj_token: cardCvv,
                trj_term: expirationDate,
                trj_desc: cardNumber
            }
            datos = await EncryptService.encryptData(JSON.stringify(datos));
            ApiServices.apiPost('addTarjeta', {dataEnc: datos}).then(async res => {
                if (res.data.valido === 1) {
                    setCardsList(res.data.addenda)
                    toast.current.show({ severity: 'success', summary: 'Exito', detail: 'Se ha guardado la tarjeta con éxito', life: 3000 });
                }
                console.log(res.data.addenda)
            }).catch(error => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: error, life: 3000 });
            })
        } else {
            toast.current.show({ severity: 'error', summary: 'Campos incompletos', detail: 'Debes de llenar todos los campos para agregar una tarjeta', life: 3000 });
            
        }
    }
    const deleteCard = async (tarj: any) => {
        let datos:any = {
            usu_id,
            trj_id: tarj.trj_id
        }
        console.log("TARJETA PRA ELIMINAR -->", datos)

        datos = await EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.apiPost('deleteTarjeta', {dataEnc: datos}).then(async res => {
            if (res.data.valido === 1) {
                setCardsList(res.data.addenda)
                toast.current.show({ severity: 'success', summary: 'Exito', detail: 'Se ha eliminado la tarjeta con éxito', life: 3000 });
            }
        }).catch(error => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: error, life: 3000 });

        })
    }

    return (
        <section>
            <Toast ref={toast} />
            <div className="grid">
                <div className='col-12 px-5'>
                    <div className='flex justify-content-between pb-3'>
                        <div>
                            <h5>Perfil</h5>
                        </div>
                        <div>
                            {/* <Button label="Editar" icon="pi pi-pencil" iconPos="left" />  */}
                        </div>
                    </div>
                </div>
                <div className="col-12 md:col-12">
                    <Panel header="Datos generales">
                        <div className="grid">
                            <div className='col-12 md:col-4'>
                                <span className="p-float-label">
                                    <InputText id="name"
                                     value={catalog.usu_nom}
                                     onChange={(e) => onInputChange(e, 'usu_nom')}
                                     className={classNames({ 'p-invalid': submitted && !catalog.usu_nom })} />
                                    <label htmlFor="name">Nombre</label>
                                </span>
                            </div>
                            <div className='col-12 md:col-4'>
                                <span className="p-float-label">
                                    <InputText 
                                    value={catalog.usu_pat}
                                    onChange={(e) => onInputChange(e, 'usu_pat')}
                                    className={classNames({ 'p-invalid': submitted && !catalog.usu_pat })} />
                                    <label htmlFor="name">Primer apellido</label>
                                </span>
                            </div>
                            <div className='col-12 md:col-4'>
                                <span className="p-float-label">
                                    <InputText 
                                    value={catalog.usu_mat}
                                    onChange={(e) => onInputChange(e, 'usu_mat')}
                                    className={classNames({ 'p-invalid': submitted && !catalog.usu_mat })} />
                                    <label htmlFor="name">Segundo apellido</label>
                                </span>
                            </div>
                            <div className='col-12 md:col-4'>
                                <span className="p-float-label">
                                    <InputText 
                                    value={catalog.usu_tel}
                                    maxLength={10}
                                    onChange={(e) => onInputChange(e, 'usu_tel')}
                                    className={classNames({ 'p-invalid': submitted && !catalog.usu_tel })} />
                                    <label htmlFor="name">Teléfono</label>
                                </span>
                            </div>
                            <div className='col-12 md:col-4'>
                                <span className="p-float-label">
                                    <InputText 
                                     value={catalog.usu_empresa}
                                     onChange={(e) => onInputChange(e, 'usu_empresa')}
                                     className={classNames({ 'p-invalid': submitted && !catalog.usu_empresa })} />
                                    <label htmlFor="name">Empresa</label>
                                </span>
                            </div>
                        </div>
                    </Panel>
                </div>
                {/* <div className="col-12 md:col-6">
                    <Panel header="Tarjetas de pago">
                        <div className="grid"> */}
                            {/* <div className='col-12 md:col-6'>
                                <span className="p-float-label">
                                    <InputText className='p-inputtext-lg' id="name" />
                                    <label htmlFor="name">Nombre</label>
                                </span>
                            </div>
                            <div className='col-12 md:col-6'>
                                <span className="p-float-label">
                                    <InputText className='p-inputtext-lg' id="name" />
                                    <label htmlFor="name">Banco</label>
                                </span>
                            </div> */}
                            {/* <div className='col-12 md:col-6'>
                                <span className="p-float-label">
                                    <InputText 
                                    keyfilter='int'
                                    value={cardNumber}
                                    onChange={(e) => setCardNumber(e.target.value)}
                                    max={16}
                                    maxLength={16} />
                                    <label htmlFor="name">Tarjeta</label>
                                </span>
                            </div>
                            <div className='col-12 md:col-6'>
                                <span className="p-float-label">
                                <Calendar
                                    value={cardExpirationDate}
                                    onChange={(e) => setCardExpirationDate(e.value)}
                                    view="month"
                                    dateFormat="mm/yy"
                                    style={{ width: '100%' }} />
                                    <label htmlFor="name">Fecha vencimiento</label>
                                </span>
                            </div>
                            <div className='col-12 md:col-6'>
                                <span className="p-float-label">
                                    <InputText 
                                        keyfilter='int'
                                        value={cardCvv}
                                        onChange={(e) => setCardCvv(e.target.value)}
                                        max={3}
                                        maxLength={3} />
                                    <label htmlFor="name">CVV</label>
                                </span>
                            </div>
                            <div className='col-12 md:col-6'>
                                <div className='flex justify-content-between pb-3'>
                                    <div>

                                    </div>
                                    <div>
                                        <Button label="Guardar tarjeta" onClick={setCard} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Panel>
                </div> */}
                <div className="col-12 md:col-6">
                    <Panel header="Tarjetas guardadas">
                        {cardsList && cardsList.map((card: any)=>{
                            return( 
                            <div className="grid">
                                <div className="col-12 md:col-4">
                                    <h6>Cuenta bancaria</h6>
                                    <small>{card.trj_desc}</small>
                                </div>
                                <div className="col-12 md:col-4">
                                    <div className="flex justify-content-end pb-3">
                                        <Button icon="pi pi-trash" onClick={(e)=>{deleteCard(card)}} tooltip="Borrar" />
                                    </div>
                                </div>
                            </div>)
                        })}
                    </Panel>
                </div>
                <div className="col-12 md:col-6">
                    <Panel header="Cambiar contraseña">
                        <div className="grid">
                            <div className='col-12 md:col-4'>
                                <span className="p-float-label">
                                    <Password
                                        value={catalog.usu_psw}
                                        onChange={(e) => onInputChange(e, 'usu_psw')}
                                        toggleMask
                                        className={classNames({ 'p-invalid': submitted && !catalog.usu_psw && !catalog.id_usuario_sistema })}
                                        tooltip="Ingresa la contraseña"
                                    />
                                    <label htmlFor="name">Contraseña</label>
                                </span>
                                {submitted && !catalog.usu_psw && !catalog.usu_id && <small className="p-invalid">Contraseña es requerida.</small>}
                                {submitted && catalog.usu_psw != catalog.usu_psw && <small className="p-invalid">Las contraseñas no coinciden.</small>}
                            </div>
                            <div className='col-12 md:col-4'>
                                <span className="p-float-label">
                                    <Password
                                        value={catalog.confirm_usu_psw}
                                        onChange={(e) => onInputChange(e, 'confirm_usu_psw')}
                                        className={classNames({'p-invalid': submitted && !catalog.confirm_usu_psw && !catalog.usu_id })}
                                        toggleMask
                                        tooltip="Confirma la contraseña"
                                    />
                                    <label htmlFor="name">Confirmar contraseña</label>
                                </span>
                                {submitted && !catalog.confirm_usu_psw && !catalog.usu_id && <small className="p-invalid">Confirmar contraseña es requerido.</small>}
                                {submitted && catalog.usu_psw != catalog.confirm_usu_psw && <small className="p-invalid">Las contraseñas no coinciden.</small>}
                            </div>
                        </div>
                    </Panel>
                </div>
                <div className='col-12 px-5'>
                    <div className='flex justify-content-between pb-3'>
                        <div>

                        </div>
                        <div>
                            <Button label="Guardar cambios" onClick={UpdateUserProfile} />
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default React.memo(PerfilCliente);
