import React from 'react';

const AppFooter = (props: any) => {

    return (
        <div className="layout-footer text-center">
            <small>&#169; Derechos reservados - 2023</small>
        </div>

    )
}

export default AppFooter;