import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Panel } from 'primereact/panel';
import { Steps } from 'primereact/steps';
import { VirtualScroller, VirtualScrollerTemplateOptions } from 'primereact/virtualscroller';
import React, { useEffect, useRef, useState } from 'react';
// import ProductService from '../../serviceExample/ProductService';
import { useLocation, useNavigate } from 'react-router-dom';
import { classNames } from 'primereact/utils';
import { RadioButton } from 'primereact/radiobutton';
import { Dialog } from 'primereact/dialog';
import { Checkbox } from 'primereact/checkbox';
import ProductService from '../../../serviceExample/ProductService';
import authGuardService from '../../../service/authGuardService';
import EncryptService from '../../../service/encrypt.service';
import ApiServices from '../../../service/apiServices';
import { Toast } from 'primereact/toast';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import configuration from '../../../configurationFile';
import { saveAs } from 'file-saver';

const MovimientoResumen = () => {

    const location = useLocation();

    const previousData = location.state.data
    const movementMaterials: any[] = location.state.materials
    const total = location.state.total
    const iva = location.state.iva
    const url = configuration.urlmedia;

    const [activeIndex, setActiveIndex] = useState(2);
    const items2 = [
        {
            label: 'Datos generales'
        },
        {
            label: 'Pago'
        },
        {
            label: 'Activado'
        },
    ];
    const [value, setValue] = useState('');
    const [conductor, setConductor] = useState('');
    const [placa, setPlaca] = useState('');
    const [volumen, setVolumen] = useState('');
    const [qr, setQr] = useState<boolean>(false);


    const [items] = useState<string[]>(Array.from({ length: 100000 }).map((_, i) => `Item #${i}`));
    
    const itemTemplate = (item: any, options: VirtualScrollerTemplateOptions) => {
        const className = classNames('flex align-items-center p-2', {
            'surface-hover': options.odd
        });

        return (
            <div className={className} style={{ height: options.props.itemSize + 'px' }}>
                {item.mat_desc}
            </div>
        );
    };

    const [checked, setChecked] = useState(false);
    const [products, setProducts] = useState<any>(null);
    const [movimientos, setMovimientos] = useState<any>(null);
    const toast = useRef<any>(null);
    const [selectedProducts, setSelectedProducts] = useState<any>(null);
    const [globalFilter, setGlobalFilter] = useState<any>(null);
    const dt = useRef<any>(null);
    const navigate = useNavigate();

    const successToast = () => {
        toast.current.show({ severity: 'success', summary: 'Guardado', detail: 'Se ha guardado con éxito.', life: 3000 });
    };

    const errorValidateToast = () => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Revisa que todos los campos estén llenos antes de guardar.', life: 3000 });
    };

    const errorToast = () => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Ha ocurrido un error, intentalo más tarde.', life: 3000 });
    };



    const codeBodyTemplate = (rowData: any) => {
        return (
            <>
                <span className="p-column-title">Folio</span>
                {rowData.mov_folio}
            </>
        );
    }

    const nameBodyTemplate = (rowData: any) => {
        return (
            <>
                <span className="p-column-title">Conductor</span>
                {rowData.mov_conductor}
            </>
        );
    }


    const priceBodyTemplate = (rowData: any) => {
        return (
            <>
                <span className="p-column-title">Placa</span>
                {rowData.mov_placa}
            </>
        );
    }

    const VolumenBodyTemplate = (rowData: any) => {
        return (
            <>
                <span className="p-column-title">Volumem m³</span>
                {rowData.mov_volumen}
            </>
        );
    }

    const actionBodyTemplate = (rowData: any) => {
        return (
            <div className="actions">
                <Button icon="pi pi-trash" className="p-button-text" onClick={e => {deleteMovimiento(rowData)}} />
            </div>
        );
    }

    const [selectedCity, setSelectedCity] = useState(null);
    const cities = [
        { name: 'New York', code: 'NY' },
        { name: 'Rome', code: 'RM' },
        { name: 'London', code: 'LDN' },
        { name: 'Istanbul', code: 'IST' },
        { name: 'Paris', code: 'PRS' }
    ];

    const getMovimientos = async () => {
        let datos:any = {sol_id: previousData.sol_id}
        datos = await EncryptService.encryptData(JSON.stringify(datos));

        ApiServices.apiPost('solMovimientos', {dataEnc: datos}).then(async res => {
            setMovimientos(res.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }
    

    useEffect(() => {
        getMovimientos();
    }, [previousData]);

    const saveMovimiento = async () => {
        if(conductor.trim() && placa.trim() && volumen.trim()){
            let datos:any = {
                "sol_id": previousData.sol_id, 
                "mov_conductor": conductor, 
                "mov_placa": placa, 
                "mov_volumen": volumen,
            }
            datos = await EncryptService.encryptData(JSON.stringify(datos));

            ApiServices.apiPost('addMovimiento', {dataEnc: datos})
                .then(async (res) => {
                    successToast()
                    setMovimientos(res.data.addenda)
                })
                .catch((error) => {
                    errorToast()
                    /* toast.current.show({
                        severity: "error",
                        summary: "Error",
                        detail: "Intentelo de Nuevo mas tarde.",
                        life: 4000,
                    }); */
                });
        } else {
            errorValidateToast()
        }
    }

    const deleteMovimiento = async (value: any) => {
        let datos: any = {
            "sol_id": value.sol_id, 
            "mov_id": value.mov_id, 
        }

        datos = await EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.apiPost('deleteMovimiento', {dataEnc: datos})
            .then(async (res) => {
                successToast()
                setMovimientos(res.data.addenda)
            })
            .catch((error) => {
                errorToast()
                console.log(error)
            });
    }

    const savePagoTransDepVent = () => {
        let data: any

        data = {
            "code": 305,
            "sol_id": previousData.sol_id,
            "pago_forma": 4
        }


        
        data = EncryptService.encryptData(JSON.stringify(data));
        ApiServices.processRequestAdd(data)
            .then(async (res) => {
                descargarQRgenerado(res)
            })
            .catch((error) => {
                console.log(error)
                /* Toasty Error */
            });
    }

    const GenerateQR = async () => {
        let datos:any = {
            "code": 304,
            "sol_id": previousData.sol_id, 
            "pago_forma":previousData.pago_forma, 
        }
        datos = await EncryptService.encryptData(JSON.stringify(datos));

        ApiServices.processRequestAdd(datos)
        .then(async (res) => {
            successToast()
            descargarQRgenerado(res)
            setQr(true)
        })
        .catch((error) => {
            /* Toasty Error */
        });
    }

    const descargarQRgenerado = (res: any) => {
        const imageSrc = res.data.url;
        saveAs(imageSrc, "ConcretosQR_"+ previousData.sol_id);
    };

    return (
        <section>
            <Toast ref={toast} />
            <div className="grid">
                <div className='col-12 md:col-8'>
                    <h5 className="m-0">SOLICITUD ACTIVA</h5>
                    <p>{previousData && previousData.sol_id}.</p>
                </div>
                <div className='col-12 md:col-4'>
                    <Steps model={items2} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly={true} />
                </div>
                <div className='col-12 md:col-9'>
                    <div className="grid">
                        <div className='col-12 md:col-4'>
                            <span className="p-float-label">
                                <InputText id="username" value={conductor} onChange={(e) => setConductor(e.target.value)} />
                                <label htmlFor="username">Conductor</label>
                            </span>
                        </div>
                        <div className='col-12 md:col-4'>
                            <span className="p-float-label">
                                <InputText id="username" value={placa} onChange={(e) => setPlaca(e.target.value)} />
                                <label htmlFor="username">Placa vehiculo</label>
                            </span>
                        </div>
                        <div className='col-12 md:col-4'>
                            <span className="p-float-label">
                                <InputText id="username" keyfilter="num" value={volumen} onChange={(e) => setVolumen(e.target.value)} />
                                <label htmlFor="username">Volumen m3</label>
                            </span>
                        </div>
                    </div>
                    <div className='flex justify-content-end pb-3'>
                        <div>
                            <Button label="Agregar" onClick={saveMovimiento} />
                        </div>
                    </div>
                    <div className="grid">
                        {/* <div className='col-12 md:col-12'>
                            <Checkbox onChange={(e: any) => setChecked(e.checked)} checked={checked}></Checkbox>
                            <label htmlFor="ingredient1" className="ml-2">Ver historial</label>
                        </div> */}
                        <div className='col-12 md:col-12'>
                            <DataTable ref={dt} value={movimientos} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                                dataKey="id" paginator rows={5} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Mostrando {first} de {last} de un total de {totalRecords} entradas"
                                globalFilter={globalFilter} emptyMessage="No se encuentran entradas." responsiveLayout="scroll">
                                <Column field="mov_folio" header="Folio" sortable body={codeBodyTemplate} ></Column>
                                <Column field="mov_conductor" header="Conductor" sortable body={nameBodyTemplate} ></Column>
                                <Column field="mov_placa" header="Placa" body={priceBodyTemplate} sortable ></Column>
                                <Column field="mov_volumen" header="Volumen m³" body={VolumenBodyTemplate} sortable></Column>
                                <Column body={actionBodyTemplate} headerStyle={{ minWidth: '1rem' }}></Column>
                            </DataTable>
                        </div>
                        {/* <div className='col-12 md:col-12'>
                            <p><i className='pi pi-info-circle'></i>Te faltan 1 m3 para llenar los 8.5 m3</p>
                        </div> */}
                        <div className='col-12 md:col-6'>
                            {/* <Dropdown value={selectedCity} onChange={(e) => setSelectedCity(e.value)} options={cities} optionLabel="name" placeholder="Generar QR" className="w-full" /> */}
                        </div>
                        <div className='col-12 md:col-2'>
                            <Button label='Generar QR' onClick={GenerateQR} />
                        </div>
                    </div>
                    <div className="grid p-5">
                        <div className='col-12 md:col-4'>

                        </div>
                        {qr?<div className='col-12 md:col-4 text'>
                          <div className="grid">
                                    <div className='col-12 md:col-3'>
                                        <i className='pi pi-check-circle p-2' style={{ fontSize: '2rem', color: 'green' }}></i>
                                    </div>
                                    <div className='col-12 md:col-9'>
                                        <p>QR generado</p>
                                        <small>QR generado con éxito</small>
                                    </div>
                                    <div className='col-12 md:col-3'>

                                    </div>
                            </div> 
                        </div> : ''}
                        <div className='col-12 md:col-4'>

                        </div>
                    </div>
                    <div>

                    </div>
                </div>
                <div className='col-12 md:col-3'>
                    <Panel header="Resumen de compra">
                        <div className="grid">
                           <div className='col-12 md:col-12'>
                                <h5>Tipo de movimiento</h5>
                                <small>{previousData ? previousData.tipo_desc : ''}</small>
                            </div>
                            <div className='col-12 md:col-12'>
                                <h5>Sucursal</h5>
                                <small>{previousData ? previousData.suc_nom : ''}</small>
                            </div>
                            <div className='col-12 md:col-12'>
                                <h5>Ubicación</h5>
                                <small>{previousData ? `${previousData.sol_calle || ''} ${previousData.sol_num || ''} ${previousData.cp_col || ''} ${previousData.cp_cp || ''} ${previousData.cp_mun || ''} ${previousData.cp_edo || ''}` : ''}</small>
                            </div>
                            <div className='col-12 md:col-12'>
                                <h5>Material requerido</h5>
                                <VirtualScroller items={movementMaterials} itemSize={50} itemTemplate={itemTemplate} className="border-1 surface-border border-round w-full" style={{ width: '200px', height: '200px' }} />
                                <small className='mt-2'><i className='pi pi-info-circle'></i>Tiene hasta 72 horas para realizar su pago, si pasan de las horas señaladas se cancelará la cotización.</small>
                            </div>
                            <div className='col-12 md:col-6'>
                                <h5>Subtotal</h5>
                            </div>
                            <div className='col-12 md:col-6'>
                                <div className='flex justify-content-end pb-3'>
                                    <div>
                                        <p>${total.toFixed(2)}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 md:col-6'>
                                <h5>IVA</h5>
                            </div>
                            <div className='col-12 md:col-6'>
                                <div className='flex justify-content-end pb-3'>
                                    <div>
                                        <p>${iva.toFixed(2)}</p>
                                    </div>
                                </div>
                            </div>
                            {/* <div className='col-12 md:col-6'>
                                <h5>Impuestos</h5>
                            </div>
                            <div className='col-12 md:col-6'>
                                <div className='flex justify-content-end pb-3'>
                                    <div>
                                        <p>$1,000</p>
                                    </div>
                                </div>
                            </div> */}
                            <div className='col-12 md:col-12'>
                                <hr />
                            </div>
                            <div className='col-12 md:col-6'>
                                <h5>Total</h5>
                            </div>
                            <div className='col-12 md:col-6'>
                                <div className='flex justify-content-end pb-3'>
                                    <div>
                                        <p>${(total + iva).toFixed(2)}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 md:col-12'>
                                <div className='p-2'>
                                    <Button label='Ver factura' onClick={savePagoTransDepVent} />
                                </div>
                            </div>
                        </div>
                    </Panel>

                </div>
            </div>
        </section>
    );
}

export default React.memo(MovimientoResumen);
