import React, { useEffect, useRef, useState } from 'react';
import ApiServices from '../../../../service/apiServices';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { Tag } from 'primereact/tag';
import { Dialog } from 'primereact/dialog';
import { classNames } from 'primereact/utils';
import { Toast } from 'primereact/toast';

const CatMateriales = () => {
    let emptyCatalog = {
        mat_id: '',
        mat_desc: '',
        mat_precio: '',
        mat_status: '',
    };
    const [globalFilter, setGlobalFilter] = useState<any>(null);
    const navigate = useNavigate();
    const dt = useRef<any>(null);
    const [selectedProducts, setSelectedProducts] = useState<any>(null);
    const [materiales, setMateriales] = useState([]);
    const [material, setMaterial] = useState<any>(emptyCatalog);
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [addDialog, setAddDialog] = useState<boolean>(false);
    const toast = useRef<any>(null);
    const [deleteDialog, setDeleteDialog] = useState<boolean>(false);

    const getMateriales = async () => {
        ApiServices.apiPost('catMaterial', '').then(async res => {
            setMateriales(res.data.addenda)
            console.log(res.data.addenda)
        }).catch( error => {
            console.log(error)
        })
    }

    useEffect(() => {
        getMateriales();
    }, [])

    //Funcion para editar los campos
    const onInputChange = (e: any, name: string) => {
        const val = (e.target && e.target.value) || '';
        let _product = { ...material };
        _product[`${name}`] = val;
        setMaterial(_product);
    }

    //Abre el Modal para agregar el catalogo seleccionado
    const openNew = () => {
        setMaterial(emptyCatalog);
        setSubmitted(false);
        setAddDialog(true);
    }

    //Oculta el modal para agregar o editar el catalogo seleccionado
    const hideDialog = () => {
        setSubmitted(false);
        setAddDialog(false);
    }

    //Oculta el modal de eliminarel catalogo seleccionado
    const hideDeleteDialog = () => {
        setDeleteDialog(false);
    }

    //Abre el Modal para editar un catalogo seleccionado
    const editMaterial = (material: any) => {
        setMaterial({ ...material });
        setAddDialog(true);
    }

    //Abre el Modal para eliminar el catalogo seleccionado
    const confirmDeleteMaterial = (material: any) => {
        setMaterial(material);
        setDeleteDialog(true);
    }

    //Guardar/editar materiales
    const saveMaterial = async () =>{
        setSubmitted(true);

        if(material.mat_desc && material.mat_precio)
        {
            if(material.mat_id){
                let data = {
                    mat_id: material.mat_id,
                    mat_desc: material.mat_desc,
                    mat_precio: material.mat_precio,
                    mat_status: 1
                }

                ApiServices.apiPost('setMaterial', data).then((response) => {
                    if(response.data.valido == 1)
                    {
                        hideDialog();
                        toast.current.show({ severity: 'success', summary: 'Exito', detail: 'Material editado exitosamente', life: 3000 });
                        getMateriales();
                    }
                })
            }
            else {
                let data = {
                    mat_id: 0,
                    mat_desc: material.mat_desc,
                    mat_precio: material.mat_precio,
                    mat_status: 1
                }
        
                ApiServices.apiPost('setMaterial', data).then((response) => {
                    if(response.data.valido == 1)
                    {
                        hideDialog();
                        toast.current.show({ severity: 'success', summary: 'Exito', detail: 'Material agregado exitosamente', life: 3000 });
                        getMateriales();
                    }
                })
            }
        }
    }

    //Eliminar material
    const deleteMaterial= async () =>{
        if(material.mat_id)
        {
            let data = {
                mat_id: material.mat_id,
                mat_desc: material.mat_desc,
                mat_precio: material.mat_precio,
                mat_status: 0
            }

            ApiServices.apiPost('setMaterial', data).then((response) => {
                if(response.data.valido == 1)
                {
                    hideDeleteDialog();
                    toast.current.show({ severity: 'success', summary: 'Exito', detail: 'Material borrado exitosamente', life: 3000 });
                    getMateriales();
                }
            })
        }
    }

    /*DISEÑO */

    const codeBodyTemplate = (rowData: any) => {
        return (
            <>
                <span className="p-column-title">Code</span>
                {rowData.mat_id}
            </>
        );
    }

    const nameBodyTemplate = (rowData: any) => {
        return (
            <>
                <span className="p-column-title">Name</span>
                {rowData.mat_desc}
            </>
        );
    }


    const priceBodyTemplate = (rowData: any) => {
        return (
            <>
                <span className="p-column-title">Price</span>
                ${rowData.mat_precio}
            </>
        );
    }

    const actionBodyTemplate = (rowData: any) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-text" onClick={() => editMaterial(rowData)} />
                <Button icon="pi pi-trash" className='p-button-text p-button-danger' onClick={() => confirmDeleteMaterial(rowData)}/>
            </div>
        );
    }

    const AddFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Guardar" icon="pi pi-save" className="p-button-text" onClick={saveMaterial} />
        </>
    );

    const deleteFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDialog} />
            <Button label="Confirmar" icon="pi pi-check" className="p-button-text" onClick={deleteMaterial} />
        </>
    );

    const header = (
        <div className="grid">
            <div className="col-12 flex align-items-center justify-content-between">
                <div className='col-12 md:col-8'>
                    <h5 className="m-0">Catálogo de materiales</h5>
                    <p>Aquí podrás encontrar los materiales registrados, dar de alta nuevos materiales o eliminar.</p>
                </div>
                <div className="col-12 md:col-4 flex mt-1 md:mt-0 p-input-icon-left">
                    <span className="p-float-label p-input-icon-left w-100" style={{height:"auto", marginRight:"10px"}}>
                        <i className="pi pi-search" />
                        <InputText id="search" type="search" onInput={(e) => setGlobalFilter(e.currentTarget.value)} />
                        <label htmlFor="search">Buscador general</label>
                    </span>
                    <Button label="Agregar material" icon="pi pi-plus" iconPos="left" onClick={openNew}/>
                </div>
            </div>
        </div>
    );

    return (
        <section>
            <Toast ref={toast} />
            <DataTable ref={dt} value={materiales} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Mostrando {first} de {last} de un total de {totalRecords} entradas."
                globalFilter={globalFilter} emptyMessage="No se encuentran entradas." header={header} responsiveLayout="scroll">
                <Column field="mat_id" header="ID" sortable body={codeBodyTemplate}></Column>
                <Column field="mat_desc" header="Material" sortable body={nameBodyTemplate}></Column>
                <Column field="mat_precio" header="Precio por m³" sortable body={priceBodyTemplate}></Column>
                <Column body={actionBodyTemplate} headerStyle={{ minWidth: '1rem' }}></Column>
            </DataTable>

            <Dialog visible={addDialog} style={{ width: '30vw' }} header="Agregar materiales" modal className="p-fluid" footer={AddFooter} onHide={hideDialog}>
                <div className="field">
                    <label htmlFor="mat_desc">Material</label>
                    <InputText
                        id="mat_desc"
                        value={material.mat_desc}
                        onChange={(e) => onInputChange(e, 'mat_desc')}
                        tooltip="Ingresa material"
                        className={classNames({ 'p-invalid': submitted && !material.mat_desc })}
                    />
                    {submitted && !material.mat_desc && <small className="p-invalid" style={{color:'red'}}>Material obligatorio.</small>}
                </div>
                <div className="field">
                    <label htmlFor="mat_precio">Precio por m<sup>3</sup> </label>
                    <InputText
                        id="mat_precio"
                        value={material.mat_precio}
                        onChange={(e) => onInputChange(e, 'mat_precio')}
                        tooltip="Ingresa el precio"
                        className={classNames({ 'p-invalid': submitted && !material.mat_precio })}
                    />
                    {submitted && !material.mat_precio && <small className="p-invalid" style={{color:'red'}}>Precio obligatorio.</small>}
                </div>
            </Dialog>

            <Dialog visible={deleteDialog} style={{ width: '30vw' }} header="Eliminar" modal footer={deleteFooter} onHide={hideDeleteDialog}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {material && <span>¿Esta seguro que desea eliminar <b>{material.mat_desc}</b>?</span>}
                </div>
            </Dialog>
        </section>
    )
}

export default React.memo(CatMateriales);