import { RequestService } from "../utilities/request.component";

export default class ApiServices {
  static apiPost(url: any, data: any) {
    return RequestService.sendPostRequestNew(`apipost/${url}`, data);
  }

  static apiPostFile(url: any, file: any, json: any) {
    return RequestService.sendPostFileRequestNew(
      `apipostfile/${url}`,
      file,
      json
    );
  }

  static processRequestAdd(data: any) {
    return RequestService.sendPostRequestNew(`processrequest-add/`, {
      palabra: data,
    });
  }
}
