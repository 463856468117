import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { ReactChild, ReactFragment, ReactPortal } from "react"

export const Footer = () => {

  return (
      <div id='footer'>
        <div className='container text-center'>
          <p>
            <b>&copy; Todos los derechos reservados 2023</b>
          </p>
        </div>
      </div>
  )
}
