import { ReactChild, ReactFragment, ReactPortal } from "react";

export const About = (props: { data: { paragraph: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined; Why: any[]; Why2: any[]; }; }) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <div className="col-xs-12 col-md-12">
              {" "}
              <img src="images/landing/CR-3.png" className="img-responsive imagenaboutradius" alt="" loading="lazy" />
              {" "}
            </div>
            <div className="col-xs-12 col-md-6">
              {" "}
              <img src="images/landing/CR-4.png" className="img-responsive imagenabout" alt="" loading="lazy" />
              {" "}
            </div>
            <div className="col-xs-12 col-md-6">
              {" "}
              <img src="images/landing/CR-1.png" className="img-responsive imagenabout" alt="" loading="lazy" />
              {" "}
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>¿Quiénes somos?</h2>
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
