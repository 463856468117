import { Carousel } from "primereact/carousel";
import { ReactChild, ReactFragment, ReactPortal, useEffect, useState } from "react";

export const Header = (props: any) => {
  const [headerInformation, setHeaderInformation] = useState<any>([]);

  const responsiveOptions = [
    {
      breakpoint: '1400px',
      numVisible: 1,
      numScroll: 2
    },
    {
      breakpoint: '1199px',
      numVisible: 3,
      numScroll: 1
    },
    {
      breakpoint: '767px',
      numVisible: 2,
      numScroll: 1
    },
    {
      breakpoint: '575px',
      numVisible: 1,
      numScroll: 1
    }
  ];

  useEffect(() => {
    setHeaderInformation(props.data)
  }, []);

  const carouselTemplate = (information: any) => {
    return (
      <div className="intro" style={{background: `url(${require(`../../imgs/${information.image}`)})`, backgroundSize: 'cover'}}>
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 intro-text">
                <h1>
                  {information ? information.title : 'Lorem'}
                  <span></span>
                </h1>
                <p>{information ? information.paragraph : 'Lorem'}</p>
                <a
                  href='/login'
                  className="btn btn-success btn-lg page-scroll">Iniciar sesión</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <header id='header'>
      <Carousel
        value={headerInformation}
        numVisible={1}
        numScroll={2}
        className="custom-carousel"
        circular
        autoplayInterval={3000}
        itemTemplate={carouselTemplate}
        responsiveOptions={responsiveOptions}
      />
    </header>
  )
}
