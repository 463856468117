import { useNavigate } from 'react-router-dom';
import { Navigation } from '../../Components/Landing/navigation';
import { Header } from '../../Components/Landing/header';
import { Blog } from '../../Components/Landing/blog';
import { About } from '../../Components/Landing/about';
import { Services } from '../../Components/Landing/services';
import { Products } from '../../Components/Landing/products';
import { Norm } from '../../Components/Landing/norm';
import { Contact } from '../../Components/Landing/contact';
import { useEffect, useState } from 'react';
import JsonData from "../../Components/Landing/data.json";
import SmoothScroll from "smooth-scroll";
import { Faqs } from '../../Components/Landing/faqs';
import { Footer } from '../../Components/Landing/footer';

export const scroll = new SmoothScroll('a[href*="#"]', {
	speed: 1000,
	speedAsDuration: true,
});

export const Landing = (props: any) => {

	const navigate = useNavigate();

	return (
		<div>
			<link rel="stylesheet" type="text/css" href="css/bootstrap.css" />
			<link rel="stylesheet" type="text/css" href="fonts/font-awesome/css/font-awesome.css" />
			<link rel="stylesheet" type="text/css" href="css/style.css" />
			<link rel="stylesheet" type="text/css" href="css/nivo-lightbox/default.css" />

			<Navigation />
			<Header data={JsonData.Header} />
			<About data={JsonData.About} /> {/* Quienes Somos */}
			<Services data={JsonData.Services} /> {/* Servicios */}
			<Products data={JsonData.Testimonials} /> {/* Productos */}
			<Norm data={JsonData.Team} /> {/* Norma */}
			<Blog data={JsonData.Features} /> {/* Blog */}
			<Faqs data={JsonData.Features} /> {/* FAQ */}
			<Contact data={JsonData.Contact} />
			<Footer />
		</div>
	)
}