import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppMenu from './VerticalMenu/AppMenu';
import { classNames } from 'primereact/utils';
import { Avatar } from 'primereact/avatar';
import authGuardService from '../../../service/authGuardService';
import { CSSTransition } from 'react-transition-group';
import ApiServices from '../../../service/apiServices';
import EncryptService from '../../../service/encrypt.service';

const AppTopbar = (props: any) => {

    const [user, setUser] = useState<any>(null);

    useEffect(() => {
        setUser(authGuardService.getUser())
    }, []);

    const onTopbarSubItemClick = (event: any) => {
        navigateToProfile()
    }

	const navigate = useNavigate();

    const logout = () => {
        authGuardService.logout()
        navigate("/");
    }

    const navigateToProfile = () => {
        navigate("/perfil")
    }

    const navigateToProfileClient = () => {
        navigate("/perfil-cliente")
    }

    return (
        <>
            <div className="layout-topbar">
                <div className="layout-topbar-left">
                    <button className="topbar-menu-button p-link" onClick={props.onMenuButtonClick}>
                        <i className="pi pi-bars"></i>
                    </button>

                    <button className="p-link" onClick={() => navigate('/inicio')}>
                        <img className='logo' src={`/assets/img/logo-nav.svg`} alt="logo" />
                    </button>

                </div>

                <AppMenu model={props.items} menuMode={props.menuMode} colorScheme={props.colorScheme} menuActive={props.menuActive} activeInlineProfile={props.activeInlineProfile}
                    onSidebarMouseOver={props.onSidebarMouseOver} onSidebarMouseLeave={props.onSidebarMouseLeave} toggleMenu={props.onToggleMenu} onChangeActiveInlineMenu={props.onChangeActiveInlineMenu}
                    onMenuClick={props.onMenuClick} onRootMenuItemClick={props.onRootMenuItemClick} onMenuItemClick={props.onMenuItemClick} />

                <div className="layout-topbar-right">
                    <ul className="layout-topbar-right-items">
                        <li id="profile" className={classNames('profile-item', { 'active-topmenuitem': props.topbarMenuActive })}>
                            <button className="p-link p-button-lg" onClick={props.onTopbarItemClick}>
                                <i className="pi pi-fw pi-user"></i>
                            </button>
                            <ul className="fadeInDown">
                                <li role="menuitem">
                                    <button className="p-link" onClick={navigateToProfile}>
                                        <i className="pi pi-fw pi-user-edit"></i>
                                        <span>Perfil</span>
                                    </button>
                                </li>
                                <li role="menuitem">
                                    <button className="p-link" onClick={logout}>
                                        <i className="pi pi-fw pi-sign-out"></i>
                                        <span>Cerrar Sesión</span>
                                    </button>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <div className='nav-user px-3 mx-3'>
                                <h6>{user?.name ? user.name : ''}</h6>
                            </div>
                        </li>
                       
                        <li id="notification" className={classNames('notification-item', { 'active-topmenuitem': props.notificationMenuActive })}>
                            <button className="p-link" onClick={props.toggleNotificationMenu}>
                                <i className="topbar-icon pi pi-fw pi-bell"></i>
                                <span className="topbar-badge">{props.notifications.length}</span>
                                <span className="topbar-item-name">Notifications</span>
                            </button>
                            <CSSTransition classNames="p-toggleable-content" timeout={{ enter: 1000, exit: 450 }} in={props.notificationMenuActive} unmountOnExit>
                                <ul className="fadeInDown">
                                    {props.notifications.map((object: any, i: any) => <li role="menuitem">
                                        <button className="p-link">
                                            <i className="pi pi-fw pi-circle-fill"></i>
                                            <span>{object.not_mensaje}</span>
                                        </button>
                                    </li>)}
                                </ul>
                            </CSSTransition>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default AppTopbar;