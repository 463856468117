import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Panel } from 'primereact/panel';
import { Steps } from 'primereact/steps';
import { VirtualScroller, VirtualScrollerTemplateOptions } from 'primereact/virtualscroller';
import React, { useEffect, useRef, useState } from 'react';
// import ProductService from '../../serviceExample/ProductService';
import { useLocation, useNavigate } from 'react-router-dom';
import { classNames } from 'primereact/utils';
import { RadioButton } from 'primereact/radiobutton';
import { Dialog } from 'primereact/dialog';
import ApiServices from '../../../service/apiServices';
import authGuardService from '../../../service/authGuardService';
import { Calendar } from 'primereact/calendar';
import EncryptService from '../../../service/encrypt.service';
import { Checkbox } from 'primereact/checkbox';
import { Toast } from 'primereact/toast';
import { saveAs } from 'file-saver';
import { InputMask } from 'primereact/inputmask';

const MovimientoPago = () => {
    const navigate = useNavigate();
    const { usu_id } = authGuardService.getUser()
    const usu = authGuardService.getUser()

    const [cardNumber, setCardNumber] = useState<any>()
    const [cardExpirationDate, setCardExpirationDate] = useState<any>()
    const [cardCvv, setCardCvv] = useState<any>()

    // const [cardCvv, setCardCvv] = useState<any>()
    const [nameInCard, setNameInCard] = useState<any>("")
    const [phone, setPhone] = useState<any>()
    const [firstName, setFirstName] = useState<any>("")
    const [paternalSurName, setpaternalSurName] = useState<any>("")
    const [maternalSurName, setmaternalSurName] = useState<any>("")
    

    const [cardsList, setCardsList] = useState([])
    const [selectedCard, setSelectedCard] = useState<any>()

    const [showCardForm, setShowCardForm] = useState<boolean>(false)
    const clickShowForm = () => setShowCardForm(true)

    const timeLineSteps = [
        {
            label: 'Datos generales'
        },
        {
            label: 'Pago'
        },
        {
            label: 'Activado'
        }
    ]
    const location = useLocation();

    const movementData = location.state.data
    const movementMaterials = location.state.materials
    const previousData = location.state.previous
    const total = location.state.total
    const iva = location.state.iva

    const handleClickTimeLine = (e: any) => {
        if (e === 0) {
            navigate(-1)
        }
    }

    useEffect(() => {
        getCards()
        getMovementDetail();
    }, [])

    const getCards = async () => {
        let datos: any = {
            usu_id
        }
        datos = await EncryptService.encryptData(JSON.stringify(datos));

        ApiServices.apiPost('listTarjetas', {dataEnc: datos}).then(async res => {
            setCardsList(res.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }

    const setCard = async () => {
        const expirationDate = `${cardExpirationDate.getMonth() + 1}/${cardExpirationDate.getFullYear()}`

        let datos:any = {
            usu_id,
            trj_token: cardCvv,
            trj_term: expirationDate,
            trj_desc: cardNumber
        }
        datos = await EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.apiPost('addTarjeta', {dataEnc: datos}).then(async res => {
            if (res.data.valido === 1) {
                setCardsList(res.data.addenda)
                console.log('Toasty Tarjeta agregada con exito')
                setModalNewCardVisible(false)
            }
            console.log(res.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }

    const clearCard = () => {
        setCardNumber(null)
        setCardExpirationDate(null)
        setCardCvv(null)

        setModalNewCardVisible(false)
    }

    const [modalNewCardVisible, setModalNewCardVisible] = useState(false);
    const [modalValidateDatatoPaymentVisible, setmodalValidateDatatoPaymentVisible] = useState(false);


    const footerAddCardTemplate = (
        <div>
            <Button label="Cancelar" onClick={clearCard} autoFocus />
            <Button label="Agregar" onClick={setCard} autoFocus />
        </div>
    );



    const checkFields = () => {
        if (paymentType.tarjeta) {
            return true
        }
        else if (paymentType.transferencia || paymentType.deposito || paymentType.ventanilla) {
            return true
        }
    }

    const [loading, setLoading] = useState(false)

    const savePago = () => {
        const status = checkFields()
        if (status) {
            let data: any = {
                "code": 303,
                "sol_id": movementData.sol_id,
                // "trj_id": 0,
            }


            if (paymentType.tarjeta) {
                if(phone && firstName.trim() && paternalSurName.trim() && maternalSurName.trim() && cardNumber && cardCvv && cardExpirationDate){
                    const expirationDate = `${cardExpirationDate.getMonth() + 1}/${cardExpirationDate.getFullYear() % 100}`

                    data.pago_forma = 1;
                    data.card_number = cardNumber
                    data.sec_code = cardCvv
                    data.exp_month = '00'//expirationDate.split('/')[0].padStart(2, '0');
                    data.exp_year = '00'//expirationDate.split('/')[1];
                    data.cardholder_name = firstName +  ' ' + paternalSurName + ' ' + maternalSurName //NOMBRE QUE VIENE EN LA TARJETA
                    data.num_cel = phone //TELEFONO
                    data.first_name = firstName //NOMBRE(S)
                    data.paternal_surname = paternalSurName //APELLIDO PATERNO
                    data.maternal_surname = maternalSurName //APELLIDO MATERNO
                    data.token_card = ''
                    data.tipo_cargo = 1
                } else {
                    return toast.current.show({ severity:'info', summary: 'Error', detail: 'Debes de completar todos los datos' });
                }
            }
            else if (paymentType.transferencia) data.pago_forma = 2;
            else if (paymentType.deposito) data.pago_forma = 3;
            else if (paymentType.ventanilla) data.pago_forma = 4
            console.log(data)
            data = EncryptService.encryptData(JSON.stringify(data));
            setLoading(true)
            ApiServices.processRequestAdd(data)
                .then(async (res) => {
                    // console.log(res)
                    showSuccessToast()
                    if(paymentType.tarjeta){
                        setTimeout(() => {
                            navigate('/inicio')
                        }, 3500);
                    }
                    if(paymentType.transferencia) savePagoTransDepVent()
                    if(paymentType.deposito) savePagoTransDepVent()
                    if(paymentType.ventanilla) savePagoTransDepVent()
                    // setTimeout(() => {
                    //     navigate('/inicio')
                    //   }, 3500);
                })
                .catch((error) => {
                    console.log(error)
                    /* Toasty Error */
                });
        } else {
            console.log('Toasty Missing Fields')
        }

    }

    const savePagoTransDepVent = () => {
        let data: any

        if(paymentType.deposito){
            if(ingredient){
                data = {
                    "code": 305,
                    "sol_id": movementData.sol_id,
                    "pago_forma": paymentType.transferencia ? 2 : paymentType.deposito ? 3 : paymentType.ventanilla ? 4 : 0
                }
            } else {
                return toast.current.show({ severity:'info', summary: 'Selección de Banco/Tienda', detail: 'Debes de seleccionar una opción' });
            }
        } else {
            data = {
                "code": 305,
                "sol_id": movementData.sol_id,
                "pago_forma": paymentType.transferencia ? 2 : paymentType.deposito ? 3 : paymentType.ventanilla ? 4 : 0
            }
        }

        console.log(data)
        data = EncryptService.encryptData(JSON.stringify(data));
        ApiServices.processRequestAdd(data)
            .then(async (res) => {
                descargarQRgenerado(res)

            })
            .catch((error) => {
                console.log(error)
                /* Toasty Error */
            });
    }

    const descargarQRgenerado = (res: any) => {
        const imageSrc = res.data.url;
        saveAs(imageSrc, "ConcretosComprobante_"+ previousData.sol_id);
        toast.current.show({ severity:'success', summary: 'Completado', detail: res.data.mensaje });
        setTimeout(() => {
            navigate('/inicio')
        }, 3500);
    };

    const [detailData, setDetailData] = useState<any>()
    const [materialList, setMaterialList] = useState<any>([])



    const getMovementDetail = () => {
        let datos: any = {
            "code": 302,
            "sol_id": previousData.sol_id
        }

        datos = EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.processRequestAdd(datos)
            .then(async (res) => {
                setDetailData(res.data.solicitud)
                setMaterialList(res.data.materiales)
                /* if (res.data.solicitud.sol_status === 3) {
                    navigate('/movimiento/pago', { state: { previous: previousData, data: detailData, materials: materialList, total: total, iva: iva } })
                } */
            })
            .catch((error) => {
                /* Toasty Error */
            });
    }

    const footerAddCardPayTemplate = (
        <div>
            <Button label="Cancelar" onClick={clearCard} autoFocus />
            <Button label="Pagar" onClick={savePago} autoFocus />
        </div>
    );

    /* OLD */
    const [activeIndex, setActiveIndex] = useState(1);
    const items2 = [
        {
            label: 'Datos generales'
        },
        {
            label: 'Pago'
        },
        {
            label: 'Activado'
        },
    ];

    const toast = useRef<any>(null);

    const showSuccessToast = () => {
        toast.current.show({ severity:'success', summary: 'Completado', detail: 'Se ha ejecutado el pago correctamente' });
    };

    const [selectedCity, setSelectedCity] = useState(null);
    const cities = [
        { name: 'New York', code: 'NY' },
        { name: 'Rome', code: 'RM' },
        { name: 'London', code: 'LDN' },
        { name: 'Istanbul', code: 'IST' },
        { name: 'Paris', code: 'PRS' }
    ];

    const [items] = useState<string[]>(Array.from({ length: 200 }).map((_, i) => `Item #${i}`));

    const itemTemplate = (item: any, options: VirtualScrollerTemplateOptions) => {
        const className = classNames('flex align-items-center p-2', {
            'surface-hover': options.odd
        });

        return (
            <div className={className} style={{ height: options.props.itemSize + 'px' }}>
                {`${item.mat_desc} ${item.mat_volumen}`}<p>m<sup>3</sup></p>
            </div>
        );
    };

    const [ingredient, setIngredient] = useState('');
    const [nombre, setNombreValue] = useState('');
    const [RFC, setRFCValue] = useState('');
    const [direccion, setDireccionValue] = useState('');

    const [paymentType, setPaymentType] = useState({
        tarjeta: false,
        transferencia: false,
        deposito: false,
        ventanilla: false
    })

    const handleSelectPayment = (type: any, valueType: any) => {
        const clonePaymentType: any = paymentType

        for (const key in clonePaymentType) {
            if (clonePaymentType.hasOwnProperty(key)) {
                if (type === key) {
                    clonePaymentType[key] = valueType;
                } else {
                    clonePaymentType[key] = false
                }
            }
        }
        setPaymentType(clonePaymentType)
        setKey(Math.floor(Math.random() * 9) + 1)
    }

    const [key, setKey] = useState(0)


    const headerPaneltarjeta = (
        <div>
            <div className="flex align-items-center">
                <Checkbox onChange={(e) => handleSelectPayment('tarjeta', e.checked)} checked={paymentType.tarjeta}></Checkbox>
                <label htmlFor="ingredient1" className="ml-2">Pago con tarjeta</label>
            </div>
        </div>
    );

    const headerPaneltransferencia = (
        <div>
            <div className="flex align-items-center">
                <Checkbox onChange={(e) => handleSelectPayment('transferencia', e.checked)} checked={paymentType.transferencia}></Checkbox>
                <label htmlFor="ingredient1" className="ml-2">TRANSFERENCIA BANCARIA (SPEI)</label>
            </div>
        </div>
    );

    {/*const headerPanelDeposito = (
        <div>
            <div className="flex align-items-center">
                <Checkbox onChange={(e) => handleSelectPayment('deposito', e.checked)} checked={paymentType.deposito}></Checkbox>
                <label htmlFor="ingredient1" className="ml-2">DEPÓSITO EN BANCOS Y TIENDAS DE CONVENIENCIA</label>
            </div>
        </div>
    );*/}

    const headerPanelVentanilla = (
        <div>
            <div className="flex align-items-center">
                <Checkbox onChange={(e) => handleSelectPayment('ventanilla', e.checked)} checked={paymentType.ventanilla}></Checkbox>
                <label htmlFor="ingredient1" className="ml-2">PAGO EN VENTANILLA</label>
            </div>
        </div>
    );

    const cardOptionTemplate = (option: any) => {
        return (
            <div className='flex' style={{alignItems:'center'}}>
                {option && Array.from(option.trj_desc)[0] == '3' &&
                    <img src="/assets/img/amex.svg" width='24px' alt='visa' style={{marginRight: '20px'}}/>
                }
                {option && Array.from(option.trj_desc)[0] == '4' &&
                    <img src="/assets/img/visa.svg" width='24px' alt='visa' style={{marginRight: '20px'}}/>
                }
                {option && Array.from(option.trj_desc)[0] == '5' &&
                    <img src="/assets/img/mastercard.svg" width='24px' alt='visa' style={{marginRight: '20px'}}/>
                }
                <div>{option.trj_desc}</div>
            </div>
        );
    }

    const selectedCardTemplate = (option: any, props: any = '') => {
        if(option)
        {
            return (
                <div className='flex' style={{alignItems:'center'}}>
                {option && Array.from(option.trj_desc)[0] == '3' &&
                    <img src="/assets/img/amex.svg" width='24px' alt='visa' style={{marginRight: '20px'}}/>
                }
                {option && Array.from(option.trj_desc)[0] == '4' &&
                    <img src="/assets/img/visa.svg" width='24px' alt='visa' style={{marginRight: '20px'}}/>
                }
                {option && Array.from(option.trj_desc)[0] == '5' &&
                    <img src="/assets/img/mastercard.svg" width='24px' alt='visa' style={{marginRight: '20px'}}/>
                }
                <div>{option.trj_desc}</div>
            </div>
            );
        }

        return (
            <span>{props.placeholder}</span>
        )
    }

    return (
        <section>
            <Toast ref={toast} />
            <div className="grid">

                <div className='col-12 md:col-8'>
                    <h5 className="m-0">Formas de pago</h5>
                    <p>Selecciona tu forma de pago.</p>
                </div>
                <div className='col-12 md:col-4'>
                    <Steps
                        model={timeLineSteps}
                        activeIndex={1}
                        onSelect={(e) => handleClickTimeLine(e.index)}
                        readOnly={true}
                    />
                </div>
                <div className='col-12 md:col-9'>
                    <div className="grid">
                        <div className='col-12 md:col-12'>
                            <Panel header={headerPaneltarjeta} toggleable collapsed={key === 0 ? false : !paymentType.tarjeta} key={key}>
                                <div className="grid">
                                    {cardsList && cardsList.length > 0 && 
                                        <div className="grid col-12">
                                            <div className='col-12 md:col-4'>
                                                <span className="p-float-label">
                                                    <Dropdown
                                                        inputId='ddCard'
                                                        value={selectedCard}
                                                        onChange={(e) => setSelectedCard(e.value)}
                                                        options={cardsList}
                                                        optionLabel="trj_desc"
                                                        className="w-full"
                                                        itemTemplate={cardOptionTemplate}
                                                        valueTemplate={selectedCardTemplate}
                                                        placeholder='Selecciona una tarjeta'
                                                        
                                                    />
                                                    <label htmlFor='ddCard'>Selecciona una tarjeta</label>
                                                </span>
                                            </div>
                                            <div className='col-12 md:col-2'>
                                                {/*<Button onClick={() => setModalNewCardVisible(true)} label="Nueva tarjeta" />*/}
                                                <Button label='Nueva tarjeta' onClick={clickShowForm} />
                                            </div>
                                        </div>
                                    }

                                    {cardsList && cardsList.length == 0 || showCardForm && 
                                    <div className='col-12 grid'>
                                    <div className='col-12 md:col-6'>
                                        <span className="p-float-label">
                                            <InputText
                                                keyfilter='int'
                                                value={cardNumber}
                                                onChange={(e) => setCardNumber(e.target.value)}
                                                max={16}
                                                maxLength={16}
                                            />
                                            <label htmlFor="username">Numero de tarjeta</label>
                                        </span>
                                    </div>
                                    <div className='col-12 md:col-3'>
                                        <span className="p-float-label">
                                            <Calendar
                                                value={cardExpirationDate}
                                                onChange={(e) => setCardExpirationDate(e.value)}
                                                view="month"
                                                dateFormat="mm/yy"
                                                style={{ width: '100%' }}
                                            />
                                            <label htmlFor="username">Fecha de caducidad</label>
                                        </span>
                                    </div>
                                    <div className='col-12 md:col-3'>
                                        <span className="p-float-label">
                                            <InputText
                                                keyfilter='int'
                                                value={cardCvv}
                                                onChange={(e) => setCardCvv(e.target.value)}
                                                max={3}
                                                maxLength={3}
                                            />
                                            <label htmlFor="username">CCV</label>
                                        </span>
                                    </div>
                                    <div className='col-12 md:col-6'>
                                        <span className="p-float-label">
                                        {/* <InputMask
                                            id="phone"
                                            value={phone}
                                            onChange={(e) => {
                                                setPhone(e.target.value);
                                            }}
                                            mask="(999) 999-9999"
                                        ></InputMask> */}
                                            <InputText
                                                keyfilter='int'
                                                value={phone}
                                                onChange={(e) => {
                                                    setPhone(e.target.value);
                                                }}
                                                max={10}
                                                maxLength={10}
                                            />
                                            <label htmlFor="username">Número de teléfono</label>
                                        </span>
                                    </div>
                                    <div className='col-12 md:col-6'>
                                        <span className="p-float-label">
                                            <InputText
                                                value={firstName}
                                                onChange={(e) => setFirstName(e.target.value)}
                                            />
                                            <label htmlFor="username">Nombre(s)</label>
                                        </span>
                                    </div>
                                    <div className='col-12 md:col-4'>
                                        <span className="p-float-label">
                                            <InputText
                                                keyfilter="alpha"
                                                value={paternalSurName}
                                                onChange={(e) => setpaternalSurName(e.target.value)}
                                            />
                                            <label htmlFor="username">Apellido Paterno</label>
                                        </span>
                                    </div>
                                    <div className='col-12 md:col-4'>
                                        <span className="p-float-label">
                                            <InputText
                                                keyfilter="alpha"
                                                value={maternalSurName}
                                                onChange={(e) => setmaternalSurName(e.target.value)}
                                            />
                                            <label htmlFor="username">Apellido Materno</label>
                                        </span>
                                    </div>
                                    {showCardForm &&
                                        <div className="col-12" style={{display:'flex', justifyContent: 'end'}}>
                                            <Button label='Guardar' style={{width: '15%'}} />
                                        </div>
                                    }
                                    </div>
                                    }
                                </div>
                            </Panel>
                        </div>
                        <div className='col-12 md:col-12'>
                            <Panel header={headerPaneltransferencia} toggleable collapsed={key === 0 ? false : !paymentType.transferencia} key={key}>
                                <div className="grid">
                                    <div className='col-12 md:col-4'>
                                        <p>Banco</p>
                                        <small>Bancomer</small>
                                    </div>
                                    <div className='col-12 md:col-4'>
                                        <p>Cuenta bancaria</p>
                                        <small>03398493574563</small>
                                    </div>
                                    <div className='col-12 md:col-4'>
                                        <p>Referencia</p>
                                        <small>V858003</small>
                                    </div>
                                    <div className='col-12 md:col-12'>
                                        <small>*Para poder seguir con el registro de vehículos, tendrá que esperar a que el equipo de administrador de Concreto valide el pago.</small>
                                    </div>
                                </div>
                            </Panel>
                        </div>
                        {/*<div className='col-12 md:col-12'>
                            <Panel header={headerPanelDeposito} toggleable collapsed={key === 0 ? false : !paymentType.deposito} key={key}>
                                <div className="grid">
                                    <div className='col-12 md:col-8 centered-radio'>
                                        <RadioButton inputId="ingredient1" name="1" value="1" onChange={(e) => setIngredient(e.value)} checked={ingredient === '1'} />
                                        <img src="/images/options/option1.png" width={300} />
                                    </div>
                                    {/*<div className='col-12 md:col-4 centered-radio'>
                                        <RadioButton inputId="ingredient1" name="2" value="2" onChange={(e) => setIngredient(e.value)} checked={ingredient === '2'} />
                                        <img src="/images/options/option2.png" width={200} />
                                    </div>
                                    <div className='col-12 md:col-4 centered-radio'>
                                        <RadioButton inputId="ingredient1" name="3" value="3" onChange={(e) => setIngredient(e.value)} checked={ingredient === '3'} />
                                        <img src="/images/options/option3.png" width={300} />
                                    </div>
                                    <div className='col-12'>
                                        <p>Luego de hacer clic en “PAGAR”, te daremos las instrucciones para completar tu compra de forma segura.</p>
                                    </div>
                                </div>
                            </Panel>
                        </div>*/}
                        <div className='col-12 md:col-12'>
                            <Panel header={headerPanelVentanilla} toggleable collapsed={key === 0 ? false : !paymentType.ventanilla} key={key}>
                                <div className="grid">
                                    <div className='col-12 md:col-12'>
                                        <p>Si su pago es por medio de ventanilla, por favor, siga al paso de registro de vehículos</p>
                                    </div>
                                </div>
                            </Panel>
                        </div>
                        <div className='col-12 md:col-12'>
                            <Panel header="¿Desea facturar? Si desea facturar, por favor llene los siguientes datos">
                                <div className="grid">
                                    <div className='col-12 md:col-6'>
                                        <span className="p-float-label">
                                            <InputText value={nombre} onChange={(e) => setNombreValue(e.target.value)} />
                                            <label htmlFor="username">Nombre del contribuyente</label>
                                        </span>
                                    </div>
                                    <div className='col-12 md:col-6'>
                                        <span className="p-float-label">
                                            <InputText value={RFC} onChange={(e) => setRFCValue(e.target.value)} />
                                            <label htmlFor="username">RFC</label>
                                        </span>
                                    </div>
                                    <div className='col-12 md:col-12'>
                                        <span className="p-float-label">
                                            <InputText value={direccion} onChange={(e) => setDireccionValue(e.target.value)} />
                                            <label htmlFor="username">Dirección</label>
                                        </span>
                                    </div>
                                </div>
                            </Panel>
                        </div>
                    </div>
                </div>

                <div className='col-12 md:col-3'>
                    <Panel header="Resumen de compra">
                        <div className="grid">
                            <div className='col-12 md:col-12'>
                                <h5>Tipo de movimiento</h5>
                                <small>{movementData ? movementData.tipo_desc : ''}</small>
                            </div>
                            <div className='col-12 md:col-12'>
                                <h5>Sucursal</h5>
                                <small>{movementData ? movementData.suc_nom : ''}</small>
                            </div>
                            <div className='col-12 md:col-12'>
                                <h5>Ubicación</h5>
                                <small>{movementData ? `${movementData.sol_calle || ''} ${movementData.sol_num || ''} ${movementData.cp_col || ''} ${movementData.cp_cp || ''} ${movementData.cp_mun || ''} ${movementData.cp_edo || ''}` : ''}</small>
                            </div>
                            <div className='col-12 md:col-12'>
                                <h5>Material requerido</h5>
                                <VirtualScroller
                                    items={movementMaterials}
                                    itemSize={50}
                                    itemTemplate={itemTemplate}
                                    className="border-1 surface-border border-round w-full"
                                    style={{ height: '15vh', marginBottom: 15 }}
                                />
                                <small className='mt-2'><i className='pi pi-info-circle' style={{ color: '#F2C95F' }}></i> Tiene hasta 72 horas para realizar su pago, si pasan de las horas señaladas se cancelará la cotización.</small>
                            </div>
                            <div className='col-12 md:col-6'>
                                <h5>Subtotal</h5>
                            </div>
                            <div className='col-12 md:col-6'>
                                <div className='flex justify-content-end pb-3'>
                                    <div>
                                        <p>${(total).toFixed(2)}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 md:col-6'>
                                <h5>IVA</h5>
                            </div>
                            <div className='col-12 md:col-6'>
                                <div className='flex justify-content-end pb-3'>
                                    <div>
                                        <p>${(iva).toFixed(2)}</p>
                                    </div>
                                </div>
                            </div>
                            {/* <div className='col-12 md:col-6'>
                                <h5>Impuestos</h5>
                            </div>
                            <div className='col-12 md:col-6'>
                                <div className='flex justify-content-end pb-3'>
                                    <div>
                                        <p>$1,000</p>
                                    </div>
                                </div>
                            </div> */}
                            <div className='col-12 md:col-12'>
                                <hr />
                            </div>
                            <div className='col-12 md:col-6'>
                                <h5>Total</h5>
                            </div>
                            <div className='col-12 md:col-6'>
                                <div className='flex justify-content-end pb-3'>
                                    <div>
                                        <p>${(total + iva).toFixed(2)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Panel>
                    <div className='col-12 md:col-12'>
                        {paymentType.tarjeta &&
                            <>
                                <div className='p-2'>
                                    {/* <Button label='PAGAR' onClick={() => navigate('/movimiento/resumen', { state: { data: previousData } })} /> */}
                                    <Button label='PAGAR' onClick={() => savePago()} /> 
                                    {/* savePago <--FUNCTION */}
                                </div>
                                {/* <div className='p-2'>
                                    <Button label='Descargar comprobante' />
                                </div> */}
                            </>
                        }
                        {paymentType.transferencia &&
                            <>
                                <div className='p-2'>
                                    {/* <Button label='PAGAR' onClick={() => navigate('/movimiento/resumen', { state: { data: previousData } })} /> */}
                                    <Button label='Descargar referencia' onClick={() => savePago()} />
                                </div>
                                {/* <div className='p-2'>
                                    <Button label='Descargar comprobante' />
                                </div> */}
                            </>
                        }
                        {paymentType.deposito &&
                            <div className='p-2'>
                                <Button label='Descargar PDF' onClick={() => savePago()} />
                            </div>
                        }
                        {paymentType.ventanilla &&
                            <div className='p-2'>
                                <Button label='Descargar PDF' onClick={savePago} />
                            </div>
                        }
                    </div>
                </div>
            </div>

            <Dialog
                header="Nueva tarjeta"
                visible={modalNewCardVisible}
                style={{ width: '25vw' }}
                onHide={() => setModalNewCardVisible(false)}
                footer={footerAddCardTemplate}
                draggable={false}
                resizable={false}
            >
                <div className="grid" style={{ margin: 10 }}>
                    {/* <div className='col-12 md:col-12'>
                        <Dropdown value={selectedCity} onChange={(e) => setSelectedCity(e.value)} options={cities} optionLabel="name" placeholder="Selecciona una tarjeta" className="w-full" />
                    </div> */}
                    <div className='col-12 md:col-12'>
                        <span className="p-float-label">
                            <InputText
                                keyfilter='int'
                                value={cardNumber}
                                onChange={(e) => setCardNumber(e.target.value)}
                                max={16}
                                maxLength={16}
                            />
                            <label htmlFor="username">Numero de tarjeta</label>
                        </span>
                    </div>
                    <div className='col-12 md:col-12'>
                        <span className="p-float-label">
                            <Calendar
                                value={cardExpirationDate}
                                onChange={(e) => setCardExpirationDate(e.value)}
                                view="month"
                                dateFormat="mm/yy"
                                style={{ width: '100%' }}
                            />
                            <label htmlFor="username">Fecha de caducidad</label>
                        </span>
                    </div>
                    <div className='col-12 md:col-12'>
                        <span className="p-float-label">
                            <InputText
                                keyfilter='int'
                                value={cardCvv}
                                onChange={(e) => setCardCvv(e.target.value)}
                                max={3}
                                maxLength={3}
                            />
                            <label htmlFor="username">CCV</label>
                        </span>
                    </div>
                </div>
            </Dialog>

                    {/* CAPTURA DE DATOS DE TARJETA */}
            <Dialog
                header="PAGO CON TARJETA"
                visible={modalValidateDatatoPaymentVisible}
                style={{ width: '25vw' }}
                onHide={() => setmodalValidateDatatoPaymentVisible(false)}
                footer={footerAddCardPayTemplate}
                draggable={false}
                resizable={false}
            >
                <div className="grid" style={{ margin: 10 }}>
                    <div className='col-12 md:col-6'>
                        <p>Tarjeta seleccionada: {selectedCard && selectedCard.trj_desc}. Expiración: {selectedCard && selectedCard.trj_term}.</p>
                    </div>
                    <div className='col-12 md:col-6'>
                        <span className="p-float-label">
                            <InputText
                                keyfilter='int'
                                value={cardCvv}
                                onChange={(e) => setCardCvv(e.target.value)}
                                max={3}
                                maxLength={3}
                            />
                            <label htmlFor="username">CCV</label>
                        </span>
                    </div>
                    <div className='col-12 md:col-12'>
                        <span className="p-float-label">
                            <InputText
                                value={nameInCard}
                                onChange={(e) => setNameInCard(e.target.value)}
                            />
                            <label htmlFor="username">Nombre del dueño de la tarjeta</label>
                        </span>
                    </div>
                    <div className='col-12 md:col-12'>
                        <span className="p-float-label">
                        <InputMask
                            id="phone"
                            value={phone}
                            onChange={(e) => {
                                setPhone(e.target.value);
                            }}
                            mask="(999) 999-9999"
                            placeholder="(999) 999-9999"
                        ></InputMask>
                            <label htmlFor="username">Número de teléfono</label>
                        </span>
                    </div>
                    {/* <div className='col-12 md:col-12'>
                        <span className="p-float-label">
                            <InputText
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                            <label htmlFor="username">Nombre(s)</label>
                        </span>
                    </div>
                    <div className='col-12 md:col-6'>
                        <span className="p-float-label">
                            <InputText
                                value={paternalSurName}
                                onChange={(e) => setpaternalSurName(e.target.value)}
                            />
                            <label htmlFor="username">Apellido Paterno</label>
                        </span>
                    </div>
                    <div className='col-12 md:col-6'>
                        <span className="p-float-label">
                            <InputText
                                value={maternalSurName}
                                onChange={(e) => setmaternalSurName(e.target.value)}
                            />
                            <label htmlFor="username">Apellido Materno</label>
                        </span>
                    </div> */}
                </div>
            </Dialog>
        </section >
    );
}

export default React.memo(MovimientoPago);
