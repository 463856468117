import GoogleMapReact from 'google-map-react';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Panel } from 'primereact/panel';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CompanyMarker from '../../../Components/Markers/CompanyMarker/CompanyMarker';
import UserMarker from '../../../Components/Markers/UserMarker/UserMarker';
import ApiServices from '../../../service/apiServices';
import authGuardService from '../../../service/authGuardService';
import EncryptService from '../../../service/encrypt.service';
import { Toast } from 'primereact/toast';
import MapEx from '../../../Components/Map/Map';

const MovimientoCompra = () => {

    const user = authGuardService.getUser()
    const toast = useRef<any>(null);
    const navigate = useNavigate();
    const cubicMetersRef = useRef<any>(null)
    const defaultProps = {
        center: {
            lat: 19.431570552318778,
            lng: -99.14056662025806
        },
        zoom: 12
    }

    const [date, setDate] = useState<any>(new Date())
    const [street, setStreet] = useState<any>({ value: '', valid: true })
    const [placeNumber, setPlaceNumber] = useState<any>({ value: '', valid: true })
    const [avenue, setAvenue] = useState<any>({ value: '', valid: true })
    const [postalCode, setPostalCode] = useState<any>({ value: '', valid: true })
    const [colonyList, setColonyList] = useState<any>()
    const [selectedColony, setSelectedColony] = useState<any>({ value: null, valid: true })
    const [cubicMeters, setCubicMeters] = useState<any>()
    const [selectedMaterialList, setSelectedMaterialList] = useState<any>([])
    const [materialList, setMaterialList] = useState<any>([])
    const [selectedMaterial, setSelectedMaterial] = useState<any>()
    const [currentLocation, setCurrentLocation] = useState({
        center: {
            lat: 19.431570552318778,
            lng: -99.14056662025806
        },
        zoom: 16
    })
    const [companyLocations, setCompanyLocations] = useState([])
    const [selectedCompanyLocation, setSelectedCompanyLocation] = useState<any>()
    const [apiResponse, setApiResponse] = useState<any>()
    const [visible, setVisible] = useState(false);

    const addToMaterialList = () => {
        if (selectedMaterial && cubicMeters) {
            const arrayClone = structuredClone(selectedMaterialList)
            const jsonToAdd = {
                id: selectedMaterial.mat_id,
                material: selectedMaterial.mat_desc,
                volume: cubicMeters
            }
            arrayClone.push(jsonToAdd)
            setSelectedMaterialList(arrayClone)
            setSelectedMaterial(null)
            setCubicMeters(null)
            cubicMetersRef.current.value = ''
        }
    }

    const deleteFromMaterialList = (rowToDelete: any) => {
        const arrayClone = structuredClone(selectedMaterialList)
        const newArray = arrayClone.filter((jsonSearch: any) => !(JSON.stringify(jsonSearch) === JSON.stringify(rowToDelete)));
        setSelectedMaterialList(newArray)
    }

    const deleteFromTableTemplate = (rowData: any) => {
        return (
            <div className="actions">
                <Button icon="pi pi-trash" className="p-button-text" onClick={() => deleteFromMaterialList(rowData)} />
            </div>
        );
    }

    useEffect(() => {
        if ("geolocation" in navigator) {
            askLocation()
        } else {
            getStoreLocation(defaultProps.center.lat, defaultProps.center.lng)
        }
    }, [])

    useEffect(() => {
        getMaterialList()
        getStoreLocation()
    }, [])

    const getMaterialList = () => {
        let datos
        ApiServices.apiPost('catMaterial', datos).then(async res => {
            setMaterialList(res.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }

    const askLocation = () => {
        navigator.geolocation.getCurrentPosition(
            function (position) {
                setCurrentLocation({
                    center: {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    },
                    zoom: 16
                })
                console.log(position)
                getStoreLocation(position.coords.latitude, position.coords.longitude)
            },
            function (error) {
                console.log(error)
                getStoreLocation(defaultProps.center.lat, defaultProps.center.lng)
            }
        );
    }

    const getStoreLocation = async (lat = defaultProps.center.lat, lng = defaultProps.center.lng) => {
        let datos:any = {
            suc_lat: lat,
            suc_lng: lng
        }
        datos = await EncryptService.encryptData(JSON.stringify(datos));

        ApiServices.apiPost('getSucursales', {dataEnc: datos}).then(async res => {
            formatStoresLocations(res.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }

    const formatStoresLocations = (locations: any) => {
        if (locations && locations.length > 0) {
            const newLocations = locations.map((singleLocation: any, index: any) => {
                const newSingleLocation = structuredClone(singleLocation)
                if (index === 0) {
                    newSingleLocation.selected = true
                    setSelectedCompanyLocation(newSingleLocation)
                }
                else {
                    newSingleLocation.selected = false
                }
                return newSingleLocation
            })
            setCompanyLocations(newLocations)
        }
    }

    const handleCPChange = async (cp: any) => {
        setValues(setPostalCode, cp)
        if (cp.length === 5) {
            let datos:any = { cp_cp: cp }
            datos = await EncryptService.encryptData(JSON.stringify(datos));

            ApiServices.apiPost('catCP', {dataEnc: datos}).then(async res => {
                if (res.data.addenda && res.data.addenda.length > 0) {
                    setColonyList(res.data.addenda)
                    setSelectedColony({ value: res.data.addenda[0], valid: true })
                }
            }).catch(error => {
                console.log(error)
            })
        }
    }

    const handleCheckLocation = (e: any, selectedIndex: any) => {
        if (companyLocations && companyLocations.length > 0) {
            const newArray = structuredClone(companyLocations)
            const newArrayModified: any = newArray.map((singleLocation: any, index) => {
                if (index === selectedIndex) {
                    singleLocation.selected = true
                    setSelectedCompanyLocation(singleLocation)
                }
                else {
                    singleLocation.selected = false
                }
                return singleLocation
            })
            setCompanyLocations(newArrayModified)
        }
    }

    const setValues = (addFunction: any, value: any) => {
        addFunction({ value: value, valid: true })
    }

    const checkValues = () => {
        setStreet({ value: street.value, valid: street.value && street.value.length > 1 })
        setPlaceNumber({ value: placeNumber.value, valid: placeNumber.value && placeNumber.value.length > 1 })
        setAvenue({ value: avenue.value, valid: avenue.value && avenue.value.length > 1 })
        setPostalCode({ value: postalCode.value, valid: postalCode.value && postalCode.value.length === 5 })
        setSelectedColony({ value: selectedColony.value, valid: selectedColony && selectedColony.value && selectedColony.value.cp_id })
        return (
            (street.value && street.value.length > 1) &&
            (placeNumber.value && placeNumber.value.length > 1) &&
            (postalCode.value && postalCode.value.length === 5) &&
            (selectedColony && selectedColony.value && selectedColony.value.cp_id) &&
            (selectedMaterialList.length > 0)
        )
    }

    const handleAdd = () => {
        const allValid = checkValues()
        if (allValid) {
            const materials = selectedMaterialList.map(({ id, volume }: any) => {
                return {
                    "mat_id": id,
                    "mat_volumen": volume
                }
            })
            if(materials.length == 0) {
                toast.current.show({
                    severity: "error",
                    summary: "Error",
                    detail: "Se necesitan materiales.",
                    life: 4000,
                });
                return
            }
            let datos: any = {
                "code": 301,
                "usu_id": user.usu_id,
                "tipo_id": 1,
                "sol_calle": street.value,
                "sol_num": placeNumber.value,
                "cp_id": selectedColony.value.cp_id,
                "sol_lat": currentLocation.center.lat,
                "sol_lng": currentLocation.center.lng,
                "suc_id": selectedCompanyLocation.suc_id,
                "materiales": materials,
                "mov_ini": "",
                "mov_fin": ""
            }
            datos = EncryptService.encryptData(JSON.stringify(datos));
            ApiServices.processRequestAdd(datos)
                .then(async (res) => {
                    if (res.status === 200 && res.data.mensaje === 'Solicitud agregada correctamente') {
                        setApiResponse(res.data)
                        toast.current.show({
                            severity: "success",
                            summary: "Movimiento Agregado",
                            detail: "Tu movimiento fue agregado con exito.",
                            life: 4000,
                        });
                        setVisible(true)
                    } else {
                        toast.current.show({
                            severity: "error",
                            summary: "Error",
                            detail: "Intentelo de Nuevo mas tarde.",
                            life: 4000,
                        });
                    }
                })
                .catch((error) => {
                    toast.current.show({
                        severity: "error",
                        summary: "Error",
                        detail: "Intentelo de Nuevo mas tarde.",
                        life: 4000,
                    });
                });
        } else {
            if (selectedMaterialList.length > 0) {
                toast.current.show({
                    severity: "error",
                    summary: "Campos faltantes",
                    detail: "Rellene todos los campos para continuar.",
                    life: 4000,
                });
            } else {
                toast.current.show({
                    severity: "error",
                    summary: "Campos faltantes",
                    detail: "Rellene todos los campos para continuar y agrega materiales a la lista",
                    life: 4000,
                });
            }
        }
    }

    const footerContent = (
        <div>
            <Button label="Terminar" icon="pi pi-check" onClick={() => {
                setVisible(false)
                navigate('/inicio')
            }} autoFocus />
        </div>
    );

    return (
        <section>
            <Toast ref={toast} />

            <div className="grid">
                <div className='col-12 md:col-12'>
                    <h5 className="m-0">Nuevo movimiento - Compra</h5>
                    <p>Selecciona los siguientes datos para iniciar un nuevo movimiento.</p>
                </div>
                <div className='col-12 md:col-4'>
                    <span className="p-float-label">
                        <Calendar
                            inputId="date"
                            showIcon
                            className="w-full"
                            locale="es"
                            dateFormat="dd/MM/yy"
                            value={date}
                            onChange={(e) => { setDate(e.target.value) }}
                            minDate={new Date()}
                        />
                        <label htmlFor="date">Fecha</label>
                    </span>
                </div>
                <div className='col-12 md:col-12'>
                    <p>Ubicación</p>
                </div>
                <div className='col-12 md:col-4'>
                    <span className="p-float-label w-100">
                        <InputText
                            value={street.value}
                            onChange={(e) => setValues(setStreet, e.target.value)}
                            className={`${!street.valid ? 'p-invalid' : ''}`}
                        />
                        <label htmlFor="username">Calle</label>
                    </span>
                </div>
                <div className='col-12 md:col-4'>
                    <span className="p-float-label w-100">
                        <InputText
                            value={placeNumber.value}
                            onChange={(e) => setValues(setPlaceNumber, e.target.value)}
                            className={`${!placeNumber.valid ? 'p-invalid' : ''}`}
                        />
                        <label htmlFor="username">Número</label>
                    </span>
                </div>
                {/* <div className='col-12 md:col-4'>
                    <span className="p-float-label w-100">
                        <InputText
                            value={avenue.value}
                            onChange={(e) => setValues(setAvenue, e.target.value)}
                            className={`${!avenue.valid ? 'p-invalid' : ''}`}
                        />
                        <label htmlFor="username">Avenida</label>
                    </span>
                </div> */}
                <div className='col-12 md:col-4'>
                    <span className="p-float-label w-100">
                        <InputText
                            value={postalCode.value}
                            onChange={(e) => handleCPChange(e.target.value)}
                            className={`${!postalCode.valid ? 'p-invalid' : ''}`}
                            maxLength={5}
                        />
                        <label htmlFor="username">C.P.</label>
                    </span>
                </div>
                <div className='col-12 md:col-4'>
                    <span className="p-float-label w-100">
                        <Dropdown
                            value={selectedColony.value}
                            onChange={(e) => {
                                setValues(setSelectedColony, e.value)
                                setCurrentLocation(currentLocation)
                            }}
                            options={colonyList}
                            optionLabel='cp_col'
                            placeholder='Selecciona tu colonia'
                            className={`${!selectedColony.valid ? 'p-invalid' : ''} w-full`}
                        />
                    </span>
                </div>
            </div>

            <Panel header="Agrega los materiales necesarios" style={{ marginTop: 40 }}>
                <div className="grid">
                    <div className='col-12 md:col-4'>
                        <span className="p-float-label w-100">
                            <Dropdown
                                value={selectedMaterial}
                                onChange={(e) => setSelectedMaterial(e.value)}
                                options={materialList}
                                optionLabel="mat_desc"
                                placeholder="Tipo de material"
                                className="w-full"
                            />
                        </span>
                    </div>
                    <div className='col-12 md:col-4'>
                        <span className="p-float-label w-100">
                            <InputText
                                keyfilter="num"
                                ref={cubicMetersRef}
                                value={cubicMeters}
                                onChange={(e) => setCubicMeters(e.target.value)}
                            />
                            <label htmlFor="username">Volumen m3</label>
                        </span>
                    </div>
                    <div className='col-12 md:col-4'>
                        <div className='flex justify-content-end'>
                            <div style={{ width: '50%' }}>
                                <Button
                                    label="Agregar materiales "
                                    onClick={addToMaterialList}
                                    disabled={!(selectedMaterial && cubicMeters)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='col-12 md:col-12'>
                        <DataTable
                            value={selectedMaterialList}
                            dataKey="id"
                            paginator={selectedMaterialList && selectedMaterialList.length > 4}
                            rows={5}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                            emptyMessage="Ingresa materiales."
                        >
                            <Column field="id" header="ID" sortable ></Column>
                            <Column field="material" header="Material" sortable ></Column>
                            <Column field="volume" header="Volumen" sortable ></Column>
                            <Column body={deleteFromTableTemplate} headerStyle={{ minWidth: '1rem' }}></Column>
                        </DataTable>
                    </div>
                </div>
            </Panel>

            <Panel header="SELECCIONA LA SUCURSAL MÁS CERCANA A TU UBICACIÓN" style={{ marginTop: 40 }} toggleable>
                <div className="grid">
                    <div className='col-12 md:col-2'>
                        <p>La más recomendada</p>
                    </div>
                    <div className='col-12 md:col-10'>
                        <p>Otras Sugerencias</p>
                    </div>
                    {companyLocations && companyLocations.map((singleLocation: any, index) => {
                        if (index === 0) {
                            return (
                                <div className='col-12 md:col-2'>
                                    <div className="flex align-items-center">
                                        <Checkbox inputId="location" checked={singleLocation.selected} onChange={(e) => { handleCheckLocation(e, index) }} />
                                        <label htmlFor="location" className="ml-2">{singleLocation.suc_nom}</label>
                                    </div>
                                </div>
                            )
                        } else {
                            return (
                                <div className='col-12 md:col-1'>
                                    <div className="flex align-items-center">
                                        <Checkbox inputId="location" checked={singleLocation.selected} onChange={(e) => { handleCheckLocation(e, index) }} />
                                        <label htmlFor="location" className="ml-2">{singleLocation.suc_nom}</label>
                                    </div>
                                </div>
                            )
                        }
                    })}
                </div>
                <div style={{ height: '50vh', width: '100%', marginTop: 20 }}>
                    <MapEx 
                        currentLocation={currentLocation}
                        companyLocations={companyLocations}
                    />
                </div>
            </Panel>

            <div className='col-12 md:col-12' style={{ marginTop: 40 }}>
                <div className='flex justify-content-end'>
                    <div style={{ width: '16%' }}>
                        <Button label="Enviar" onClick={handleAdd} />
                    </div>
                </div>
            </div>

            <Dialog
                header="Nueva solicitud enviada"
                visible={visible}
                style={{ width: '50vw' }}
                onHide={() => setVisible(false)}
                footer={footerContent}
                draggable={false}
                resizable={false}
            >
                <div className='text-center'>
                    <p className="m-0">
                        Se ha enviado la solicitud, en 12 horas tendrás una respuesta.
                    </p>
                    <h3>{apiResponse && apiResponse.folio}</h3>
                    <small>Folio generado</small>
                </div>
            </Dialog>
        </section>
    )

}

export default React.memo(MovimientoCompra);
