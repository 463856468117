import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import EventService from '../../../serviceExample/EventService';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import ApiServices from '../../../service/apiServices';
import EncryptService from '../../../service/encrypt.service';

const AdminReciclaje = () => {

    const [events, setEvents] = useState([]);

    const eventService = new EventService();

    /* useEffect(() => {
        eventService.getEvents().then(data => setEvents(data));
    }, []); */ // eslint-disable-line react-hooks/exhaustive-deps

    const [eventos, setEventos] = useState<any>([]);
    const [eventoSelected, setEventoSelected] = useState<any>(null);
    const [solicitudSelected, setSolicitudSelected] = useState<any>(null);
    const [materialeSSolicitudSelected, setMatSolicitudSelected] = useState<any>(null);

    const [fechaini, setFechaIni] = useState<any>(null);
    const [fechafin, setFechaFin] = useState<any>(null);
    /* useEffect(() => {
        const productService = new ProductService();
        productService.getProducts().then(data => setProducts(data));
    }, []); */

    const getCotizaciones = () => {
        let datos = {
            "tipo_id": 3
        }
        ApiServices.apiPost('getSolicitudesAdmin', datos).then(async res => {
            let evArray = res.data.addenda
            let eventosF = [];
            for(let evento of evArray){
                eventosF.push({
                    title: evento.tipo_desc,
                    sol_folio: evento.sol_folio,
                    sol_id: evento.sol_id,
                    id: evento.sol_id,
                    sol_status: evento.sol_status,
                    sol_update: evento.sol_update,
                    tipo_desc: evento.tipo_desc,
                    tipo_id: evento.tipo_id,
                    volumen: evento.volumen,
                    date: evento.sol_update.split('T')[0]
                });
            }
            setEventos(eventosF)
        }).catch(error => {
            console.log(error)
        })
    }

    const getVentanillaCoded = async () => {
        let datos: any = {
            "tipo_id": 3,
        }

        datos = await EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.apiPost('getSolicitudesAdmin', {dataEnc: datos}).then(async res => {
            // setCotizaciones(res.data.addenda)
            let evArray = res.data.addenda
            let eventosF = [];
            for(let evento of evArray){
                eventosF.push({
                    title: evento.tipo_desc,
                    sol_folio: evento.sol_folio,
                    sol_id: evento.sol_id,
                    id: evento.sol_id,
                    sol_status: evento.sol_status,
                    sol_update: evento.sol_update,
                    tipo_desc: evento.tipo_desc,
                    tipo_id: evento.tipo_id,
                    volumen: evento.volumen,
                    date: evento.sol_update.split('T')[0]
                });
            }
            setEventos(eventosF)
        }).catch(error => {
            console.log(error)
        })
    }
    useEffect(() => {
        // getCotizaciones();
        getVentanillaCoded();
    },[])

    const handleEventClick = (event: any) => {
        console.log('Evento seleccionado:', event);
        setEventoSelected(event.event.id);
        getMovementReciclaje();
    };

    const getMovementReciclaje = async () => {
        let datos: any = {
            "code": 302,
            "tipo": 3,
            "sol_id": eventoSelected
        }

        datos = await EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.processRequestAdd(datos)
            .then(async (res) => {
                console.log("DATA DE SOLICITUD SELECCIONADA", res.data)
                setSolicitudSelected(res.data.solicitud)
                setMatSolicitudSelected(res.data.materiales)
            })
            .catch((error) => {
                /* Toasty Error */
            });
    }


    const saveCotizaje = async () => {
        if(solicitudSelected && eventoSelected && fechaini && fechafin && materialeSSolicitudSelected && selectedCity){
            let data: any = {
                "code": 204,
                "sol_id": eventoSelected,
                "sol_ini": new Date(fechaini).toISOString().split('T')[0],
                "sol_fin": new Date(fechafin).toISOString().split('T')[0],
                "materiales": materialeSSolicitudSelected,
                "sol_status": selectedCity.code,  
                "usu_id": solicitudSelected.usu_id,  
            }
            console.log(data)
    
            data = await EncryptService.encryptData(JSON.stringify(data));
            ApiServices.processRequestAdd(data)
                .then(async (res) => {
                    console.log("RESPUESTA", res.data)
                    setVisible(true)
                })
                .catch((error) => {
                    /* Toasty Error */
                });
        } else {
            console.log('Toast de datos faltantes')
        }

    }

    const [selectedCity, setSelectedCity] = useState<any>(null);
    const cities = [
        { name: 'Aprobado', code: 1 },
        { name: 'Rechazado', code: 2 },
    ];
    const [visible, setVisible] = useState(false);
    const footerContent = (
        <div>
            <Button label="Terminar" icon="pi pi-check" onClick={() => setVisible(false)} autoFocus />
        </div>
    );

    return (
        <div className='grid'>
            <div className="col-12 md:col-8">
                <FullCalendar events={eventos} initialDate="2023-08-17" initialView='dayGridMonth' plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    headerToolbar={{ left: 'prev,next today', center: 'title', right: 'dayGridMonth,timeGridWeek,timeGridDay' }} editable selectable selectMirror dayMaxEvents
                    eventClick={handleEventClick} />
            </div>

            <div className='col-12 md:col-4'>
                <div className='col-12 md:col-12'>
                    <h3><i className='pi pi-circle-fill'></i> Reciclaje SA de CV</h3>
                </div>
                <div className='col-12 md:col-12'>
                    {solicitudSelected ? <p>Folio: {solicitudSelected.sol_id}</p> : <p>Folio: 0</p>}
                    <hr />
                    <p>Volumen: 2.5m3</p>
                    <hr />
                </div>
                <div className='col-12 md:col-12'>
                    {solicitudSelected ? <p><i className='pi pi-map-marker'></i>{solicitudSelected ? solicitudSelected.sol_calle + ', ' + solicitudSelected.sol_num + ', ' + solicitudSelected.cp_col + ', ' + solicitudSelected.cp_id
                                    + ', ' + solicitudSelected.cp_edo : ''}</p> : ''}
                </div>
                <div className='col-12 md:col-12'>
                    <span className="p-float-label">
                        <Calendar showIcon className="w-full" dateFormat="dd/MM/yy" value={fechaini} onChange={(e) => setFechaIni(e.value)}/>
                        <label htmlFor="name">Fecha inicio</label>
                    </span>
                </div>
                <div className='col-12 md:col-12'>
                    <span className="p-float-label">
                        <Calendar showIcon className="w-full" dateFormat="dd/MM/yy" value={fechafin} onChange={(e) => setFechaFin(e.value)}/>
                        <label htmlFor="name">Fecha fin</label>
                    </span>
                </div>
                <div className='col-12 md:col-12'>
                    <Dropdown value={selectedCity} onChange={(e) => setSelectedCity(e.value)} options={cities} optionLabel="name" placeholder="Estatus" className="w-full" />
                </div>
                <div className='flex justify-content-end pb-3'>
                    <div>
                        <Button label="Enviar" onClick={saveCotizaje} />
                    </div>
                </div>
            </div>
            <Dialog header="Respuesta enviada" visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)} footer={footerContent} draggable={false} resizable={false}>
                <p className="m-0">
                    Se ha enviado la respuesta con éxito.
                </p>
            </Dialog>
        </div>
    );
}

export default React.memo(AdminReciclaje);