import StorageService from "./storage.service";
import storageService from "./storage.service";

export default class LocalService {
  constructor(private storageService: StorageService) { }
  // Set the json data to local 
  
  static setJsonValue(key: string, value: any) {
    storageService.secureStorage.setItem(key, value);
  }
  // Get the json value from local 
  static getJsonValue(key: string) {
    //console.log("getJsonValue",storageService.secureStorage.getItem(key) );
    return storageService.secureStorage.getItem(key);
  }// Clear the local 
  static clearToken() {
    return storageService.secureStorage.clear();
  }
}


