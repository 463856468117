import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react';
import ProductService from '../../../serviceExample/ProductService';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { useLocation, useNavigate } from 'react-router-dom';
import EncryptService from '../../../service/encrypt.service';
import ApiServices from '../../../service/apiServices';
import authGuardService from '../../../service/authGuardService';


const ViewCotizacion = () => {
    const location = useLocation();

    const previousData = location.state.data

    console.log("DETALLE COTIZACIÓN", previousData)

    const { usu_id } = authGuardService.getUser()
    console.log("DETALLE USUARIO", usu_id)

    const [cotizacionSelected, setcotizacionSelected] = useState<any>(null);
    const [materialesList, setMaterialesList] = useState([]);



    useEffect(() => {
        getMovementDetail()
    }, [previousData])

    const getMovementDetail = async () => {
        let datos: any = {
            "code": 302,
            "tipo": 1,
            "sol_id": previousData.sol_id
        }

        datos = await EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.processRequestAdd(datos)
            .then(async (res) => {
                console.log("DATA DE SOLICITUD SELECCIONADA", res.data.solicitud)
                setcotizacionSelected(res.data.solicitud)
                setMaterialesList(res.data.materiales)
            })
            .catch((error) => {
                /* Toasty Error */
            });
    }

    const cotizar = async () => {
        let missingPrice = false
        const newMaterialList = materialesList.map((singleItem) => {
            const newSingleItem: any = singleItem
            let newNumeric: any
            if (newSingleItem && newSingleItem.mat_costo)
                newNumeric = parseFloat(newSingleItem.mat_costo.toString().replace(/[$,]/g, ''));

            if (!isNaN(newNumeric)) {
                newSingleItem.mat_costo = newNumeric
            } else {
                missingPrice = true
            }
            return newSingleItem
        })

        if (missingPrice) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se debe ingresar un costo', life: 4000 });
        } else {
            let datos: any = {
                "code": 202,
                "sol_id": previousData.sol_id,
                "usu_id": usu_id,
                "materiales": materialesList,
            }
            datos = EncryptService.encryptData(JSON.stringify(datos));
            ApiServices.processRequestAdd(datos)
                .then(async (res) => {
                    setVisible(true)
                    console.log("RESPUESTA SAVE COTIZACIÓN", res.data)
                })
                .catch((error) => {
                    console.log(error)
                    /* Toasty Error */
                });
        }
    }




    let emptyProduct = {
        id: null,
        name: '',
        image: null,
        description: '',
        category: null,
        price: 0,
        quantity: 0,
        rating: 0,
        inventoryStatus: 'INSTOCK'
    };

    const [products, setProducts] = useState<any>(null);
    const [selectedProducts, setSelectedProducts] = useState<any>(null);
    const dt = useRef<any>(null);
    const toast = useRef<any>();
    const navigate = useNavigate();

    // useEffect(() => {
    //     const productService = new ProductService();
    //     productService.getProducts().then(data => setProducts(data));
    // }, []); 



    const codeBodyTemplate = (rowData: any) => {
        return (
            <>
                <span className="p-column-title">Tipo material</span>
                {rowData.mat_desc}
            </>
        );
    }

    const nameBodyTemplate = (rowData: any) => {
        return (
            <>
                <span className="p-column-title">Volumen</span>
                {rowData.mat_volumen}m³
            </>
        );
    }


    const priceBodyTemplate = (rowData: any) => {
        return (
            <>
                <span className="p-column-title">Costo</span>
                <span className="p-float-label">
                    <InputNumber
                        inputId="currency-us"
                        mode="currency"
                        value={rowData.mat_costo}
                        onBlur={(e) => { rowData.mat_costo = e.target.value }}
                        currency="USD"
                        locale="en-US"
                    />
                </span>
            </>
        );
    }

    const [visible, setVisible] = useState(false);
    const footerContent = (
        <div>
            <Button label="Terminar" icon="pi pi-check" onClick={() => navigate('/administrador/cotizaciones')} autoFocus />
        </div>
    );



    return (
        <section>
            <Toast ref={toast} />

            <div className='grid'>
                <div className='col-12 md:col-12'>
                    <div className='grid'>
                        <div className='col-12'>
                            <div className='flex justify-content-between pb-3'>
                                <div>
                                    <h5>Detalle de la solicitud</h5>
                                </div>
                                <div>
                                    <p><span className='font-bold'>Folio  {cotizacionSelected ? cotizacionSelected.sol_id : ''}</span></p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='grid'>
                        <div className='col-12 md:col-6'>
                            <div className='text'>
                                <small className='font-bold'>Tipo de movimiento</small>
                                <p>{cotizacionSelected ? cotizacionSelected.tipo_desc : ''}</p>
                                {/* <p>Compra</p> */}
                            </div>
                        </div>
                        <div className='col-12 md:col-6'>
                            <div className='text'>
                                <small className='font-bold'>Sucursal</small>
                                <p>{cotizacionSelected ? cotizacionSelected.suc_nom : ''}</p>
                                {/* <p>Arboles...</p> */}
                            </div>
                        </div>
                        <div className='col-12 md:col-12'>
                            <div className='text'>
                                <small className='font-bold'>Ubicación</small>
                                <p>{cotizacionSelected ? cotizacionSelected.sol_calle + ', ' + cotizacionSelected.sol_num + ', ' + cotizacionSelected.cp_col + ', ' + cotizacionSelected.cp_id
                                    + ', ' + cotizacionSelected.cp_edo : ''}</p>
                                {/* <p>Priv. Prta 3 Manzana 001, Campo Militar 1 A, 53538 Naucalpan de Juárez, Méx.</p> */}
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-12 md:col-12'>
                    <div className='pb-3'>
                        <h6>Ingresar precios</h6>
                        <p>Ingresa los costos siguientes para enviar la respuesta.</p>
                    </div>

                    <DataTable ref={dt} value={materialesList} showGridlines selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                        dataKey="id" className="datatable-responsive" scrollable scrollHeight="400px"
                        currentPageReportTemplate="Mostrando {first} de {last} de un total de {totalRecords} entradas."
                        emptyMessage="No se encontraron entradas." responsiveLayout="scroll">
                        <Column field="code" header="Tipo material" body={codeBodyTemplate} ></Column>
                        <Column field="mat_volumen" header="Volumen" body={nameBodyTemplate} ></Column>
                        <Column field="mat_costo" header="Costo" body={priceBodyTemplate} ></Column>
                    </DataTable>

                </div>
            </div>
            <div className='flex justify-content-end pb-3'>
                <div>
                    <Button label="Cotizar" className='p-button-lg' onClick={cotizar} />
                </div>
            </div>
            <Dialog header="Respuesta enviada" visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)} footer={footerContent} draggable={false} resizable={false}>
                <p className="m-0">
                    Solicitud actualizada correctamente.
                </p>
            </Dialog>
        </section>
    );
}

export default React.memo(ViewCotizacion);