import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ProductService from '../../../serviceExample/ProductService';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { start } from 'repl';
import authGuardService from '../../../service/authGuardService';
import EncryptService from '../../../service/encrypt.service';
import ApiServices from '../../../service/apiServices';
import { Toast } from 'primereact/toast';

const MovimientoReciclaje = () => {

    const user = authGuardService.getUser()
    const [startDate, setStartDate] = useState<any>()
    const [endDate, setEndDate] = useState<any>()

    const [street, setStreet] = useState<any>()
    const [placeNumber, setPlaceNumber] = useState<any>()
    const [colony, setColony] = useState<any>()
    const [avenue, setAvenue] = useState<any>()
    const [postalCode, setPostalCode] = useState<any>()
    const [materialList, setMaterialList] = useState<any>([])
    const [colonyList, setColonyList] = useState<any>()
    const [selectedMaterial, setSelectedMaterial] = useState<any>()
    const [selectedColony, setSelectedColony] = useState<any>()
    const [cubicMeters, setCubicMeters] = useState<any>()
    const [apiResponse, setApiResponse] = useState<any>()
    const toast = useRef<any>(null);


    /*  */

    const getMaterialList = () => {
        let datos
        ApiServices.apiPost('catMaterial', datos).then(async res => {
            setMaterialList(res.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }

    useEffect(() => {
        // getStoreLocation()
        getMaterialList()
    }, [])


    const handleCPChange = async(cp: any) => {
        setPostalCode(cp)
        if (cp.length === 5) {
            let datos: any = { cp_cp: cp }
            datos = await EncryptService.encryptData(JSON.stringify(datos));

            ApiServices.apiPost('catCP', {dataEnc: datos}).then(async res => {
                setColonyList(res.data.addenda)
            }).catch(error => {
                console.log(error)
            })
        }
    }

    const handleAdd = () => {
        if(street && selectedColony && selectedMaterial && cubicMeters && startDate && endDate && placeNumber){
            let datos: any = {
                "code": 301,
                "usu_id": user.usu_id,
                "tipo_id": 3,
                "sol_calle": street,
                "sol_num": placeNumber,
                "cp_id": selectedColony.cp_id,
                "sol_lat": 0,
                "sol_lng": 0,
                "suc_id": 0,
                "materiales": [{
                    "mat_id": selectedMaterial.mat_id,
                    "mat_volumen": cubicMeters
                }],
                "mov_ini": startDate,
                "mov_fin": endDate
            }
            datos = EncryptService.encryptData(JSON.stringify(datos));
            ApiServices.processRequestAdd(datos)
                .then(async (res) => {
                    if (res.status === 200 && res.data.mensaje === 'Solicitud agregada correctamente') {
                        setApiResponse(res.data)
                        setVisible(true);
                    } else {
                    }
                })
                .catch((error) => {
                     toast.current.show({
                        severity: "error",
                        summary: "Error",
                        detail: "Intentelo de Nuevo mas tarde.",
                        life: 4000,
                    });
                });
        } else {
            toast.current.show({
                severity: "error",
                summary: "Campos incompletos",
                detail: "Todos los campos son obligatorios.",
                life: 4000,
            });
        }

        /* setVisible(true) */
    }


    const [products, setProducts] = useState<any>(null);
    const [selectedProducts, setSelectedProducts] = useState<any>(null);
    const [globalFilter, setGlobalFilter] = useState<any>(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [visible, setVisible] = useState(false);
    const dt = useRef<any>(null);
    const cities = [
        { name: 'New York', code: 'NY' },
        { name: 'Rome', code: 'RM' },
        { name: 'London', code: 'LDN' },
        { name: 'Istanbul', code: 'IST' },
        { name: 'Paris', code: 'PRS' }
    ];

    const navigate = useNavigate();

    /* useEffect(() => {
        const productService = new ProductService();
        productService.getProducts().then(data => setProducts(data));
    }, []); */

    const formatCurrency = (value: any) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }

    const footerContent = (
        <div>
            <Button label="Terminar" icon="pi pi-check" onClick={() => navigate('/inicio')} autoFocus />
        </div>
    );

    return (
        <section>
            <Toast ref={toast} />
            <div className="grid">
                <div className='col-12 md:col-12'>
                    <h5 className="m-0">Nuevo movimiento - Reciclaje de Sitio</h5>
                    <p>Selecciona los siguientes datos para iniciar un nuevo movimiento</p>
                </div>

                <div className='col-12 md:col-4'>
                    <span className="p-float-label">
                        <Calendar
                            inputId="date"
                            showIcon
                            className="w-full"
                            locale="es"
                            dateFormat="dd/MM/yy"
                            value={startDate}
                            onChange={(e) => { setStartDate(e.target.value) }}
                        />
                        <label htmlFor="">Fecha del proyecto inicio</label>
                    </span>
                </div>
                <div className='col-12 md:col-4'>
                    <span className="p-float-label">
                        <Calendar
                            inputId="date"
                            showIcon
                            className="w-full"
                            locale="es"
                            dateFormat="dd/MM/yy"
                            value={endDate}
                            onChange={(e) => { setEndDate(e.target.value) }}
                        />
                        <label htmlFor="">Fecha del proyecto fin</label>
                    </span>
                </div>


                <div className='col-12 md:col-12'>
                    <p>Ubicación</p>
                </div>

                <div className='col-12 md:col-4'>
                    <span className="p-float-label w-100">
                        <InputText
                            value={street}
                            onChange={(e) => setStreet(e.target.value)}
                        />
                        <label htmlFor="username">Calle</label>
                    </span>
                </div>
                <div className='col-12 md:col-4'>
                    <span className="p-float-label w-100">
                        <InputText
                            keyfilter="num"
                            value={placeNumber}
                            onChange={(e) => setPlaceNumber(e.target.value)}
                        />
                        <label htmlFor="username">Número</label>
                    </span>
                </div>
                <div className='col-12 md:col-4'>
                    <span className="p-float-label w-100">
                    <InputText
                            keyfilter="num"
                            value={postalCode}
                            maxLength={5}
                            onChange={(e) => handleCPChange(e.target.value)}
                        />
                        <label htmlFor="username">C.P.</label>
                    </span>
                </div>
                <div className='col-12 md:col-4'>
                    <span className="p-float-label w-100">
                    <Dropdown
                            value={selectedColony}
                            onChange={(e) => setSelectedColony(e.value)}
                            options={colonyList}
                            optionLabel='cp_col'
                            placeholder='Selecciona tu colonia'
                            className='w-full'
                        />
                    </span>
                </div>
                {/* <div className='col-12 md:col-4'>
                    <span className="p-float-label w-100">
                        <InputText
                            value={avenue}
                            onChange={(e) => setAvenue(e.target.value)}
                        />
                        <label htmlFor="username">Avenida</label>
                    </span>
                </div> */}


                <div className='col-12 md:col-12'>
                    <p>Material reciclado</p>
                </div>
                <div className='col-12 md:col-4'>
                    <span className="p-float-label p-input-icon-left w-full">
                        <Dropdown value={selectedMaterial} onChange={(e) => setSelectedMaterial(e.value)} options={materialList} optionLabel="mat_desc" placeholder="Tipo de material" className="w-full" />
                    </span>
                </div>
                <div className='col-12 md:col-4'>
                    <span className="p-float-label w-100">
                        <InputText
                            keyfilter="num"
                            value={cubicMeters}
                            onChange={(e) => setCubicMeters(e.target.value)}
                        />
                        <label htmlFor="username">Volumen</label>
                    </span>
                </div>
            </div>
            <div className='flex justify-content-end pb-3'>
                <div>
                    <Button label="Enviar" onClick={handleAdd} />
                </div>
            </div>
            <Dialog header="Nueva solicitud enviada" visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)} footer={footerContent} draggable={false} resizable={false}>
                <div className='text-center'>
                    <p className="m-0">
                        Se ha enviado la solicitud, en 12 horas tendrás una respuesta.
                    </p>
                    <h3>{apiResponse && apiResponse.folio}</h3>
                    <small>Folio generado</small>
                </div>
            </Dialog>
        </section>
    )

}

export default React.memo(MovimientoReciclaje);
