import moment from 'moment';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ApiServices from '../../service/apiServices';
import authGuardService from '../../service/authGuardService';
import { Toast } from 'primereact/toast';
import { Tag } from 'primereact/tag';
import { FilterMatchMode } from 'primereact/api';
import EncryptService from '../../service/encrypt.service';

const InicioCliente = () => {

    const user = authGuardService.getUser()

    const statusTypes = [
        { id: 0, name: 'Cancelada' },
        { id: 1, name: 'Nueva' },
        { id: 2, name: 'Cotizada' },
        { id: 3, name: 'Pendiente' },
        { id: 4, name: 'Validación de pago' },
        { id: 5, name: 'Activa' },
        { id: 6, name: 'Entregado' }
    ]

    const toast = useRef<any>(null);

    const [movementTypes, setMovementTypes] = useState([])
    const [movementTypesFilter, setMovementTypesFilter] = useState([])

    const [selectedMovementType, setSelectedMovementType] = useState<any>(null)

    const [movementsList, setMovementsList] = useState<any>([])
    const [movementsListClone, setMovemenstListClone] = useState<any>([])

    const [selectedTypeFilter, setSelectedTypeFilter] = useState<any>()
    const [filterDate, setFilterDate] = useState<any>()

    useEffect(() => {
        getMovementTypes()
        getMovements()
    }, [])

    const getMovementTypes = () => {
        let datos
        ApiServices.apiPost('catTipoMov', datos).then(async res => {
            setMovementTypes(res.data.addenda)
            setMovementTypesFilter(res.data.addenda)
        }).catch(error => {
            toastyErrorServer()
        })
    }

    const getMovements = async () => {
        let datos:any = {
            usu_id: user.usu_id,
            tipo_id: 0,
            sol_update: ""
        }
        datos = await EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.apiPost('getSolicitudes', {dataEnc: datos}).then(async res => {
            formatMovements(res.data.addenda)
        }).catch(error => {
            toastyErrorServer()
        })
    }

    const formatMovements = (movements: any) => {
        const newMovements = structuredClone(movements)

        const formatted = newMovements.map((singleMovement: any) => {
            const clone = structuredClone(singleMovement)

            const fechaFormateada = moment(singleMovement.sol_update).format("DD-MMMM-YYYY");
            clone.formattedDate = fechaFormateada

            statusTypes && statusTypes.map((singleStatusType: any) => {
                if (singleStatusType.id === singleMovement.sol_status) {
                    clone.status_desc = singleStatusType.name
                }
            })
            return clone
        })
        setMovementsList(formatted)
        setMovemenstListClone(formatted)
    }

    const handleNewQuoteClick = () => {
        if (selectedMovementType) {
            switch (selectedMovementType.tipo_id) {
                case 1:
                    navigate('/movimiento/compra')
                    break;
                case 2:
                    navigate('/movimiento/envio')
                    break;
                case 3:
                    navigate('/movimiento/reciclaje')
                    break;
                default:
                    toastyErrorServer()
            }
        }
    }

    const formatDate = (date: any) => {
        const fechaOriginal = new Date(date);
        const año = fechaOriginal.getFullYear();
        const mes = (fechaOriginal.getMonth() + 1).toString().padStart(2, '0'); // Los meses van de 0 a 11
        const dia = fechaOriginal.getDate().toString().padStart(2, '0');
        return `${año}-${mes}-${dia}`
    }

    const handleFilter = (filter: any, date: any) => {
        let newArray: any = []
        const cloneArray = structuredClone(movementsList)

        if (filter && date) {
            cloneArray.map((singleElement: any) => {
                const splitDate = singleElement.sol_update.split('T')
                if ((singleElement.tipo_desc === filter.tipo_desc) && (formatDate(date) === splitDate[0])) {
                    newArray.push(singleElement)
                }
            })
        } else if (filter || date) {
            cloneArray.map((singleElement: any) => {
                const splitDate = singleElement.sol_update.split('T')
                if ((filter && (singleElement.tipo_desc === filter.tipo_desc)) || (formatDate(date) === splitDate[0])) {
                    newArray.push(singleElement)
                }
            })
        } else {
            newArray = movementsList
        }

        setMovemenstListClone(newArray)
    }

    const toastyErrorServer = () => {
        toast.current.show({ severity: 'error', summary: 'Error de Conexión', detail: 'No fue posible acceder al servidor intentelo de nuevo mas tarde.' });
    };

    const getStatusColor = (status: any) => {
        switch (status) {
            case 'Cancelada':
                return '#C94800';
            case 'Nueva':
                return '#000000';
            case 'Cotizada':
                return '#00779C';
            case 'Pendiente':
                return '#C94800';
            case 'Validación de pago':
                return '#00779C';
            case 'Activa':
                return '#00581C'
            case 'Entregado':
                return '#00581C'
        }
    }

    const statusBodyTemplate = (rowData: any) => {
        return <Tag value={rowData.status_desc} style={{ background: getStatusColor(rowData.status_desc), color: '#FFF' }} />;
    };
    const [globalFilterValue, setGlobalFilterValue] = useState("");

    const onGlobalFilterChange = (e: any) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters["global"].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const navigate = useNavigate();

    const navigateToScreen = (data: any) => {
        navigate('/movimiento/detalle', { state: { data: data } })
    }


    const actionBodyTemplate = (rowData: any) => {
        return (
            <div className="actions">
                <Button icon="pi pi-eye" className="p-button-text" onClick={() => { navigateToScreen(rowData) }} />
            </div>
        );
    }

    const header = (
        <div className="grid">
            <Toast ref={toast} />

            <div className='col-12 md:col-7'>
                <h5 className="m-0">Movimientos en transcurso</h5>
            </div>
            <div className='col-12 md:col-3'>
                <span className="p-float-label p-input-icon-left w-100">
                    <Dropdown
                        value={selectedMovementType}
                        onChange={(e) => setSelectedMovementType(e.value)}
                        options={movementTypes}
                        optionLabel="tipo_desc"
                        placeholder="Tipo de movimiento"
                        className="w-full"
                        emptyMessage="Error al Obtener Movimientos"
                    />
                </span>
            </div>
            <div className='col-12 md:col-2'>
                <div className='flex justify-content-end pb-3'>
                    <Button
                        label="Nueva cotización"
                        onClick={handleNewQuoteClick}
                        disabled={!selectedMovementType}
                    />
                </div>
            </div>
            <div className='col-12 md:col-12'>
                <p>Filtrado</p>
            </div>
            <div className='col-12 md:col-3'>
                <span className="p-float-label p-input-icon-left w-100">
                    <Dropdown
                        value={selectedTypeFilter}
                        onChange={(e) => {
                            setSelectedTypeFilter(e.value)
                            handleFilter(e.value, filterDate)
                        }}
                        options={movementTypesFilter}
                        optionLabel="tipo_desc"
                        placeholder="Tipo de movimiento"
                        className="w-full"
                        emptyMessage="Error al Obtener Movimientos"
                        showClear
                    />
                </span>
            </div>
            <div className='col-12 md:col-3'>
                <span className="p-float-label">
                    <Calendar
                        inputId="date"
                        showIcon
                        className="w-full"
                        locale="es"
                        dateFormat="dd/MM/yy"
                        value={filterDate}
                        onChange={(e) => {
                            setFilterDate(e.target.value)
                            handleFilter(selectedTypeFilter, e.target.value)
                        }}
                        showButtonBar
                    />
                    <label htmlFor="date">Fecha</label>
                </span>
            </div>
            <div className='col-12 md:col-3'>
                <span className="p-float-label p-input-icon-left w-100">
                    <i className="pi pi-search" />
                    <InputText
                        type="search"
                        value={globalFilterValue}
                        onChange={onGlobalFilterChange}
                    />
                    <label htmlFor="username">Buscador general</label>
                </span>
            </div>
        </div >
    );

    return (
        <section>
            <DataTable
                value={movementsListClone}
                dataKey="sol_id"
                paginator
                rows={5}
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Mostrando {first} de {last} de un total de {totalRecords} entradas"
                emptyMessage="No se encuentran entradas."
                header={header}
                filters={filters}
            >
                <Column field="sol_id" header="Folio" sortable ></Column>
                <Column field="tipo_desc" header="Movimiento" sortable ></Column>
                <Column field="formattedDate" header="Fecha" sortable ></Column>
                <Column field="volumen" header="Volumen" sortable ></Column>
                <Column filterField="status_desc" header="Estatus" body={statusBodyTemplate} />

                <Column body={actionBodyTemplate} headerStyle={{ minWidth: '1rem' }}></Column>
            </DataTable>
        </section>
    );

}

export default React.memo(InicioCliente);