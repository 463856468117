import './styles.css'

const CompanyMarker = (props: any) => {
   // console.log(props)
    return (
        <div>
            <i className={`pi pi-building building ${props.selected && 'selected'}`} style={{ fontSize: '1.5rem' }}></i>
        </div>
    )
};

export default CompanyMarker;
