import React, { useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import UserService from "../../service/user.service";
import { Toast } from "primereact/toast";
import EncryptService from "../../service/encrypt.service";
import { useNavigate } from "react-router-dom";
import authGuardService from "../../service/authGuardService";
import MenuService from "../../service/menu.service";
import Axios from "axios";
import { Password } from "primereact/password";
import { RequestService } from "../../utilities/request.component";
import ApiServices from "../../service/apiServices";

export const Login = (props: any) => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [validEmail, setValidEmail] = useState<any>(false);

  const toast = useRef<any>();
  const navigate = useNavigate();
  const permisos: { label: any }[] = [];

  const validateEmail = (email: any) => {
    // onInputChange(email, name)
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    setValidEmail(re.test(email.target.value))
}

  const permisosMenu = async (menu: any[]) => {
    menu.forEach((menuItem) => {
      let permiso = { label: menuItem.label };
      if (menuItem.items && menuItem.items.length > 0) {
        permisosMenu(menuItem.items);
      } else {
        permisos.push(permiso);
      }
    });
    return permisos;
  };

  const userLogin = () => {
    setSubmitted(true);
    if (email.trim().length <= 0 || password.trim().length <= 0) {
      toast.current.show({
        severity: "error",
        summary: "Campos incompletos",
        detail: "Ingresa tu correo y tu contraseña",
        life: 4000,
      });
    } else {
      let datos;
      datos = {
        code: 101,
        usu_email: email,
        usu_psw: password,
      };
      datos = EncryptService.encryptData(JSON.stringify(datos));
      ApiServices.processRequestAdd(datos)
        .then(async (res) => {
          if (res.data.valido === 0) {
            toast.current.show({
              severity: "error",
              summary: "Datos Incorrectos",
              detail: "Email y/o Contraseña Incorrectos",
              life: 4000,
            });
          } else {
            let usuario = res.data.addenda;
            usuario.token = res.data.token;
            authGuardService.sendKey(res.data.token);
            authGuardService.sendUser(usuario);
            /* MenuService.getMenuPerfilConsola(
              res.data.token,
              usuario.id_perfil
            ).then((res) => {
              let respuestaDto = EncryptService.dencryptData(res.data.response);
              if (respuestaDto.valido === 0) {
                toast.current.show({
                  severity: "error",
                  summary: "Datos Incorrectos",
                  detail: "Email y/o Contraseña Incorrectos",
                  life: 4000,
                });
              } else {
                permisosMenu(respuestaDto.addenda).then(async (permiso) => {
                  authGuardService.sendPermits(permiso);
                });
              }
            }); */
            navigate("/inicio");
          }
        })
        .catch((error) => {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Intentelo de Nuevo mas tarde.",
            life: 4000,
          });
        });
    }
  };

  const handleClick = () => {
    // Navegar a otra ruta
    navigate("registro");
  };

  return (
    <section>
      <Toast ref={toast} />
      <div className="fondo">
        <div className="grid" style={{ height: "100%", marginTop: 0 }}>
          <div className="col-12 md:col-6 p-0">
            <img src="assets/img/login-fondo.svg" className="w-100" />
          </div>
          <div className="col-12 md:col-6 flex justify-content-center align-items-center">
            <div className="login">
              <h1>Bienvenido</h1>
              <p>Ingresa los siguientes datos para iniciar sesión.</p>
              <div className="grid p-5">
                <div className="col-12 md:col-12">
                  <span className="p-float-label">
                    <InputText
                      className="p-inputtext-lg"
                      id="username"
                      value={email}
                      onChange={(e) => {setEmail(e.target.value); validateEmail(e)}}
                    />
                    <label htmlFor="username">Correo Electronico</label>
                    {/* {submitted && !email && <small className="p-invalid">Correo es requerido.</small>} */}
                  </span>
                    {!validEmail && email && <small className="p-invalid">El correo no es valido.</small>}
                </div>
                <div className="col-12 md:col-12">
                  <span className="p-float-label">
                    <Password
                      className="p-inputtext-lg w-100"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      feedback={false}
                      toggleMask
                    />
                    <label htmlFor="username">Contraseña</label>
                  </span>
                </div>
                <div className="col-12 md:col-12">
                  <div className="p-5">
                    <Button
                      type="button"
                      label="Iniciar"
                      disabled={!validEmail}
                      onClick={userLogin}
                    ></Button>
                  </div>
                  <div className="p-5">
                    <Button
                      type="button"
                      label="Regístrate"
                      className="p-button-text"
                      onClick={handleClick}
                    ></Button>
                  </div>
                  <div>
                    <Button
                      type="button"
                      label="¿Olvidaste la contraseña?"
                      className="p-button-text"
                      onClick={() => {
                        navigate("olvide");
                      }}
                    ></Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
