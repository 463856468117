import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react';
import ProductService from '../../../serviceExample/ProductService';
import { Panel } from 'primereact/panel';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Dialog } from 'primereact/dialog';
import { useLocation, useNavigate } from 'react-router-dom';
import authGuardService from '../../../service/authGuardService';
import EncryptService from '../../../service/encrypt.service';
import ApiServices from '../../../service/apiServices';
import { ProgressBar } from 'primereact/progressbar';
import { Tag } from 'primereact/tag';
import { Tooltip } from 'primereact/tooltip';
import { FileUpload } from 'primereact/fileupload';

const ViewPagos = () => {
    const location = useLocation();

    const previousData = location.state.data

    console.log("DETALLE PAGO", previousData)

    const { usu_id } = authGuardService.getUser()
    let emptyProduct = {
        id: null,
        name: '',
        image: null,
        description: '',
        category: null,
        price: 0,
        quantity: 0,
        rating: 0,
        inventoryStatus: 'INSTOCK'
    };

    const [products, setProducts] = useState<any>(null);
    const [selectedProducts, setSelectedProducts] = useState<any>(null);
    const dt = useRef<any>(null);
    const toast = useRef<any>(null);
    const [selectedCity1, setSelectedCity1] = useState<any>(null);
    const navigate = useNavigate();

    const cities = [
        { name: 'Pendiente', code: 1 },
        { name: 'Realizado', code: 2 },
    ];

    const onCityChange = (e: { value: any }) => {
        setSelectedCity1(e.value);
    }

    const [pagoSelected, setPagoSelected] = useState<any>(null);
    const [pago, setPago] = useState<any>(null);
    const [materialesList, setMaterialesList] = useState([]);
    const [selectedNota, setSelectedNota] = useState<any>(null);
    const [selectedComprobante, setSelectedComprobante] = useState<any>(null);

    /*  useEffect(() => {
         const productService = new ProductService();
         productService.getProducts().then(data => setProducts(data));
     }, []); */

     

    useEffect(() => {
        getMovementDetail()
    }, [previousData])

    const getMovementDetail = async () => {
        let datos: any = {
            "code": 302,
            "tipo": 2,
            "sol_id": previousData.sol_id
        }

        datos = await EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.processRequestAdd(datos)
            .then(async (res) => {
                setPago(res.data.pago[0])
                setPagoSelected(res.data.solicitud)
                setMaterialesList(res.data.materiales)
            })
            .catch((error) => {
                /* Toasty Error */
            });
    }


    const updatePayment = async () => {
        let data: any = {
            "sol_id": previousData.sol_id,
            "pago_id": pago.pago_id,
            "pago_status": selectedCity1.code,
            "pago_comprobante": "media/comprobante/",
            "pago_nota": selectedNota,
            "sol_status":5
        }

        ApiServices.apiPostFile('updatePago', file, data).then(async res => {
            if (res.data.valido === 1) {
                // console.log('Toasty pago actualizado con éxito')
                setVisible(true)
            }
            console.log(res)
        }).catch(error => {
            console.log(error)
        })
    }

    const codeBodyTemplate = (rowData: any) => {
        return (
            <>
                <span className="p-column-title">Tipo material</span>
                {rowData.mat_desc}
            </>
        );
    }

    const nameBodyTemplate = (rowData: any) => {
        return (
            <>
                <span className="p-column-title">Volumen</span>
                {rowData.mat_volumen}
            </>
        );
    }


    const priceBodyTemplate = (rowData: any) => {
        return (
            <>
                <span className="p-column-title">Costo</span>
                <p>${rowData.mat_costo}</p>
            </>
        );
    }

    const formatCurrency = (value: any) => {
        if (value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        } else {
            return 0
        }
    }

    const TotalVolumen = () => {
        let total = 0;

        materialesList && materialesList.map((singleMaterial: any) => {
            total = total + singleMaterial.mat_volumen
        })

        return total + "m3";
    };

    const TotalMaterial = () => {
        let total = 0;

        materialesList && materialesList.map((singleMaterial: any) => {
            total = total + singleMaterial.mat_costo
        })

        return formatCurrency(total);
    };


    const footerGroup = (
        <ColumnGroup>
            <Row>
                <Column footer="Totales:" />
                <Column footer={TotalVolumen} />
                <Column footer={TotalMaterial} />
            </Row>
        </ColumnGroup>
    );

    const [visible, setVisible] = useState(false);
    const footerContent = (
        <div>
            <Button label="Terminar" icon="pi pi-check" onClick={() => navigate('/administrador/pagos')} autoFocus />
        </div>
    );


    const [file, setFile] = useState<any>();
    const refFile: any = useRef()
    const handleFileClick = (e: any) => {
        refFile.current.click()
    }

    const readUploadFile = (e: any) => {
        e.preventDefault();
        if (e.target.files) {
            console.log(e.target.files[0])
            setFile(e.target.files[0]);
        }
    }


    return (
        <section>
            <Toast ref={toast} />

            <div className='grid'>
                <div className='col-12 px-5'>
                    <div className='grid'>
                        <div className='col-12'>
                            <div className='flex justify-content-between pb-3'>
                                <div>
                                    <h5>Detalle de solicitud</h5>
                                </div>
                                <div>
                                    <p>Folio <span className='font-bold'>{pagoSelected ? pagoSelected.sol_id : ''}</span></p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='grid'>
                        <div className='col-12 md:col-6'>
                            <div className='text'>
                                <small className='font-bold'>Tipo de movimiento</small>
                                <p>{pagoSelected ? pagoSelected.tipo_desc : ''}</p>
                            </div>
                        </div>
                        <div className='col-12 md:col-6'>
                            <div className='text'>
                                <small className='font-bold'>Sucursal</small>
                                <p>{pagoSelected ? pagoSelected.suc_nom : ''}</p>
                            </div>
                        </div>
                        <div className='col-12 md:col-12'>
                            <div className='text'>
                                <small className='font-bold'>Ubicación</small>
                                <p>{pagoSelected ? pagoSelected.sol_calle + ', ' + pagoSelected.sol_num + ', ' + pagoSelected.cp_col + ', ' + pagoSelected.cp_id
                                    + ', ' + pagoSelected.cp_edo : ''}</p>
                            </div>
                        </div>
                        <div className='col-12 md:col-6'>
                            <h4>Solicitud</h4>
                            <DataTable ref={dt} value={materialesList} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)} footerColumnGroup={footerGroup}
                                dataKey="id" className="datatable-responsive" scrollable scrollHeight="400px"
                                currentPageReportTemplate="Mostrando {first} de {last} de un total de {totalRecords} entradas"
                                emptyMessage="No se encontraron entradas." responsiveLayout="scroll">
                                <Column field="mat_desc" header="Tipo material" sortable body={codeBodyTemplate} ></Column>
                                <Column field="mat_volumen" header="Volumen" sortable body={nameBodyTemplate} ></Column>
                                <Column field="mat_costo" header="Costo" body={priceBodyTemplate} sortable ></Column>
                            </DataTable>
                        </div>
                        <div className='col-12 md:col-6'>
                            <h4>Método de pago</h4>
                            <Panel header="TRANSFERENCIA BANCARIA (SPEI)">
                                <div className='grid'>
                                    <div className='col-12 md:col-4'>
                                        <div className='text'>
                                            <small className='font-bold'>Banco</small>
                                            <p>Bancomer</p>
                                        </div>
                                    </div>
                                    <div className='col-12 md:col-4'>
                                        <div className='text'>
                                            <small className='font-bold'>Cuenta bancaria</small>
                                            <p>03398493574563</p>
                                        </div>
                                    </div>
                                    <div className='col-12 md:col-4'>
                                        <div className='text'>
                                            <small className='font-bold'>Referencia</small>
                                            <p>V858003</p>
                                        </div>
                                    </div>
                                    <div className='col-12 md:col-12'>
                                        <small>*Para poder seguir con el registro de vehículos, tendrá que esperar a que el equipo de administrador de Concreto valide el pago.</small>
                                    </div>
                                </div>
                            </Panel>
                        </div>

                    </div>
                </div>
                <div className='col-12'>
                    <div className='grid'>
                        <div className='col-12 md:col-6'>
                            <h5>Comprobante de pago</h5>
                            {!file ? <div className='pt-2'>
                                <input
                                    ref={refFile}
                                    type="file"
                                    hidden
                                    onChange={readUploadFile}
                                />
                                <p className='document' onClick={handleFileClick}>
                                    <i className='pi pi-cloud-upload' style={{ 'fontSize': '3em' }}></i>
                                    Selecciona para subir el comprobante de pago</p>
                            </div> :
                            <div className='pt-2 document-ok'>
                                <p onClick={handleFileClick}><i className='pi pi-file' style={{ 'fontSize': '3em' }}></i></p>
                                <a className='' href="">{file.name}</a>
                                {/* Se agrega el 2do con texto del nombre del archivo con el peso, puede seleccionar para hacer cambio de documento */}
                            </div>}
                        </div>

                        <div className='col-12 md:col-6'>
                            <div className='grid'>
                                <div className='col-12 md:col-12'>
                                    <h5>Enviar respuesta</h5>
                                </div>

                                <div className='col-12 md:col-6'>
                                    <span className="p-float-label">
                                        <Dropdown className='w-full' value={selectedCity1} options={cities} onChange={onCityChange} optionLabel="name" />
                                        <label htmlFor="username">Estatus</label>
                                    </span>
                                </div>
                                <div className='col-12 md:col-12 '>
                                    <span className="p-float-label">
                                        <InputTextarea value={selectedNota} onChange={(e) => setSelectedNota(e.target.value)} rows={5} cols={30} />
                                        <label htmlFor="username">Nota</label>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='flex justify-content-end pb-3'>
                        <div>
                            {/* <Button label="Enviar" className='p-button-lg' onClick={() => setVisible(true)} /> */}
                            <Button label="Enviar" className='p-button-lg' onClick={updatePayment} />
                        </div>
                    </div>

                </div>
                <Dialog header="Respuesta enviada" visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)} footer={footerContent} draggable={false} resizable={false}>
                    <p className="m-0">
                        Se ha enviado la respuesta exitosamente.
                    </p>
                </Dialog>
            </div >


        </section >
    );
}

export default React.memo(ViewPagos);