import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import ProductService from "../../../serviceExample/ProductService";
import authGuardService from "../../../service/authGuardService";
import EncryptService from "../../../service/encrypt.service";
import ApiServices from "../../../service/apiServices";
import { FileUpload } from "primereact/fileupload";
import { Toast } from "primereact/toast";

const ViewHistorial = () => {
    const location = useLocation();
    const toast = useRef<any>(null);
    const previousData = location.state.data
    const navigate = useNavigate();
    console.log("DETALLE COTIZACIÓN",previousData)
    
    const { usu_id } = authGuardService.getUser()


    const [products, setProducts] = useState<any>(null);
    const [selectedProducts, setSelectedProducts] = useState<any>(null);
    const [globalFilter, setGlobalFilter] = useState<any>(null);
    const dt = useRef<any>(null);

    const [historialSelected, setHistorialSelected] = useState<any>(null);
    const [materialesList, setMaterialesList] = useState([]);
    const [movimientosList , setMovimientosList] = useState([]);
    

    useEffect(() => {
        getMovementDetail()
    }, [previousData])

    const getMovementDetail = async () => {
        let datos: any = {
            "code": 302,
            "tipo": 4,
            "sol_id": previousData.sol_id
        }

        datos = await EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.processRequestAdd(datos)
            .then(async (res) => {
                console.log("DATA DE HISTORIAL SELECCIONADA", res.data)
                setHistorialSelected(res.data.solicitud)
                setMaterialesList(res.data.materiales)
                setMovimientosList(res.data.movimientos)
            })
            .catch((error) => {
                /* Toasty Error */
            });
    }

    const [filePDF, setFilePDF] = useState<any>();

    const onFileSelectPDF = async (event: any) => {
        // Aquí puedes manejar el archivo seleccionado
        console.log(event.files[0]);
        setFilePDF(event.files[0])

        // await addPDF()
    };

    const addPDF = async () => {
        console.log("ejecucion de save PDF")
        let data: any = {
            "sol_id": previousData.sol_id,
            "sol_fact_pdf": "media/factura/",
        }

        ApiServices.apiPostFile('addFactPDF', filePDF, data).then(async res => {
            if (res.data.valido === 1) {
                console.log(res.data)
                toast.current.show({ severity: 'success', summary: 'Exito', detail: 'Se ha guardado el PDF con éxito', life: 4000 });
            }
            console.log(res)
        }).catch(error => {
            console.log(error)
        })
    }

    const [fileXML, setFileXML] = useState<any>();

    const onFileSelectXML = async (event: any) => {
        // Aquí puedes manejar el archivo seleccionado
        console.log(event.files[0]);
        setFileXML(event.files[0])

        // await addXML()
    };

    const addXML = async () => {
        console.log("ejecucion de save XML")
        let data: any = {
            "sol_id": previousData.sol_id,
            "sol_fact_xml": "media/factura/",
        }

        ApiServices.apiPostFile('addFactXML', fileXML, data).then(async res => {
            if (res.data.valido === 1) {
                console.log(res.data)
                toast.current.show({ severity: 'success', summary: 'Exito', detail: 'Se ha guardado el XML con éxito', life: 4000 });
                    navigate('/administrador/cotizaciones')
            }
            console.log(res)
        }).catch(error => {
            console.log(error)
        })
    }


    const onUpload = () => {
        toast.current.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
    };

    const formatCurrency = (value: any) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }

    /* useEffect(() => {
        const productService = new ProductService();
        productService.getProducts().then(data => setProducts(data));
    }, []);
 */
    const codeBodyTemplate = (rowData: any) => {
        return (
            <>
                <span className="p-column-title">Code</span>
                {rowData.mat_volumen}
            </>
        );
    }
    const codeBodyTemplateMov = (rowData: any) => {
        return (
            <>
                <span className="p-column-title">Code</span>
                {rowData.mov_placa}
            </>
        );
    }

    const nameBodyTemplate = (rowData: any) => {
        return (
            <>
                <span className="p-column-title">Name</span>
                {rowData.mat_desc}
            </>
        );
    }

    const nameBodyTemplateMov = (rowData: any) => {
        return (
            <>
                <span className="p-column-title">Name</span>
                {rowData.mov_folio}
            </>
        );
    }


    const priceBodyTemplate = (rowData: any) => {
        return (
            <>
                <span className="p-column-title">Price</span>
                {rowData.mov_volumen}
            </>
        );
    }

    const categoryBodyTemplate = (rowData: any) => {
        return (
            <>
                <span className="p-column-title">Category</span>
                {rowData.mov_conductor}
            </>
        );
    }

    return (
        <section>
            <Toast ref={toast} />

            <div className="grid">
                <div className='col-12 md:col-12'>
                    <h5 className="m-0">Historial</h5>
                    <p>Podrás el detalle de los movimientos</p>
                </div>
            </div>
            <div className='grid'>
                <div className='col-12 md:col-2'>
                    <div className='text'>
                        <small className='font-bold'>Tipo de movimiento</small>
                        {/* <p>Compra</p> */}
                        <p>{historialSelected ? historialSelected.tipo_desc : ''}</p>
                    </div>
                </div>
                <div className='col-12 md:col-2'>
                    <div className='text'>
                        <small className='font-bold'>Tipo de pago</small>
                        <p>En linea</p>
                        {/* <p>{historialSelected  && historialSelected.pago_forma == 1 ? 'Tarjeta' : historialSelected.pago_forma == 2 ? 'Transferencia' : 
                            historialSelected.pago_forma == 3 ? 'Deposito' : historialSelected.pago_forma == 4 ? 'Ventanilla' :' '}</p> */}
                    </div>
                </div>
                <div className='col-12 md:col-2'>
                    <div className='text'>
                        <small className='font-bold'>Línea de pago</small>
                        <p>Ventanilla</p>
                        {/* <p>Ventanilla</p> */}
                    </div>
                </div>
                <div className='col-12 md:col-1'>
                    <div className='text'>
                        <small className='font-bold'>Sucursal</small>
                        {/* <p>Arboles...</p> */}
                        <p>{historialSelected ? historialSelected.suc_nom : ''}</p>
                    </div>
                </div>
                <div className='col-12 md:col-4'>
                    <div className='text'>
                        <small className='font-bold'>Ubicación</small>
                        {/* <p>Priv. Prta 3 Manzana 001, Campo Militar 1 A, 53538 Naucalpan de Juárez, Méx.</p> */}
                        <p>{historialSelected ? historialSelected.sol_calle + ', ' + historialSelected.sol_num + ', '+ historialSelected.cp_cp + ', '+ historialSelected.cp_id
                                + ', ' + historialSelected.cp_edo : ''}</p>
                    </div>
                </div>
                <div className='col-12 md:col-1'>
                    <Button label="Ver factura" />
                </div>
            </div>
            <hr />
            <div className="grid">
                <div className='col-12 md:col-4'>
                    <DataTable ref={dt} value={materialesList} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                        dataKey="id" paginator rows={5} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} de {last} de un total de {totalRecords} entradas"
                        globalFilter={globalFilter} emptyMessage="No products found." responsiveLayout="scroll">
                        <Column field="name" header="Tipo de material" sortable body={nameBodyTemplate} ></Column>
                        <Column field="code" header="Volumen" sortable body={codeBodyTemplate} ></Column>
                    </DataTable>
                </div>
                <div className='col-12 md:col-8'>
                    <DataTable ref={dt} value={movimientosList} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                        dataKey="id" paginator rows={5} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} de {last} de un total de {totalRecords} entradas"
                        globalFilter={globalFilter} emptyMessage="No products found." responsiveLayout="scroll">
                        <Column field="code" header="Folio" sortable body={nameBodyTemplateMov} ></Column>
                        <Column field="name" header="Placa" sortable body={codeBodyTemplateMov} ></Column>
                        <Column field="price" header="Conductor" body={categoryBodyTemplate} sortable ></Column>
                        <Column field="category" header="Volumen" body={priceBodyTemplate} sortable ></Column>
                    </DataTable>
                </div>
            </div>
            <div className='flex justify-content-end pb-3'>
                {!filePDF || !fileXML ?
                    <div>
                        <div className="p-3">
                            <FileUpload mode="basic" chooseLabel="Subir factura (PDF)" accept=".pdf" customUpload={false} maxFileSize={1000000} 
                                        onSelect={onFileSelectPDF}/>
                            {/* <Button label="Subir factura (PDF)" icon="pi pi-file-pdf" /> */}
                        </div>
                        <div className="p-3">
                            <FileUpload mode="basic" chooseLabel="Subir factura (XML)" accept=".xml" customUpload={false} maxFileSize={1000000} 
                                        onSelect={onFileSelectXML}/>
                            {/* <Button label="Subir factura (XML)" icon="pi pi-file-excel" /> */}
                        </div>
                    </div>
                    :
                    <div className="p-3">
                        <Button label="Guardar facturas" onClick={()=>{addPDF();addXML()}} />
                    </div>
                }

                {/* <div className="p-3">
                    <Button label="Descargar información" />
                </div> */}
            </div>
        </section>
    );
}

export default React.memo(ViewHistorial);