import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { MultiSelect } from 'primereact/multiselect';
import authGuardService from '../../../../service/authGuardService';
import MenuService from '../../../../service/menu.service';
import { PerfilService } from '../../../../service/perfil.service';

const ProfilesConfiguration = () => {
    let emptyCatalog = {
        descripcion: '',
        id_perfil: '',
        perfiles_status: '',
        permiso_app: '',
        permiso_web: ''
    };

    const token = authGuardService.getToken()
    const [dropdownValue, setDropdownValue] = useState<any>(null);
    const [dropdownValues, setDropdownValues] = useState<any>(null);
    const [catalogs, setCatalogs] = useState<any>(null);
    const [catalog, setCatalog] = useState<any>(emptyCatalog);
    const [addDialog, setAddDialog] = useState<boolean>(false);
    const [deleteDialog, setDeleteDialog] = useState<boolean>(false);
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [globalFilter, setGlobalFilter] = useState<any>(null);
    const toast = useRef<any>(null);
    const settingsRef = useRef<any>(null);
    const [multiselectValue, setMultiselectValue] = useState<any>(null);

    const [menus, setMenus] = useState<any>(null);
    const [menusPermissions, setMenusPermissions] = useState<any>(null);

    useEffect(() => {
        hanldeGetMenus()
        hanldeGetPerfiles()
    }, []);

    //Obtiene los Setting de la DB
    const hanldeGetMenus = async () => {
        await MenuService.getMenus({}, token).then((response) => {
            if (response.data.valido == 0) toast.current.show({ severity: 'error', summary: 'Error', detail: 'Hubo un error al descargar los datos', life: 4000 })
            else {
                const values = response.data.addenda.map((element: any) => {
                    return { name: element.menu_nombre, code: element.id_menu }
                });
                setMenus(values)
            }
        }).catch((error) => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Hubo un error al descargar los datos', life: 4000 });
        })
    }

    const hanldeGetPerfiles = async () => {
        await PerfilService.getPerfiles({}, token).then((response) => {
            if (response.data.valido == 0) toast.current.show({ severity: 'error', summary: 'Error', detail: 'Hubo un error al descargar los datos', life: 4000 })
            else setCatalogs(response.data.addenda)
        }).catch((error) => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Hubo un error al descargar los datos', life: 4000 });
        })
    }

    //Obtiene los valores de los tipos de perfiles de la DB
    const handleGetTypes = async () => {
        const allUSers = await PerfilService.getPerfiles({}, token).then((response) => {
            if (response.data.valido == 0) toast.current.show({ severity: 'error', summary: 'Error', detail: 'Hubo un error al descargar los datos', life: 4000 })
            else {
                const values = response.data.addenda.map((element: any) => {
                    return { name: element.descripcion, code: element.id_perfil }
                });
                setDropdownValues(values)
            }
        }).catch((error) => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Hubo un error al descargar los datos', life: 4000 });
        })
    }

    //Guarda o Edita el Catalogo seleccionado
    const saveCatalog = async () => {
        setSubmitted(true);
        //Si el Catalogo tiene ID se mandara a Actulizar ese registro de lo contrario se mandara a agregar.
        if (catalog.id_perfil) {
            let datoPerfil = {
                id_perfil: catalog.id_perfil,
                descripcion: catalog.descripcion,
                perfiles_status: catalog.perfiles_status
            }
            PerfilService.updatePerfil(datoPerfil, token).then(resp => {
                let respuestaDto = resp.data
                if (respuestaDto.valido == 1) {
                    let idmenus = "";
                    if (multiselectValue) {
                        multiselectValue.forEach((element: { code: string; }) => {
                            idmenus = idmenus + element.code + ","
                        });
                    }
                    if (idmenus) {
                        MenuService.setMenuEnPerfil({ menu_nombre: idmenus + "0", id_perfil: catalog.id_perfil }, token).then((response) => {
                            if (response.data.valido == 0) toast.current.show({ severity: 'error', summary: 'Error', detail: 'Hubo un error al agregar', life: 4000 })
                            else {
                                hideDialog();
                                toast.current.show({ severity: 'success', summary: 'Exito', detail: 'Registro Editado con Exito', life: 3000 });
                                hanldeGetPerfiles()
                            }
                        }).catch((error) => {
                            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Hubo un error al descargar los datos', life: 4000 });
                        })
                    }
                }
            }).catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Hubo un error al descargar los datos', life: 4000 });
            });
        } else {
            if (multiselectValue && catalog.descripcion) {
                catalog.permiso_web = 1;
                catalog.permiso_app = 1;
                catalog.perfiles_status = 1;
                PerfilService.setPerfilenCatalogo({
                    descripcion: catalog.descripcion, permiso_web: catalog.permiso_web,
                    permiso_app: catalog.permiso_app
                }, token).then((response) => {
                    if (response.data.valido == 0) toast.current.show({ severity: 'error', summary: 'Error', detail: 'Hubo un error al agregar', life: 4000 })
                    else {
                        let respuestaDto = response.data;
                        catalog.id_perfil = respuestaDto.addenda[0].id_insertado;
                        let idmenus = "";
                        if (multiselectValue) {
                            multiselectValue.forEach((element: { code: string; }) => {
                                idmenus = idmenus + element.code + ","
                            });
                        }
                        if (idmenus) {
                            MenuService.setMenuEnPerfil({ menu_nombre: idmenus + "0", id_perfil: catalog.id_perfil }, token).then((response) => {
                                if (response.data.valido == 0) toast.current.show({ severity: 'error', summary: 'Error', detail: 'Hubo un error al agregar', life: 4000 })
                                else {
                                    hideDialog();
                                    toast.current.show({ severity: 'success', summary: 'Exito', detail: 'Registro Editado con Exito', life: 3000 });
                                    hanldeGetPerfiles()
                                }
                            }).catch((error) => {
                                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Hubo un error al descargar los datos', life: 4000 });
                            })
                        }
                    }
                }).catch((error) => {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Hubo un error al descargar los datos', life: 4000 });
                })
            }
        }
    }

    //Borra el Catalogo seleccionado
    const deleteCatalog = async () => {
        if (catalog.id_perfil) {
            catalog.perfiles_status = 2;
            PerfilService.updatePerfil({
                id_perfil: catalog.id_perfil,
                descripcion: catalog.descripcion, perfiles_status: catalog.perfiles_status
            }, token).then((response) => {
                if (response.data.valido == 0) toast.current.show({ severity: 'error', summary: 'Error', detail: 'Hubo un error al eliminar el registro', life: 4000 })
                else {
                    hideDeleteDialog();
                    toast.current.show({ severity: 'success', summary: 'Exito', detail: 'Registro eliminado con exito', life: 3000 });
                    hanldeGetPerfiles()
                }
            }).catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Hubo un error al elimar los datos', life: 4000 });
            })
        }
    }

    //Abre el Modal para agregar el catalogo seleccionado
    const openNew = () => {
        setCatalog(emptyCatalog);
        setSubmitted(false);
        setAddDialog(true);
    }

    //Oculta el modal para agregar o editar el catalogo seleccionado
    const hideDialog = () => {
        setMultiselectValue(null)
        setSubmitted(false);
        setAddDialog(false);
    }

    //Oculta el modal de eliminarel catalogo seleccionado
    const hideDeleteDialog = () => {
        setMultiselectValue(null);
        setDeleteDialog(false);
    }

    //Abre el Modal para editar un catalogo seleccionado
    const editProduct = (catalog: any) => {
        MenuService.getMenusPerfil({ id_perfil: catalog.id_perfil }, token).then((response) => {
            if (response.data.valido == 0) toast.current.show({ severity: 'error', summary: 'Error', detail: 'Hubo un error al eliminar el registro', life: 4000 })
            else {
                const values = response.data.addenda.map((element: any) => {
                    return { name: element.menu_nombre, code: element.id_menu }
                });
                setMultiselectValue(values)
            }
        }).catch((error) => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Hubo un error al elimar los datos', life: 4000 });
        })
        setDropdownValue({ name: catalog.descripcion, code: catalog.id_perfil })
        setCatalog(catalog);
        setAddDialog(true);
    }

    //Abre el Modal para eliminar el catalogo seleccionado
    const confirmDeleteProduct = (catalog: any) => {
        setCatalog(catalog);
        setDeleteDialog(true);
    }

    const findIndexById = (): number => {
        let index = 0
        catalogs.forEach((element: { id_usuario_sistema: number; }) => { if (element.id_usuario_sistema >= index) index = element.id_usuario_sistema });
        return index + 1
    }

    //Funcion para editar los campos
    const onInputChange = (e: any, name: string) => {
        const val = (e.target && e.target.value) || '';
        let _product = { ...catalog };
        _product[`${name}`] = val;
        setCatalog(_product);
    }

    //Botones de Editar y Eliminar
    const actionBodyTemplate = (rowData: any) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-warning mr-2" onClick={() => editProduct(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mt-2" onClick={() => confirmDeleteProduct(rowData)} />
            </div>
        );
    }

    const AddFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Guardar" icon="pi pi-save" className="p-button-text" onClick={saveCatalog} />
        </>
    );
    const deleteFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDialog} />
            <Button label="Confirmar" icon="pi pi-check" className="p-button-text" onClick={deleteCatalog} />
        </>
    );

    const itemTemplate = (option: any) => {
        return (
            <div className="flex align-items-center">
                <span>{option.name}</span>
            </div>
        );
    };

    const selectedItemTemplate = (option: any) => {
        if (option) {
            return (
                <div className="inline-flex align-items-center py-1 px-2 bg-primary text-primary border-round mr-2">
                    <span>{option.name}</span>
                </div>
            );
        }
    };

    return (
        <div className="grid table-demo">
            <div className="col-12">
                <div className="card">
                    <div className="flex flex-column align-items-center md:flex-row md:justify-content-between md:align-items-center">
                        <div style={{ flexDirection: 'row' }}>
                            <h3 className="m-0">Perfiles</h3>
                            <h6>Crea un nuevo perfil para dar permisos a un usuario en la consola</h6>
                        </div>
                        <span className="block mt-1 md:mt-0 p-input-icon-left">
                            <InputText type="search" onInput={(e) => setGlobalFilter(e.currentTarget.value)} placeholder="Buscador general" style={{ marginRight: 10 }} />
                            <Button label="Nuevo Perfil" icon="pi pi-plus" iconPos="left" onClick={openNew} />
                        </span>

                    </div>
                    <div className="col-12 md:col-12">
                        <div className="grid table">
                            <div className="col-12">
                                <div className="card">
                                    <Toast ref={toast} />
                                    <DataTable ref={settingsRef} value={catalogs}
                                        dataKey="st_id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                        currentPageReportTemplate="Mostrando de {first} a {last} de {totalRecords} registros"
                                        globalFilter={globalFilter} emptyMessage="No hay registros en la tabla." responsiveLayout="scroll">
                                        <Column field="descripcion" header="Perfil" headerStyle={{ width: '90%' }} sortable></Column>
                                        <Column body={actionBodyTemplate}></Column>
                                    </DataTable>

                                    <Dialog visible={addDialog} style={{ width: '450px' }} header="Agregar al Catalogo" modal className="p-fluid" footer={AddFooter} onHide={hideDialog}>

                                        <div className="field">
                                            <label htmlFor="dropdownValue">Menus</label>
                                            <MultiSelect
                                                value={multiselectValue}
                                                onChange={(e) => setMultiselectValue(e.value)}
                                                options={menus}
                                                optionLabel="name"
                                                placeholder="Seleccionar Menus"
                                                filter
                                                itemTemplate={itemTemplate}
                                                selectedItemTemplate={selectedItemTemplate}
                                                className="multiselect-custom"
                                                tooltip="Selecciona una o mas opciones"
                                            />
                                            {submitted && !catalog.multiselectValue && <small className="p-invalid">Valor es requerido.</small>}
                                        </div>

                                        <div className="field">
                                            <label htmlFor="descripcion">Nombre</label>
                                            <InputText
                                                id="descripcion"
                                                value={catalog.descripcion}
                                                onChange={(e) => onInputChange(e, 'descripcion')}
                                                className={classNames({ 'p-invalid': submitted && !catalog.descripcion })}
                                                tooltip="Ingresa el nombre"
                                            />
                                            {submitted && !catalog.descripcion && <small className="p-invalid">Nombre es requerido.</small>}
                                        </div>

                                    </Dialog>

                                    <Dialog visible={deleteDialog} style={{ width: '450px' }} header="Eliminar" modal footer={deleteFooter} onHide={hideDeleteDialog}>
                                        <div className="flex align-items-center justify-content-center">
                                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                            {catalog && <span>Estas seguro de que quieres eliminar este registro <b>{catalog.name}</b>?</span>}
                                        </div>
                                    </Dialog>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default React.memo(ProfilesConfiguration);
