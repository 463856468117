import React, { useState } from 'react';
import { TabMenu } from 'primereact/tabmenu';
import Profiles from './Profiles';
import Users from './Users';

const Crud = () => {
    const [activeIndex, setActiveIndex] = useState(0);

    const wizardItems = [
        { label: 'Usuarios' },
        { label: 'Perfiles' },
    ];

    return (
        <div className="col-12 md:col-12">
            <div className="card card-w-title">
                <h3 className="m-0">Configurador de accesos y perfiles</h3>
                <h6>Nuestra vista de configuración es para el area de la consola administrativa, crea perfiles deseados para permitir dar acceso a tus usuarios que deseas que trabajen en un area especial.</h6>
                <TabMenu model={wizardItems} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
                {activeIndex === 0 && <Users />}
                {activeIndex === 1 && <Profiles />}
            </div>
        </div>
    );
}

export default React.memo(Crud);
