import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useRef, useState } from 'react';
import ProductService from '../../../serviceExample/ProductService';
import { useNavigate } from 'react-router-dom';
import ApiServices from '../../../service/apiServices';
import EncryptService from '../../../service/encrypt.service';
import { Tag } from 'primereact/tag';

const AdminPagos = () => {

    let emptyProduct = {
        id: null,
        name: '',
        image: null,
        description: '',
        category: null,
        price: 0,
        quantity: 0,
        rating: 0,
        inventoryStatus: 'INSTOCK'
    };

    const [products, setProducts] = useState<any>(null);
    const [selectedProducts, setSelectedProducts] = useState<any>(null);
    const [globalFilter, setGlobalFilter] = useState<any>(null);
    const dt = useRef<any>(null);
    const navigate = useNavigate();


    const [pagos, setPagos] = useState([]);


    /* useEffect(() => {
        const productService = new ProductService();
        productService.getProducts().then(data => setProducts(data));
    }, []); */

    const getCotizaciones = () => {
        let datos = {
            "tipo_id": 2
        }
        ApiServices.apiPost('getSolicitudesAdmin', datos).then(async res => {
            setPagos(res.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }

    const getPagosCoded = async () => {
        let datos: any = {
            "tipo_id": 2,
        }

        datos = await EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.apiPost('getSolicitudesAdmin', {dataEnc: datos}).then(async res => {
            setPagos(res.data.addenda)
            // setCotizaciones(res.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }

    useEffect(() => {
        // getCotizaciones();
        getPagosCoded();
    },[])
    

    const formatCurrency = (value: any) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }


    const codeBodyTemplate = (rowData: any) => {
        return (
            <>
                <span className="p-column-title">Code</span>
                {rowData.sol_id}
            </>
        );
    }

    const nameBodyTemplate = (rowData: any) => {
        return (
            <>
                <span className="p-column-title">Name</span>
                {rowData.sol_update.split('T')[0]}
            </>
        );
    }


    const priceBodyTemplate = (rowData: any) => {
        return (
            <>
                <span className="p-column-title">Price</span>
                {rowData.tipo_desc}
            </>
        );
    }

    const categoryBodyTemplate = (rowData: any) => {
        return (
            <>
                <span className="p-column-title">Category</span>
                {rowData.volumen}
            </>
        );
    }

    const getStatusColor = (status: any) => {
        switch (status) {
            case 0:
                return '#C94800';
            case 1:
                return '#000000';
            case 2:
                return '#00779C';
            case 3:
                return '#C94800';
            case 4:
                return '#00779C';
            case 5:
                return '#00581C'
            case 6:
                return '#00581C'
        }
    }

    const statusBodyTemplate = (rowData: any) => {
        return (
            <>
                <span className="p-column-title">Status</span>
                {/* <span className={`product-badge status-${rowData.inventoryStatus.toLowerCase()}`}>{rowData.inventoryStatus}</span> */}
                <Tag value={rowData.sol_status == 0 ? 'Cancelada' :rowData.sol_status == 1 ? 'Nueva' : rowData.sol_status == 2 ? 'Cotizada' : rowData.sol_status == 3 ? 'Pendiente' :
                            rowData.sol_status == 4 ? 'Validación' : rowData.sol_status == 5 ? 'Activa' : rowData.sol_status == 6 ? 'Entregada' :  ''} style={{ background: getStatusColor(rowData.sol_status), color: '#FFF' }} />
                {/* {rowData.sol_status} */}
            </>
        )
    }

    const actionBodyTemplate = (rowData: any) => {
        return (
            <div className="actions">
                <Button icon="pi pi-eye" className="p-button-text" onClick={() => navigate('/administrador/pagos/detalle', { state: { data: rowData } })} />
            </div>
        );
    }

    const header = (
        <div className="grid">
            <div className='col-12 md:col-8'>
                <h5 className="m-0">Pagos</h5>
                <p>Pagos realizados por medio de banco, verifica los pagos entrantes.</p>
            </div>
            <div className='col-12 md:col-4'>
                <span className="p-float-label p-input-icon-left w-100">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.currentTarget.value)} />
                    <label htmlFor="username">Buscador general</label>
                </span>
            </div>
        </div>
    );



    return (
        <section>
            <DataTable ref={dt} value={pagos} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Mostrando {first} de {last} de un total de {totalRecords} entradas"
                globalFilter={globalFilter} emptyMessage="No se encuentran entradas." header={header} responsiveLayout="scroll">
                <Column field="sol_id" header="Folio" sortable body={codeBodyTemplate} ></Column>
                <Column field="sol_update" header="Fecha" sortable body={nameBodyTemplate} ></Column>
                <Column field="tipo_desc" header="Cotización" sortable body={priceBodyTemplate}  ></Column>
                <Column field="volumen" header="Volumen" sortable body={categoryBodyTemplate} ></Column>
                <Column field="category" header="Referencia pago" sortable body={categoryBodyTemplate} ></Column>
                <Column field="sol_status" header="Estatus" sortable body={statusBodyTemplate}  ></Column>
                <Column body={actionBodyTemplate} headerStyle={{ minWidth: '1rem' }}></Column>
            </DataTable>
        </section>
    );
}

export default React.memo(AdminPagos);