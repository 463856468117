const configuration = {
  theme: 'light', // light | dark
  url: 'https://concretosreciclados.com.mx/api/processrequest/',
  urlmedia: 'https://concretosreciclados.com.mx/api/',
  menu: 'horizontal', // horizontal | sidebar | slim | overlay
  landing: true // true | false
}

export default configuration

//url: 'http://localhost:5005/processrequest/',