import { Button } from "primereact/button"
import { InputText } from "primereact/inputtext"
import { Password } from "primereact/password";
import { useLocation, useNavigate } from 'react-router-dom';
import EncryptService from "../../service/encrypt.service";
import ApiServices from "../../service/apiServices";
import { useRef, useState } from "react";
import { Toast } from "primereact/toast";

export const Olvide2 = (props: any) => {

	const navigate = useNavigate();
    const location = useLocation();
    const email = location.state.previous;
	const toast = useRef<any>();

	const [code, setCode] = useState<string>("");


	const resendCode = async () => {
		if(email){
			let datos: any =  {
				"code":102,
				"usu_email":email
			}
			console.log(datos)
			datos = EncryptService.encryptData(JSON.stringify(datos));
			ApiServices.processRequestAdd(datos)
			.then(async (res) => {
				if(res.data.valido === 1){
					// navigate('olvide-pt2', { state: { previous: email} })
					toast.current.show({
						severity: "success",
						summary: "Campo incompleto",
						detail: "Se ha reenviado el código a tu correo, favor de verificar",
						life: 4000,
					});
				} else {
					toast.current.show({
						severity: "error",
						summary: "Campo incompleto",
						detail: res.data.mensaje,
						life: 4000,
					});
				}
			})
		} else {
			
		}
	}

	const confirmCode = async () => {
		if(code.trim()){
			if(code.trim().length === 6){
				let datos: any =  {
					"code":103,
					"usu_email":email,
					"codigo":code
				}
				console.log(datos)
				datos = EncryptService.encryptData(JSON.stringify(datos));
				ApiServices.processRequestAdd(datos)
				.then(async (res) => {
					console.log(res)
					if(res.data.valido === 1){
						navigate('olvide-pt3', { state: { previous: email, usu_id: res.data.addenda.usu_id} })
						// navigate('olvide-pt3')
						toast.current.show({
							severity: "success",
							summary: "Campo incompleto",
							detail: "Se ha reenviado el código a tu correo, favor de verificar",
							life: 4000,
						});
					} else {
						toast.current.show({
							severity: "error",
							summary: "Campo incompleto",
							detail: res.data.mensaje,
							life: 4000,
						});
					}
				})
			} else {
				toast.current.show({
					severity: "error",
					summary: "Campo incorrecto",
					detail: "El código debe de tener 6 caracteres",
					life: 4000,
				});
			}
		} else {
			
		}
	}

	return (
		<section>
      		<Toast ref={toast} />
			<div className='fondo'>
				<div className='grid' style={{ height: '100%', marginTop: 0 }}>
					<div className='col-12 md:col-6 p-0'>
						<img src="assets/img/login-fondo.svg" className='w-100' />
					</div>
					<div className='col-12 md:col-6 flex justify-content-center align-items-center'>
						<div className='login'>
							<h1>Olvidé mi contraseña</h1>
							<p>Ingresa el código que se envió al correo {email} para iniciar el cambio de contraseña.</p>
							<div className='grid p-5'>
								<div className='col-12 md:col-12'>
									<span className="p-float-label">
										<InputText className='p-inputtext-lg' maxLength={6} onChange={(e) => {setCode(e.target.value)}} id="code" />
										<label htmlFor="code">Código</label>
									</span>
								</div>
							</div>
							<div className='flex justify-content-between pb-3'>
								<div>

								</div>
								<div>
									<a onClick={resendCode} style={{cursor: 'pointer'}}>Enviar código nuevamente</a>
								</div>
								<div>

								</div>
							</div>
							<div className='flex justify-content-between pb-3'>
								<div>
									<Button label="Atrás" icon="pi pi-chevron-left" onClick={() => { navigate('olvide') }} />
								</div>
								<div>
									<Button label="Enviar" onClick={confirmCode} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}